.featuredPostsDynamic {
  padding: 0 4.375rem;
  margin-bottom: clamp(3.5625rem, 16vw, 7.5rem);
  @media (max-width: 513px) {
    padding: 0 1.5rem;
  }
  & h2 {
    text-transform: uppercase;
    margin: 0 0 35px 20px;
    font-size: clamp(1.5rem, 5vw, 2.25rem);
    @media (max-width: 513px) {
      margin: 35px 0;
    }
  }
  & ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35ch, 1fr));
    gap: 2.5rem 1.75rem;
    list-style: none;
    padding: 0;
    width: 100%;
    margin-bottom: 0;
    @media (max-width: 513px) {
      grid-template-columns: 1fr;
    }
  }
}
