@mixin height-width($property) {
  height: $property;
  width: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin transition-delay($property) {
  -moz-transition-delay: $property;
  -webkit-transition-delay: $property;
  -o-transition-delay: $property;
  transition-delay: $property;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin box-sizing($property) {
  -webkit-box-sizing: $property;
  -moz-box-sizing: $property;
  box-sizing: $property;
}

@mixin box-shadow($property){
  -webkit-box-shadow: $property;
  -moz-box-shadow: $property;
  box-shadow: $property;
}

@mixin animation-duration($property) {
  animation-duration: $property;
  -o-animation-duration: $property;
  -ms-animation-duration: $property;
  -moz-animation-duration: $property;
  -webkit-animation-duration: $property;
}

@mixin animation-name($property) {
  animation-name: $property;
  -o-animation-name: $property;
  -ms-animation-name: $property;
  -moz-animation-name: $property;
  -webkit-animation-name: $property;
}

@mixin border-radius($property) {
  aborder-radius: $property;
  -ms-border-radius: $property;
  -moz-border-radius: $property;
  -webkit-border-radius: $property;
  -khtml-border-radius: $property;
}
