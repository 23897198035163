.pagination {
  display: flex;
  justify-content: center;
  margin: 3.25rem 0 2rem;
  @media (max-width: 513px) {
    margin: 2.5rem 0 1.5rem;
  }
  & > button {
    border: none;
    background: none;
    transition: color 0.3s ease-in-out;
    padding: 0;
    &:hover {
      color: #8ac691;
    }
    & + button {
      margin-left: 1rem;
    }
  }
}
