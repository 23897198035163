.richText {
  & h1 {
    font-size: clamp(1.75rem, 4vw, 2.5rem);
    color: #444;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 2.5rem;
    @media (max-width: 513px) {
      margin: 2rem 0 1.75rem;
    }
  }
  & .legalContent {
    color: #444;
    line-height: 1.5;
    & h1,
    & h2,
    & h3,
    & h4 {
      color: #444;
    }
    & h1,
    & h2 {
      text-align: center;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 1.2;
      margin: 2.5rem 0 2rem;
      @media (max-width: 513px) {
        margin: 2rem 0 1.75rem;
      }
    }

    & h1 {
      font-size: clamp(1.75rem, 4vw, 2rem);
    }

    & h2 {
      font-size: clamp(1.5rem, 4vw, 1.75rem);
    }

    & h3 {
      font-size: 1.25rem;
      margin-top: 2rem;
    }

    & h4 {
      font-size: 1rem;
      font-weight: 700;
      text-decoration: underline;
    }
    max-width: 60.75rem;
    margin: 5rem auto;
    padding: 0 4.375rem;
    @media (max-width: 513px) {
      padding: 0 1.5rem;
    }
    & adress {
      display: block;
      font-style: italic;
      text-align: center;
      margin: 1.5rem auto;
    }
  }
}
