.postsListCard {
  & > img {
    margin-bottom: 1rem;
    width: 100%;
  }
  & h2 {
    display: flex;
    margin: 0 0 0.75rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #1d212c;
    & > img {
      margin-right: 0.75rem;
      height: 21px;
    }
    & + div {
      margin-bottom: 0.75rem;
      & i {
        margin-right: 0.75rem;
      }
      & time {
        text-transform: uppercase;
        font-size: 0.875rem;
      }
    }
  }
  & p {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  & a {
    display: inline-flex;
    align-items: center;
    border-top: 1px solid #c8c4c4;
    border-bottom: 1px solid #c8c4c4;
    padding: 0.375rem 1.25rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    &::after {
      content: '';
      position: relative;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23787D88' d='M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z'%3E%3C/path%3E%3C/svg%3E");
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
}
