.globalSearch {
  & .headingSmallBanner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12.5rem;
    & > h1 {
      font-size: clamp(1.75rem, 6vw, 2.5rem);
      color: #444;
      text-transform: uppercase;
    }
  }
  & .searchBar {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(50vw + 3.125rem);
    max-width: 37.5rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    & input {
      width: 100%;
      font-size: 0.875rem;
      background: none;
      border: none;
      outline: none;
      padding: 0.5rem 1rem;
    }
    & div {
      display: flex;
      flex-direction: row-reverse;
      & i {
        &:first-of-type {
          padding: 0 1rem;
        }
        &:last-of-type {
          padding-left: 1rem;
        }
      }
    }
  }
  & ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35ch, 1fr));
    gap: 3.375rem 2rem;
    list-style: none;
    margin-top: 6.25rem;
    padding: 0 4.375rem;
    @media (max-width: 513px) {
      grid-template-columns: 1fr;
      padding: 0 1.5rem;
      margin-top: 3.5rem;
    }
    & li {
      & img {
        width: 100%;
        margin-bottom: 1rem;
      }
      & h2 {
        margin: 0 0 0.75rem;
        font-size: 1.125rem;
        text-transform: uppercase;
        font-weight: bold;
        color: #1d212c;
        & > i {
          margin-right: 0.75rem;
        }
      }
      & p {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      & .date {
        margin-bottom: 0.75rem;
        & i {
          margin-right: 0.75rem;
        }
        & time {
          text-transform: uppercase;
          font-size: 0.875rem;
        }
      }
      & p {
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
      & > a:last-child {
        display: inline-flex;
        align-items: center;
        border-top: 1px solid #c8c4c4;
        border-bottom: 1px solid #c8c4c4;
        padding: 0.375rem 1.25rem;
        text-transform: uppercase;
        font-size: 0.875rem;
        &::after {
          content: '';
          position: relative;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23787D88' d='M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z'%3E%3C/path%3E%3C/svg%3E");
          width: 1rem;
          height: 1rem;
          display: inline-block;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
