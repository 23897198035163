@import '../../assets/scss/settings/variables';

.blockImgLeft {
  max-height: 368px;
  max-width: 420px;
}

@media screen and (max-width: $medium_max) {
  .blockImgLeft{
    max-width: $max;
  }
}
