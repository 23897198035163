
/* === 3.27. BLOCK ICON === */
.block-icon-box-left-icon {
    margin-bottom: 35px;
  }
  .block-icon-box-left-icon .icon {
    text-align: center;
    display: inline-block;
    border: 2px solid transparent;
    width: 60px;
    height: 60px;
    box-sizing: content-box;
    position: relative;
    margin-top: -8px;
    margin-bottom: 35px;
  }
  .block-icon-box-left-icon.circle .icon {
    background-color: #9fce4e;
    border-radius: 30px;
  }
  .block-icon-box-left-icon .icon i {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: #9fce4e;
  }
  .block-icon-box-left-icon.circle .icon i {
    color: #fff;
  }
  .block-icon-box-left-icon .icon i.fz-4 {
    font-size: 40px;
  }
  .block-icon-box-left-icon.style-2 .icon {
    margin-bottom: 0px;
  }
  .block-icon-box-left-icon .icon.icon-image {
    margin-right: 10px;
    margin-bottom: 0;
  }
  .block-icon-box-left-icon .heading {
    margin: 0;
    text-transform: uppercase;
  }
  .block-icon-box-left-icon .content {
    margin-top: 15px;
    margin-bottom: 50px;
  }