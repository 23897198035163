@import '../../assets/scss/settings/variables';
@import '../../assets/scss/settings/utility';

.list_cn {
  padding-left: 15px;
  padding-right: 15px;
  .link_ingredient {
    padding: 20px;
    padding-left: 5px;
    padding-right: 5px;
    color: $black;
    @include transition(all .3s ease);
    &:hover {
      text-decoration: none;
    }
    &:focus > div,
    &:hover>div {
      background-color: #d0d3c2;
    }
    > div {
      min-height: 355px;
      background-color: #dee0d4;
      padding: 12px;
      @include transition(all .3s ease);
      .img-cn {
        text-align: center;
        display: block;
        background-color: $white;
        img {
          max-height: 242px;
          max-width: $max;
          height: auto !important;
          vertical-align: middle;
          border: 0;
          outline: none;
        }
      }
    }
    .title-cn {
      text-align: center;
      font-size: 1.675rem;
      display: block;
      margin: 32px 0 0;
      font-weight: $fontmedium;
    }
  }
  .modal-xl {
    max-width: 1055px;
    margin-top: 10%;
    .modal-content {
      .modal-header {
        border-bottom: unset;
        padding-bottom: 0;
        height: 30px;
        .close {
          position: initial;
          z-index: 2050;
        }
      }
      .popup-title {
        font-size: 1.375rem;
        margin-top: 50px;
        padding-right: 40px;
        font-weight: $fontbold;
        margin-bottom: 10px;
      }
      p {
        font-size: .9375rem;
        line-height: 24px;
        font-weight: $fontregular;
      }
    }
  }
  .modal.fade.show{
    z-index: 99;
    background: #ffffffd4;
    opacity: 1;
    padding-right: 0;
    padding-top: 6%;
  }
}

.modal-backdrop{
  z-index: unset !important;
}

@media screen and (max-width: 425px) {
  .list_cn {
    .modal-xl {
      .modal-content {
        .popup-title {
          margin-top: 0;
          font-size: 1.125rem;
        }
      }
    }
  }
}
