.FeaturedPosts {
  padding: 0 4.375rem;
  margin-bottom: clamp(3.5625rem, 16vw, 7.5rem);
  @media (max-width: 513px) {
    padding: 0 1.5rem;
  }
  & h2 {
    margin: 0 0 2rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  & ul {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35ch, 1fr));
    gap: 1.75rem;
    list-style: none;
    width: 100%;
    margin-bottom: 0;
    @media (max-width: 513px) {
      grid-template-columns: 1fr;
    }
    & li {
      & img {
        margin-bottom: 1rem;
      }
      & .richText {
        margin-bottom: 1rem;
      }
      & h3 {
        font-size: 1.25rem;
        text-transform: uppercase;
        margin: 0 0 0.5rem;
        font-weight: bold;
        transition: color 0.3s ease-in-out;
      }
      & > a {
        display: inline-flex;
        align-items: center;
        border-top: 1px solid #c8c4c4;
        border-bottom: 1px solid #c8c4c4;
        padding: 0.375rem 1.25rem;
        text-transform: uppercase;
        font-size: 0.875rem;
        &::after {
          content: '';
          position: relative;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23787D88' d='M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z'%3E%3C/path%3E%3C/svg%3E");
          width: 1rem;
          height: 1rem;
          display: inline-block;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
