.headingBanner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  // height: 554px;
  padding: 21%;
  & .whiteBlock {
    // todo clamp with actualites fontsize
    position: absolute;
    width: 513px;
    height: 50px;
    bottom: 0;
    left: 0;
    background-color: white;
    @media (max-width: 513px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 25%;
  }
}

.headingTitle {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}
