@media screen and (max-width: 425px) {
  .row.box-1{
    h1 {
      font-size: 1.875rem !important;
    }
    .pad {
      padding: 20px !important;
      blockquote {
        font-size: 1rem;
        p {
          font-size: 1rem;
        }
      }
    }
  }
}
