@font-face {
    font-family: 'ElegantIcons';
    src: url('../fonts/ElegantIcons.eot');
    src: url('../fonts/ElegantIcons.eot?#iefix') format('embedded-opentype'),url('../fonts/ElegantIcons.woff') format('woff'),url('../fonts/ElegantIcons.ttf') format('truetype'),url('../fonts/ElegantIcons.svg#ElegantIcons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*="elegant_"]:before {
    font-family: 'ElegantIcons';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
    text-transform: none;
    display: inline-block;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.elegant_arrow_up:before {
    content: "\21";
}

.elegant_arrow_down:before {
    content: "\22";
}

.elegant_arrow_left:before {
    content: "\23";
}

.elegant_arrow_right:before {
    content: "\24";
}

.elegant_arrow_left-up:before {
    content: "\25";
}

.elegant_arrow_right-up:before {
    content: "\26";
}

.elegant_arrow_right-down:before {
    content: "\27";
}

.elegant_arrow_left-down:before {
    content: "\28";
}

.elegant_arrow-up-down:before {
    content: "\29";
}

.elegant_arrow_up-down_alt:before {
    content: "\2a";
}

.elegant_arrow_left-right_alt:before {
    content: "\2b";
}

.elegant_arrow_left-right:before {
    content: "\2c";
}

.elegant_arrow_expand_alt2:before {
    content: "\2d";
}

.elegant_arrow_expand_alt:before {
    content: "\2e";
}

.elegant_arrow_condense:before {
    content: "\2f";
}

.elegant_arrow_expand:before {
    content: "\30";
}

.elegant_arrow_move:before {
    content: "\31";
}

.elegant_arrow_carrot-up:before {
    content: "\32";
}

.elegant_arrow_carrot-down:before {
    content: "\33";
}

.elegant_arrow_carrot-left:before {
    content: "\34";
}

.elegant_arrow_carrot-right:before {
    content: "\35";
}

.elegant_arrow_carrot-2up:before {
    content: "\36";
}

.elegant_arrow_carrot-2down:before {
    content: "\37";
}

.elegant_arrow_carrot-2left:before {
    content: "\38";
}

.elegant_arrow_carrot-2right:before {
    content: "\39";
}

.elegant_arrow_carrot-up_alt2:before {
    content: "\3a";
}

.elegant_arrow_carrot-down_alt2:before {
    content: "\3b";
}

.elegant_arrow_carrot-left_alt2:before {
    content: "\3c";
}

.elegant_arrow_carrot-right_alt2:before {
    content: "\3d";
}

.elegant_arrow_carrot-2up_alt2:before {
    content: "\3e";
}

.elegant_arrow_carrot-2down_alt2:before {
    content: "\3f";
}

.elegant_arrow_carrot-2left_alt2:before {
    content: "\40";
}

.elegant_arrow_carrot-2right_alt2:before {
    content: "\41";
}

.elegant_arrow_triangle-up:before {
    content: "\42";
}

.elegant_arrow_triangle-down:before {
    content: "\43";
}

.elegant_arrow_triangle-left:before {
    content: "\44";
}

.elegant_arrow_triangle-right:before {
    content: "\45";
}

.elegant_arrow_triangle-up_alt2:before {
    content: "\46";
}

.elegant_arrow_triangle-down_alt2:before {
    content: "\47";
}

.elegant_arrow_triangle-left_alt2:before {
    content: "\48";
}

.elegant_arrow_triangle-right_alt2:before {
    content: "\49";
}

.elegant_arrow_back:before {
    content: "\4a";
}

.elegant_icon_minus-06:before {
    content: "\4b";
}

.elegant_icon_plus:before {
    content: "\4c";
}

.elegant_icon_close:before {
    content: "\4d";
}

.elegant_icon_check:before {
    content: "\4e";
}

.elegant_icon_minus_alt2:before {
    content: "\4f";
}

.elegant_icon_plus_alt2:before {
    content: "\50";
}

.elegant_icon_close_alt2:before {
    content: "\51";
}

.elegant_icon_check_alt2:before {
    content: "\52";
}

.elegant_icon_zoom-out_alt:before {
    content: "\53";
}

.elegant_icon_zoom-in_alt:before {
    content: "\54";
}

.elegant_icon_search:before {
    content: "\55";
}

.elegant_icon_box-empty:before {
    content: "\56";
}

.elegant_icon_box-selected:before {
    content: "\57";
}

.elegant_icon_minus-box:before {
    content: "\58";
}

.elegant_icon_plus-box:before {
    content: "\59";
}

.elegant_icon_box-checked:before {
    content: "\5a";
}

.elegant_icon_circle-empty:before {
    content: "\5b";
}

.elegant_icon_circle-slelected:before {
    content: "\5c";
}

.elegant_icon_stop_alt2:before {
    content: "\5d";
}

.elegant_icon_stop:before {
    content: "\5e";
}

.elegant_icon_pause_alt2:before {
    content: "\5f";
}

.elegant_icon_pause:before {
    content: "\60";
}

.elegant_icon_menu:before {
    content: "\61";
}

.elegant_icon_menu-square_alt2:before {
    content: "\62";
}

.elegant_icon_menu-circle_alt2:before {
    content: "\63";
}

.elegant_icon_ul:before {
    content: "\64";
}

.elegant_icon_ol:before {
    content: "\65";
}

.elegant_icon_adjust-horiz:before {
    content: "\66";
}

.elegant_icon_adjust-vert:before {
    content: "\67";
}

.elegant_icon_document_alt:before {
    content: "\68";
}

.elegant_icon_documents_alt:before {
    content: "\69";
}

.elegant_icon_pencil:before {
    content: "\6a";
}

.elegant_icon_pencil-edit_alt:before {
    content: "\6b";
}

.elegant_icon_pencil-edit:before {
    content: "\6c";
}

.elegant_icon_folder-alt:before {
    content: "\6d";
}

.elegant_icon_folder-open_alt:before {
    content: "\6e";
}

.elegant_icon_folder-add_alt:before {
    content: "\6f";
}

.elegant_icon_info_alt:before {
    content: "\70";
}

.elegant_icon_error-oct_alt:before {
    content: "\71";
}

.elegant_icon_error-circle_alt:before {
    content: "\72";
}

.elegant_icon_error-triangle_alt:before {
    content: "\73";
}

.elegant_icon_question_alt2:before {
    content: "\74";
}

.elegant_icon_question:before {
    content: "\75";
}

.elegant_icon_comment_alt:before {
    content: "\76";
}

.elegant_icon_chat_alt:before {
    content: "\77";
}

.elegant_icon_vol-mute_alt:before {
    content: "\78";
}

.elegant_icon_volume-low_alt:before {
    content: "\79";
}

.elegant_icon_volume-high_alt:before {
    content: "\7a";
}

.elegant_icon_quotations:before {
    content: "\7b";
}

.elegant_icon_quotations_alt2:before {
    content: "\7c";
}

.elegant_icon_clock_alt:before {
    content: "\7d";
}

.elegant_icon_lock_alt:before {
    content: "\7e";
}

.elegant_icon_lock-open_alt:before {
    content: "\e000";
}

.elegant_icon_key_alt:before {
    content: "\e001";
}

.elegant_icon_cloud_alt:before {
    content: "\e002";
}

.elegant_icon_cloud-upload_alt:before {
    content: "\e003";
}

.elegant_icon_cloud-download_alt:before {
    content: "\e004";
}

.elegant_icon_image:before {
    content: "\e005";
}

.elegant_icon_images:before {
    content: "\e006";
}

.elegant_icon_lightbulb_alt:before {
    content: "\e007";
}

.elegant_icon_gift_alt:before {
    content: "\e008";
}

.elegant_icon_house_alt:before {
    content: "\e009";
}

.elegant_icon_genius:before {
    content: "\e00a";
}

.elegant_icon_mobile:before {
    content: "\e00b";
}

.elegant_icon_tablet:before {
    content: "\e00c";
}

.elegant_icon_laptop:before {
    content: "\e00d";
}

.elegant_icon_desktop:before {
    content: "\e00e";
}

.elegant_icon_camera_alt:before {
    content: "\e00f";
}

.elegant_icon_mail_alt:before {
    content: "\e010";
}

.elegant_icon_cone_alt:before {
    content: "\e011";
}

.elegant_icon_ribbon_alt:before {
    content: "\e012";
}

.elegant_icon_bag_alt:before {
    content: "\e013";
}

.elegant_icon_creditcard:before {
    content: "\e014";
}

.elegant_icon_cart_alt:before {
    content: "\e015";
}

.elegant_icon_paperclip:before {
    content: "\e016";
}

.elegant_icon_tag_alt:before {
    content: "\e017";
}

.elegant_icon_tags_alt:before {
    content: "\e018";
}

.elegant_icon_trash_alt:before {
    content: "\e019";
}

.elegant_icon_cursor_alt:before {
    content: "\e01a";
}

.elegant_icon_mic_alt:before {
    content: "\e01b";
}

.elegant_icon_compass_alt:before {
    content: "\e01c";
}

.elegant_icon_pin_alt:before {
    content: "\e01d";
}

.elegant_icon_pushpin_alt:before {
    content: "\e01e";
}

.elegant_icon_map_alt:before {
    content: "\e01f";
}

.elegant_icon_drawer_alt:before {
    content: "\e020";
}

.elegant_icon_toolbox_alt:before {
    content: "\e021";
}

.elegant_icon_book_alt:before {
    content: "\e022";
}

.elegant_icon_calendar:before {
    content: "\e023";
}

.elegant_icon_film:before {
    content: "\e024";
}

.elegant_icon_table:before {
    content: "\e025";
}

.elegant_icon_contacts_alt:before {
    content: "\e026";
}

.elegant_icon_headphones:before {
    content: "\e027";
}

.elegant_icon_lifesaver:before {
    content: "\e028";
}

.elegant_icon_piechart:before {
    content: "\e029";
}

.elegant_icon_refresh:before {
    content: "\e02a";
}

.elegant_icon_link_alt:before {
    content: "\e02b";
}

.elegant_icon_link:before {
    content: "\e02c";
}

.elegant_icon_loading:before {
    content: "\e02d";
}

.elegant_icon_blocked:before {
    content: "\e02e";
}

.elegant_icon_archive_alt:before {
    content: "\e02f";
}

.elegant_icon_heart_alt:before {
    content: "\e030";
}

.elegant_icon_star_alt:before {
    content: "\e031";
}

.elegant_icon_star-half_alt:before {
    content: "\e032";
}

.elegant_icon_star:before {
    content: "\e033";
}

.elegant_icon_star-half:before {
    content: "\e034";
}

.elegant_icon_tools:before {
    content: "\e035";
}

.elegant_icon_tool:before {
    content: "\e036";
}

.elegant_icon_cog:before {
    content: "\e037";
}

.elegant_icon_cogs:before {
    content: "\e038";
}

.elegant_arrow_up_alt:before {
    content: "\e039";
}

.elegant_arrow_down_alt:before {
    content: "\e03a";
}

.elegant_arrow_left_alt:before {
    content: "\e03b";
}

.elegant_arrow_right_alt:before {
    content: "\e03c";
}

.elegant_arrow_left-up_alt:before {
    content: "\e03d";
}

.elegant_arrow_right-up_alt:before {
    content: "\e03e";
}

.elegant_arrow_right-down_alt:before {
    content: "\e03f";
}

.elegant_arrow_left-down_alt:before {
    content: "\e040";
}

.elegant_arrow_condense_alt:before {
    content: "\e041";
}

.elegant_arrow_expand_alt3:before {
    content: "\e042";
}

.elegant_arrow_carrot_up_alt:before {
    content: "\e043";
}

.elegant_arrow_carrot-down_alt:before {
    content: "\e044";
}

.elegant_arrow_carrot-left_alt:before {
    content: "\e045";
}

.elegant_arrow_carrot-right_alt:before {
    content: "\e046";
}

.elegant_arrow_carrot-2up_alt:before {
    content: "\e047";
}

.elegant_arrow_carrot-2dwnn_alt:before {
    content: "\e048";
}

.elegant_arrow_carrot-2left_alt:before {
    content: "\e049";
}

.elegant_arrow_carrot-2right_alt:before {
    content: "\e04a";
}

.elegant_arrow_triangle-up_alt:before {
    content: "\e04b";
}

.elegant_arrow_triangle-down_alt:before {
    content: "\e04c";
}

.elegant_arrow_triangle-left_alt:before {
    content: "\e04d";
}

.elegant_arrow_triangle-right_alt:before {
    content: "\e04e";
}

.elegant_icon_minus_alt:before {
    content: "\e04f";
}

.elegant_icon_plus_alt:before {
    content: "\e050";
}

.elegant_icon_close_alt:before {
    content: "\e051";
}

.elegant_icon_check_alt:before {
    content: "\e052";
}

.elegant_icon_zoom-out:before {
    content: "\e053";
}

.elegant_icon_zoom-in:before {
    content: "\e054";
}

.elegant_icon_stop_alt:before {
    content: "\e055";
}

.elegant_icon_menu-square_alt:before {
    content: "\e056";
}

.elegant_icon_menu-circle_alt:before {
    content: "\e057";
}

.elegant_icon_document:before {
    content: "\e058";
}

.elegant_icon_documents:before {
    content: "\e059";
}

.elegant_icon_pencil_alt:before {
    content: "\e05a";
}

.elegant_icon_folder:before {
    content: "\e05b";
}

.elegant_icon_folder-open:before {
    content: "\e05c";
}

.elegant_icon_folder-add:before {
    content: "\e05d";
}

.elegant_icon_folder_upload:before {
    content: "\e05e";
}

.elegant_icon_folder_download:before {
    content: "\e05f";
}

.elegant_icon_info:before {
    content: "\e060";
}

.elegant_icon_error-circle:before {
    content: "\e061";
}

.elegant_icon_error-oct:before {
    content: "\e062";
}

.elegant_icon_error-triangle:before {
    content: "\e063";
}

.elegant_icon_question_alt:before {
    content: "\e064";
}

.elegant_icon_comment:before {
    content: "\e065";
}

.elegant_icon_chat:before {
    content: "\e066";
}

.elegant_icon_vol-mute:before {
    content: "\e067";
}

.elegant_icon_volume-low:before {
    content: "\e068";
}

.elegant_icon_volume-high:before {
    content: "\e069";
}

.elegant_icon_quotations_alt:before {
    content: "\e06a";
}

.elegant_icon_clock:before {
    content: "\e06b";
}

.elegant_icon_lock:before {
    content: "\e06c";
}

.elegant_icon_lock-open:before {
    content: "\e06d";
}

.elegant_icon_key:before {
    content: "\e06e";
}

.elegant_icon_cloud:before {
    content: "\e06f";
}

.elegant_icon_cloud-upload:before {
    content: "\e070";
}

.elegant_icon_cloud-download:before {
    content: "\e071";
}

.elegant_icon_lightbulb:before {
    content: "\e072";
}

.elegant_icon_gift:before {
    content: "\e073";
}

.elegant_icon_house:before {
    content: "\e074";
}

.elegant_icon_camera:before {
    content: "\e075";
}

.elegant_icon_mail:before {
    content: "\e076";
}

.elegant_icon_cone:before {
    content: "\e077";
}

.elegant_icon_ribbon:before {
    content: "\e078";
}

.elegant_icon_bag:before {
    content: "\e079";
}

.elegant_icon_cart:before {
    content: "\e07a";
}

.elegant_icon_tag:before {
    content: "\e07b";
}

.elegant_icon_tags:before {
    content: "\e07c";
}

.elegant_icon_trash:before {
    content: "\e07d";
}

.elegant_icon_cursor:before {
    content: "\e07e";
}

.elegant_icon_mic:before {
    content: "\e07f";
}

.elegant_icon_compass:before {
    content: "\e080";
}

.elegant_icon_pin:before {
    content: "\e081";
}

.elegant_icon_pushpin:before {
    content: "\e082";
}

.elegant_icon_map:before {
    content: "\e083";
}

.elegant_icon_drawer:before {
    content: "\e084";
}

.elegant_icon_toolbox:before {
    content: "\e085";
}

.elegant_icon_book:before {
    content: "\e086";
}

.elegant_icon_contacts:before {
    content: "\e087";
}

.elegant_icon_archive:before {
    content: "\e088";
}

.elegant_icon_heart:before {
    content: "\e089";
}

.elegant_icon_profile:before {
    content: "\e08a";
}

.elegant_icon_group:before {
    content: "\e08b";
}

.elegant_icon_grid-2x2:before {
    content: "\e08c";
}

.elegant_icon_grid-3x3:before {
    content: "\e08d";
}

.elegant_icon_music:before {
    content: "\e08e";
}

.elegant_icon_pause_alt:before {
    content: "\e08f";
}

.elegant_icon_phone:before {
    content: "\e090";
}

.elegant_icon_upload:before {
    content: "\e091";
}

.elegant_icon_download:before {
    content: "\e092";
}

.elegant_social_facebook:before {
    content: "\e093";
}

.elegant_social_twitter:before {
    content: "\e094";
}

.elegant_social_pinterest:before {
    content: "\e095";
}

.elegant_social_googleplus:before {
    content: "\e096";
}

.elegant_social_tumblr:before {
    content: "\e097";
}

.elegant_social_tumbleupon:before {
    content: "\e098";
}

.elegant_social_wordpress:before {
    content: "\e099";
}

.elegant_social_instagram:before {
    content: "\e09a";
}

.elegant_social_dribbble:before {
    content: "\e09b";
}

.elegant_social_vimeo:before {
    content: "\e09c";
}

.elegant_social_linkedin:before {
    content: "\e09d";
}

.elegant_social_rss:before {
    content: "\e09e";
}

.elegant_social_deviantart:before {
    content: "\e09f";
}

.elegant_social_share:before {
    content: "\e0a0";
}

.elegant_social_myspace:before {
    content: "\e0a1";
}

.elegant_social_skype:before {
    content: "\e0a2";
}

.elegant_social_youtube:before {
    content: "\e0a3";
}

.elegant_social_picassa:before {
    content: "\e0a4";
}

.elegant_social_googledrive:before {
    content: "\e0a5";
}

.elegant_social_flickr:before {
    content: "\e0a6";
}

.elegant_social_blogger:before {
    content: "\e0a7";
}

.elegant_social_spotify:before {
    content: "\e0a8";
}

.elegant_social_delicious:before {
    content: "\e0a9";
}

.elegant_social_facebook_circle:before {
    content: "\e0aa";
}

.elegant_social_twitter_circle:before {
    content: "\e0ab";
}

.elegant_social_pinterest_circle:before {
    content: "\e0ac";
}

.elegant_social_googleplus_circle:before {
    content: "\e0ad";
}

.elegant_social_tumblr_circle:before {
    content: "\e0ae";
}

.elegant_social_stumbleupon_circle:before {
    content: "\e0af";
}

.elegant_social_wordpress_circle:before {
    content: "\e0b0";
}

.elegant_social_instagram_circle:before {
    content: "\e0b1";
}

.elegant_social_dribbble_circle:before {
    content: "\e0b2";
}

.elegant_social_vimeo_circle:before {
    content: "\e0b3";
}

.elegant_social_linkedin_circle:before {
    content: "\e0b4";
}

.elegant_social_rss_circle:before {
    content: "\e0b5";
}

.elegant_social_deviantart_circle:before {
    content: "\e0b6";
}

.elegant_social_share_circle:before {
    content: "\e0b7";
}

.elegant_social_myspace_circle:before {
    content: "\e0b8";
}

.elegant_social_skype_circle:before {
    content: "\e0b9";
}

.elegant_social_youtube_circle:before {
    content: "\e0ba";
}

.elegant_social_picassa_circle:before {
    content: "\e0bb";
}

.elegant_social_googledrive_alt2:before {
    content: "\e0bc";
}

.elegant_social_flickr_circle:before {
    content: "\e0bd";
}

.elegant_social_blogger_circle:before {
    content: "\e0be";
}

.elegant_social_spotify_circle:before {
    content: "\e0bf";
}

.elegant_social_delicious_circle:before {
    content: "\e0c0";
}

.elegant_social_facebook_square:before {
    content: "\e0c1";
}

.elegant_social_twitter_square:before {
    content: "\e0c2";
}

.elegant_social_pinterest_square:before {
    content: "\e0c3";
}

.elegant_social_googleplus_square:before {
    content: "\e0c4";
}

.elegant_social_tumblr_square:before {
    content: "\e0c5";
}

.elegant_social_stumbleupon_square:before {
    content: "\e0c6";
}

.elegant_social_wordpress_square:before {
    content: "\e0c7";
}

.elegant_social_instagram_square:before {
    content: "\e0c8";
}

.elegant_social_dribbble_square:before {
    content: "\e0c9";
}

.elegant_social_vimeo_square:before {
    content: "\e0ca";
}

.elegant_social_linkedin_square:before {
    content: "\e0cb";
}

.elegant_social_rss_square:before {
    content: "\e0cc";
}

.elegant_social_deviantart_square:before {
    content: "\e0cd";
}

.elegant_social_share_square:before {
    content: "\e0ce";
}

.elegant_social_myspace_square:before {
    content: "\e0cf";
}

.elegant_social_skype_square:before {
    content: "\e0d0";
}

.elegant_social_youtube_square:before {
    content: "\e0d1";
}

.elegant_social_picassa_square:before {
    content: "\e0d2";
}

.elegant_social_googledrive_square:before {
    content: "\e0d3";
}

.elegant_social_flickr_square:before {
    content: "\e0d4";
}

.elegant_social_blogger_square:before {
    content: "\e0d5";
}

.elegant_social_spotify_square:before {
    content: "\e0d6";
}

.elegant_social_delicious_square:before {
    content: "\e0d7";
}

.elegant_icon_printer:before {
    content: "\e103";
}

.elegant_icon_calulator:before {
    content: "\e0ee";
}

.elegant_icon_building:before {
    content: "\e0ef";
}

.elegant_icon_floppy:before {
    content: "\e0e8";
}

.elegant_icon_drive:before {
    content: "\e0ea";
}

.elegant_icon_search-2:before {
    content: "\e101";
}

.elegant_icon_id:before {
    content: "\e107";
}

.elegant_icon_id-2:before {
    content: "\e108";
}

.elegant_icon_puzzle:before {
    content: "\e102";
}

.elegant_icon_like:before {
    content: "\e106";
}

.elegant_icon_dislike:before {
    content: "\e0eb";
}

.elegant_icon_mug:before {
    content: "\e105";
}

.elegant_icon_currency:before {
    content: "\e0ed";
}

.elegant_icon_wallet:before {
    content: "\e100";
}

.elegant_icon_pens:before {
    content: "\e104";
}

.elegant_icon_easel:before {
    content: "\e0e9";
}

.elegant_icon_flowchart:before {
    content: "\e109";
}

.elegant_icon_datareport:before {
    content: "\e0ec";
}

.elegant_icon_briefcase:before {
    content: "\e0fe";
}

.elegant_icon_shield:before {
    content: "\e0f6";
}

.elegant_icon_percent:before {
    content: "\e0fb";
}

.elegant_icon_globe:before {
    content: "\e0e2";
}

.elegant_icon_globe-2:before {
    content: "\e0e3";
}

.elegant_icon_target:before {
    content: "\e0f5";
}

.elegant_icon_hourglass:before {
    content: "\e0e1";
}

.elegant_icon_balance:before {
    content: "\e0ff";
}

.elegant_icon_rook:before {
    content: "\e0f8";
}

.elegant_icon_printer-alt:before {
    content: "\e0fa";
}

.elegant_icon_calculator_alt:before {
    content: "\e0e7";
}

.elegant_icon_building_alt:before {
    content: "\e0fd";
}

.elegant_icon_floppy_alt:before {
    content: "\e0e4";
}

.elegant_icon_drive_alt:before {
    content: "\e0e5";
}

.elegant_icon_search_alt:before {
    content: "\e0f7";
}

.elegant_icon_id_alt:before {
    content: "\e0e0";
}

.elegant_icon_id-2_alt:before {
    content: "\e0fc";
}

.elegant_icon_puzzle_alt:before {
    content: "\e0f9";
}

.elegant_icon_like_alt:before {
    content: "\e0dd";
}

.elegant_icon_dislike_alt:before {
    content: "\e0f1";
}

.elegant_icon_mug_alt:before {
    content: "\e0dc";
}

.elegant_icon_currency_alt:before {
    content: "\e0f3";
}

.elegant_icon_wallet_alt:before {
    content: "\e0d8";
}

.elegant_icon_pens_alt:before {
    content: "\e0db";
}

.elegant_icon_easel_alt:before {
    content: "\e0f0";
}

.elegant_icon_flowchart_alt:before {
    content: "\e0df";
}

.elegant_icon_datareport_alt:before {
    content: "\e0f2";
}

.elegant_icon_briefcase_alt:before {
    content: "\e0f4";
}

.elegant_icon_shield_alt:before {
    content: "\e0d9";
}

.elegant_icon_percent_alt:before {
    content: "\e0da";
}

.elegant_icon_globe_alt:before {
    content: "\e0de";
}

.elegant_icon_clipboard:before {
    content: "\e0e6";
}