.carriers {
  height: 500px;
  padding: 5.375rem 0 0 6rem;
  margin-bottom: 4.5rem;
  @media (max-width: 1050px) {
    padding: 5.375rem 2rem 0;
    padding-top: clamp(2rem, 6vw, 5.375rem);
    padding-left: clamp(2rem, 6vw, 6rem);
  }
  & h1 {
    text-transform: uppercase;
    max-width: 25ch;
    font-size: clamp(1.75rem, 6vw, 2.5rem);
    color: #444444;
    margin-top: 0;
  }
  & p {
    font-size: 0.875rem;
    max-width: 80ch;
  }
  & a {
    display: inline-flex;
    align-items: center;
    border-top: 1px solid #c8c4c4;
    border-bottom: 1px solid #c8c4c4;
    padding: 0.375rem 1.25rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    &::after {
      content: '';
      position: relative;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23787D88' d='M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z'%3E%3C/path%3E%3C/svg%3E");
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
}
