.featuredPostDynamic {
  & .imgContainer {
    position: relative;
    & img {
      width: 100%;
    }
    & .decoration {
      position: absolute;
      background-color: #8ac691;
      width: 42.5%;
      height: calc(100% + 0.8125rem);
      bottom: 0;
      left: -13px;
      z-index: -1;
    }
  }
  & h3 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 1.5rem 0 0.5rem;
    font-size: clamp(1rem, 3vw, 1.125rem);
  }
  & .richText {
    margin: 0 0 1rem;
    overflow: hidden;
    font-size: 0.875rem;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  & .postShortDescription {
    color: #444;
    font-size: 0.875rem;
  }
}
