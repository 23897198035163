.rowBlock {
  display: flex;
  padding: 0 70px;
  margin-top: 4.5rem;
  margin-bottom: clamp(4rem, 15vw, 7.5rem);
  align-items: center;
  transform: translate3D(0, 50px, 0);
  opacity: 0;
  transition: transform 0.75s ease, opacity 1.5s ease-out;
  @media (max-width: 1280px) {
    justify-content: center;
    flex-direction: column !important;
    align-items: initial;
  }
  @media (max-width: 513px) {
    padding: 0;
  }
  .imgContainer {
    flex: 2;
    &.decorationMargin {
      @media (max-width: 1280px) {
        margin-bottom: 3rem;
      }
      @media (max-width: 745px) {
        margin-bottom: calc(3rem - 25px);
      }
    }
    & .imgWrapper {
      position: relative;
      width: initial;
      height: initial;
      & img {
        width: 100%;
      }
      & .greenDecoration {
        position: absolute;
        top: -50px;
        bottom: -50px;
        left: -50px;
        background: #8ac691;
        width: 49%;
        z-index: -1;
        @media (max-width: 745px) {
          top: -25px;
          bottom: -25px;
          left: -25px;
        }
      }
      & .blueDecoration {
        position: absolute;
        top: 0;
        bottom: -50px;
        left: -50px;
        width: calc(100% + 50px);
        background: #e4f0f4;
        z-index: -1;
        @media (max-width: 745px) {
          bottom: -25px;
          left: -25px;
          width: calc(100% + 25px);
        }
      }
    }
  }
  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.75rem;
    align-self: stretch;

    &:not(.blueBackground):not(.fuchsiaBackground) {
      @media (max-width: 1280px) {
        padding: 1.75rem 1rem 0;
      }
      &.marginLeft {
        margin-left: 1.75rem;
        @media (max-width: 1280px) {
          margin-left: 0;
        }
      }
      &.marginRight {
        margin-right: 1.75rem;
        @media (max-width: 1280px) {
          margin-right: 0;
        }
      }
    }
    &.blueBackground,
    &.fuchsiaBackground {
      margin: 0;
    }
    &.blueBackground {
      background: #bedfea;
      color: #1a1919;
    }
    &.fuchsiaBackground {
      background: #9f6082;
      color: white;
      & h2 {
        color: white;
      }
    }

    & h2 {
      margin-top: 0;
      font-size: clamp(1.5rem, calc(1rem + 1.5645vw), 2.25rem);
    }
    & > div {
      font-size: 0.875rem;
      & *:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.animation {
    transform: translate3D(0, 0, 0);
    opacity: 1;
  }
}

.rowBlock[style*='flex-direction: row-reverse'] .greenDecoration {
  left: unset !important;
  right: -50px;
}
