@use '../core/base';
@import '../settings/_variables';

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 900px) {
  body {
    overflow-x: hidden;
  }
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background: transparent;
  transition: color 0.3s ease-in-out;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 2.857142857142857em;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 0.14285714285714285em;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.main-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2em;
}

.main-content table > thead > tr > th,
.main-content table > tbody > tr > th,
.main-content table > tfoot > tr > th,
.main-content table > thead > tr > td,
.main-content table > tbody > tr > td,
.main-content table > tfoot > tr > td {
  padding: 0.7142857142857143em 0.7142857142857143em;
  line-height: 1.6;
  vertical-align: middle;
  border-top: 1px solid #e5e5e5;
}

.main-content table > thead > tr > th {
  text-transform: uppercase;
  font-weight: 600;
}

.main-content table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 0 solid #e5e5e5;
}

.main-content table > caption + thead > tr:first-child > th,
.main-content table > colgroup + thead > tr:first-child > th,
.main-content table > thead:first-child > tr:first-child > th,
.main-content table > caption + thead > tr:first-child > td,
.main-content table > colgroup + thead > tr:first-child > td,
.main-content table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.main-content table > tbody + tbody {
  border-top: 1px solid #e5e5e5;
}

td,
th {
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $main-font;
  color: #444;
  background: #fff;
  font-style: normal;
  max-width: 1440px;
  margin: 0 auto;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #8ac691;
  text-decoration: none;
}

a:focus {
  outline: none;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid #aaa;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}

code {
  padding: 0.14285714285714285em 0.2857142857142857em;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 0;
}

kbd {
  padding: 0.14285714285714285em 0.2857142857142857em;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  box-shadow: none;
}

pre {
  display: block;
  padding: 0.5em;
  margin: 0 0 1em;
  font-size: 12px;
  line-height: 1.6;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f3f3f3;
  border: 1px solid #e1e1e1;
  border-radius: 0;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

/* --------------------------------------------------------------------- */
/* 2. COMMON CLASSES
/* --------------------------------------------------------------------- */

.pre-scrollable {
  max-height: 24.285714285714285em;
  overflow-y: scroll;
}

/*.fade {*/
/*    opacity: 0;*/
/*    -webkit-transition: opacity .15s linear;*/
/*    -o-transition: opacity .15s linear;*/
/*    transition: opacity .15s linear*/
/*}*/

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $main-font;
  font-weight: $fontblack;
  line-height: 1.2;
  color: #15181f;
  /* color: #333 */
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1.2;
  color: #999;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}

h1,
h2 {
  font-size: 2.25rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.25rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.75rem;
}

p {
  margin: 0 0 1em;
}

.lead {
  margin-bottom: 2em;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .lead {
    font-size: 19.5px;
  }
}

.heading-typed .nth-typed {
  display: inline-block;
  min-width: 1px;
  min-height: 1px;
}

small,
.small {
  font-size: 85%;
}

cite {
  font-style: normal;
}

mark,
.mark {
  background-color: rgba(255, 224, 199, 0.3);
  padding: 0.2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}
.font-italic {
  font-style: italic;
}

.page-header {
  padding-bottom: 0;
  margin: 4em 0 2em;
  border-bottom: 1px solid #e1e1e1;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 1em;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 2em;
}

dt,
dd {
  line-height: 1.6;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #999;
}

blockquote {
  padding: 0 0 0 1.4285714285714286em;
  margin: 2em 0 2em;
  font-size: 13px;
  border-left: 3px solid #e1e1e1;
  font-style: italic;
  font-weight: normal;
  position: relative;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote p {
  font-size: 1.1em;
}

blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.6;
  color: #999;
  text-transform: uppercase;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: '\2014 \00A0';
}

blockquote:after {
  content: '';
}

address {
  margin-bottom: 2em;
  font-style: normal;
  line-height: 1.6;
}

iframe,
embed,
object {
  max-height: 100%;
  max-width: 100%;
  border: 0;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

#wrapper {
  -webkit-transition: transform 0.5s ease 0s;
  -o-transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.full-wrap .container {
  width: 100%;
  max-width: 1280px;
}

.wide-wrap {
  position: relative;
}

@media (min-width: 1200px) {
  .page-header-toggle-offcanvas-always-show .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.wrapper-container {
  position: relative;
  z-index: 10;
  background: #fff;
}

// .content-container {
//   padding-top: 80px;
//   padding-bottom: 80px;
//   /*min-height: 500px;*/
//   background: black;
//   position: relative;
//   z-index: 2;
// }

// @media (max-width: 767px) {
//   .content-container {
//     padding-top: 30px;
//     padding-bottom: 30px;
//   }
// }

// .content-container.no-padding {
//   padding-top: 0;
//   padding-bottom: 0;
// }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-full {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row {
  position: relative;
}

.row.row-custom-margin {
  z-index: 100;
}

.main-sidebar > div:first-child .widget-title {
  margin-top: 0;
}

@media (min-width: 769px) {
  .page-layout-left-sidebar .main-wrap {
    float: right;
  }
  .page-layout-border.page-layout-left-sidebar .content-container > .container > .row {
    background-attachment: scroll;
    background-image: url('../../images/Theme/main-border.jpg');
    background-position: 25% top;
    background-repeat: repeat-y;
  }
  .page-layout-border.page-layout-left-sidebar .main-wrap {
    padding-left: 30px;
  }
  .page-layout-border.page-layout-left-sidebar .sidebar-wrap {
    padding-right: 30px;
  }
  .page-layout-border.page-layout-right-sidebar .content-container > .container > .row {
    background-attachment: scroll;
    background-image: url('../../images/Theme/main-border.jpg');
    background-position: 75% top;
    background-repeat: repeat-y;
  }
  .page-layout-border.page-layout-right-sidebar .main-wrap {
    padding-right: 30px;
  }
  .page-layout-border.page-layout-right-sidebar .sidebar-wrap {
    padding-left: 30px;
  }
  .page-layout-border .content-container:not(.no-padding) {
    padding: 0;
  }
  .page-layout-border .content-container:not(.no-padding) .main-wrap {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .page-layout-border .content-container:not(.no-padding) .sidebar-wrap {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 991px) and (min-width: 769px) {
  .container {
    width: 97%;
  }
  .main-wrap {
    float: left;
    width: 66.66666666666666%;
  }
  .col-md-12.main-wrap {
    width: inherit;
    float: none;
  }
  .sidebar-wrap {
    float: left;
    width: 33.33333333333333%;
  }
}

@media (max-width: 767px) {
  .sidebar-wrap {
    border-top: 1px solid #e1e1e1;
    clear: both;
    margin-top: 60px;
    padding-top: 30px;
  }
  .main-wrap {
    float: left;
    margin-bottom: 30px;
  }
}

.column {
  position: relative;
}

table {
  background-color: #fff;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2em;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 0.7142857142857143em 0.7142857142857143em;
  line-height: 1.6;
  vertical-align: middle;
  border-top: 1px solid #e5e5e5;
}

.table > thead > tr > th {
  text-transform: uppercase;
  font-weight: 600;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 0 solid #e5e5e5;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 1px solid #e5e5e5;
}

.table-bordered {
  border: 1px solid #e5e5e5;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #e5e5e5;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

table col[class*='col-'] {
  position: static;
  float: none;
  display: table-column;
}

table td[class*='col-'],
table th[class*='col-'] {
  position: static;
  float: none;
  display: table-cell;
}

::-webkit-input-placeholder {
  color: #444;
}

:-moz-placeholder {
  color: #444;
}

::-moz-placeholder {
  color: #444;
}

:-ms-input-placeholder {
  color: #444;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 2em;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e1e1e1;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}

input[type='search'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 0.2857142857142857em 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type='file'] {
  display: block;
}

input[type='range'] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

select {
  -webkit-appearance: select;
  -webkit-border-radius: 0px;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: none;
}

output {
  display: block;
  padding-top: 1.5714285714285714rem;
  font-size: 13px;
  line-height: 1.6;
  color: #444;
}

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.5714285714285714rem 1rem;
  line-height: 1.6;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
  -webkit-transition: border-color 0.3s, box-shadow 0.3s;
  -o-transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-control:focus {
  border-color: #333;
  outline: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #aaa;
  opacity: 1;
  filter: alpha(opacity=100);
}

textarea.form-control {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: none;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  line-height: 40px;
  line-height: 1.6 \0;
}

input[type='date'].input-sm,
input[type='time'].input-sm,
input[type='datetime-local'].input-sm,
input[type='month'].input-sm {
  line-height: 137.325%;
}

input[type='date'].input-lg,
input[type='time'].input-lg,
input[type='datetime-local'].input-lg,
input[type='month'].input-lg {
  line-height: 4.842857142857143em;
}

.form-group {
  margin-bottom: 15px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  min-height: 2em;
  margin-top: 0.7142857142857143em;
  margin-bottom: 0.7142857142857143em;
}

.radio label,
.checkbox label {
  padding-left: 1.4285714285714286em;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  position: absolute;
  margin-left: -1.4285714285714286em;
  margin-top: 4px \9;
}

.dh-ie .radio input[type='radio'],
.dh-ie .radio-inline input[type='radio'],
.dh-ie .checkbox input[type='checkbox'],
.dh-ie .checkbox-inline input[type='checkbox'] {
  position: relative \9;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -0.35714285714285715em;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.input-sm {
  height: 137.325%;
  padding: 0.5rem 0.8571428571428571rem;
  font-size: 85%;
  line-height: 1.6;
  -webkit-border-radius: 0;
  border-radius: 0;
}

select.input-sm {
  height: 137.325%;
  line-height: 137.325%;
}

textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}

.input-lg {
  height: 4.842857142857143em;
  padding: 0.8571428571428571rem 2rem;
  font-size: 1.2857142857142858em;
  line-height: 2;
  -webkit-border-radius: 0;
  border-radius: 0;
}

select.input-lg {
  height: 4.842857142857143em;
  line-height: 4.842857142857143em;
}

textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}

.form-flat-radio,
.form-flat-checkbox {
  margin: 0;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 0 0 0 30px;
}

.dh-ie .form-flat-radio,
.dh-ie .form-flat-checkbox {
  padding: 0;
}

.form-flat-radio input,
.form-flat-checkbox input {
  left: -9999px;
  position: absolute;
}

.dh-ie .form-flat-radio input,
.dh-ie .form-flat-checkbox input {
  position: relative;
  left: auto;
}

.form-flat-radio i,
.form-flat-checkbox i {
  background: none repeat scroll 0 0 #fff;
  border-style: solid;
  border-width: 1px;
  display: block;
  height: 18px;
  left: 0;
  outline: medium none;
  position: absolute;
  border-color: #e1e1e1;
  width: 18px;
  top: 50%;
  margin-top: -9px;
  color: #333;
}

.dh-ie .form-flat-radio i,
.dh-ie .form-flat-checkbox i {
  display: none;
}

.form-flat-radio i:after,
.form-flat-checkbox i:after {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.form-flat-radio input:checked + i:after,
.form-flat-checkbox input:checked + i:after {
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-flat-radio i {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-flat-radio i:after {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: '';
  height: 8px;
  left: 4px;
  top: 4px;
  line-height: 1;
  width: 8px;
  position: absolute;
  background: #333;
}

.form-flat-checkbox i:after {
  content: '\f00c';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.form-flat-checkbox i:after {
  font-size: 12px;
  height: 17px;
  left: -1px;
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin-top: 2px;
  width: 17px;
  opacity: 0;
  filter: alpha(opacity=0);
  line-height: 1;
}

.form-flat-select {
  position: relative;
  display: block;
}

.form-flat-select i {
  height: 100%;
  line-height: 40px;
  padding: 0 1em;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  color: #333;
}

.dh-ie .form-flat-select i {
  display: none;
}

.form-flat-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 0.5em 3em 0.5em 1.5em;
  padding-right: 2em \9;
  border: 1px solid #e1e1e1;
  outline: none;
  background: none;
  position: relative;
  z-index: 1;
  height: 40px;
  text-transform: uppercase;
  font-size: 85%;
}

.form-flat-select select option {
  outline: none;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.5714285714285714rem 2rem;
  font-size: 13px;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  text-decoration: none;
}

.btn i,
.btn span {
  z-index: 2;
  position: relative;
}

.btn.btn-icon-left i {
  margin-right: 5px;
}

.btn.btn-icon-right i {
  margin-left: 5px;
}

.btn.btn-icon-right.btn-icon-slide-in {
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
}

.btn.btn-icon-right.btn-icon-slide-in span,
.btn.btn-icon-right.btn-icon-slide-in i {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.btn.btn-icon-right.btn-icon-slide-in i {
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0;
  filter: alpha(opacity=0);
}

.btn.btn-icon-right.btn-icon-slide-in span {
  position: relative;
  left: 0;
}

.btn.btn-icon-right.btn-icon-slide-in:hover span {
  left: -15px;
}

.btn.btn-icon-right.btn-icon-slide-in:hover i {
  opacity: 1;
  filter: alpha(opacity=100);
  right: 25px;
}

.btn-white {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active {
  color: #333;
}

.btn-white:active,
.btn-white.active {
  background-image: none;
}

.btn-white-outline {
  background: transparent;
  border: 2px solid #fff;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  font-weight: 600;
}

.btn-white-outline:hover {
  color: #fff;
  border-color: #8ac691;
  background: #8ac691;
}

.btn-lg {
  padding: 0.8571428571428571rem 2rem;
  font-size: 1.2857142857142858em;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.btn-sm {
  padding: 0.5rem 0.8571428571428571rem;
  font-size: 85%;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.btn-xs {
  padding: 0.21428571428571427rem 0.5rem;
  font-size: 85%;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.btn-align-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 480px) {
  .btn-align-right {
    float: right;
  }
}

.btn-uppercase {
  text-transform: uppercase;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.content-container {
  // margin-top: 137px;
}

.row_custom_small {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

/* === 3.24. TABS === */
.nav-tabs {
  border-color: #f0f0f0;
}
.tabs .nav-tabs > li > a {
  border-color: #f7f7f7;
  background-color: #fff;
  color: #666;
  border-bottom-color: #f0f0f0;
}
.tabs .nav-tabs > li > a:hover {
  background-color: #f0f0f0;
}
.tabs .nav-tabs > li.active > a {
  border-color: #f0f0f0;
  background-color: #f8f8f8;
  color: #666;
  border-bottom-color: transparent;
}
.tab-content {
  box-sizing: content-box;
  padding: 14px 20px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: padding 0.2s ease-in-out;
  transition: padding 0.2s ease-in-out;
  background-color: #f8f8f8;
  border: 1px solid #f0f0f0;
  border-top: none;
}
.commerce-tabs .tab-content {
  border: none;
  background: none;
}

/**
 Table of Contents:

1. RESET
2. DEFAULT CLASSES
3. ELEMENTS
  3.1.  MENUS
  3.2.  HEADERS
  3.3.  BREADCRUMBS
  3.4.  PAGINATION
  3.5.  BUTTONS
  3.6.  CAROUSEL
  3.7.  CAROUSELFRED
  3.8.  TESTIMONIALS
  3.9.  COUNTER
  3.10. COUNTDOWN TIMER
  3.11. INSTAGRAM
  3.12. TYPED TEXT
  3.13. PIE CHART
  3.14. PRICING TABLE
  3.15. TEAM MEMBER
  3.16. FOOTER
  3.17. BLOG POST
  3.18. SIDEBAR
  3.19. CONTACT FORM
  3.20. ACCORDION
  3.21. MESSAGE BOX
  3.22. TEXT BOX
  3.23. TEXT BLOCK
  3.24. TABS
  3.25. CALL TO ACTION
  3.26. PORTFOLIO
  3.27. BLOCK ICON
  3.28. PROGRESSBARS
4. COMMERCE
  4.1.  GENERAL
  4.2.  SINGLE PRODUCT
  4.3.  CART
  4.4.  OTHER

*
* -----------------------------------------------------------------------------
*/

/* --------------------------------------------------------------------- */
/* 1. RESET
/* --------------------------------------------------------------------- */

.clear:before,
.clearfix:before,
.clear:after,
.clearfix:after,
.container:before,
.container:after,
.boxed-wrap:before,
.boxed-wrap:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.navbar-wrap:before,
.navbar-wrap:after,
.primary-nav .dropdown-menu:before,
.primary-nav .dropdown-menu:after,
.paginate:before,
.paginate:after,
.modal-footer:before,
.modal-footer:after,
.pricing-table:before,
.pricing-table:after,
.searchform-result .searchform-result-list .search-entry:before,
.searchform-result .searchform-result-list .search-entry:after,
.posts .posts-wrap:before,
.posts .posts-wrap:after,
.hentry-wrap:before,
.hentry-wrap:after,
.author-info:before,
.author-info:after,
.comment-notes:before,
.comment-notes:after,
.widget_text:before,
.widget_text:after,
.widget_tag_cloud .tagcloud:before,
.widget_tag_cloud .tagcloud:after,
.widget_product_tag_cloud .tagcloud:before,
.widget_product_tag_cloud .tagcloud:after,
.posts-thumbnail-list > li:before,
.posts-thumbnail-list > li:after,
.masonry-filter:before,
.masonry-filter:after,
.masonry-filter .filter-action:before,
.masonry-filter .filter-action:after {
  content: ' ';
  display: table;
}

.clear:after,
.clearfix:after,
.container:after,
.boxed-wrap:after,
.container-fluid:after,
.row:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.navbar-wrap:after,
.primary-nav .dropdown-menu:after,
.paginate:after,
.modal-footer:after,
.pricing-table:after,
.searchform-result .searchform-result-list .search-entry:after,
.posts .posts-wrap:after,
.hentry-wrap:after,
.author-info:after,
.comment-notes:after,
.widget_text:after,
.widget_tag_cloud .tagcloud:after,
.widget_product_tag_cloud .tagcloud:after,
.posts-thumbnail-list > li:after,
.masonry-filter:after,
.masonry-filter .filter-action:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.transform-uppercase {
  text-transform: uppercase;
}

.hide {
  display: none;
}

/*.show {*/
/*    display: block;*/
/*}*/
.collapse.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.hidden {
  display: none;
  visibility: hidden;
}

.nth-typed {
  font-weight: bold;
}

.separator-inherit-theme {
  position: relative;
}

.separator-inherit-theme:before {
  content: '';
  width: 9px;
  height: 9px;
  border: 1px solid #e1e1e1;
  background: #fff;
  position: absolute;
  bottom: -5px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%) rotate(45deg);
  transform: -moz-translateX(-50%) rotate(45deg);
  transform: -webkit-translateX(-50%) rotate(45deg);
  transform: -ms-translateX(-50%) rotate(45deg);
  transform: -o-translateX(-50%) rotate(45deg);
}

/* --------------------------------------------------------------------- */
/* 3. ELEMENTS
/* --------------------------------------------------------------------- */

/* === 3.1. MENUS === */

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 12px 24px;
}

.nav > li > a:hover {
  text-decoration: none;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #e1e1e1;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.6;
  border: 1px solid transparent;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  color: #777;
}

.nav-tabs > li > a > i {
  margin-right: 0.5em;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background: #fff;
  color: #333;
  border: 1px solid #e1e1e1;
  border-bottom-color: transparent;
  cursor: default;
}

.tab-content {
  padding-top: 20px;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.tab-content .widget {
  margin-bottom: 0;
}

.widget .tab-content {
  padding-bottom: 0;
}

.navbar {
  margin-bottom: 2em;
  border-bottom: 0px solid transparent;
  position: relative;
}

@media (min-width: 900px) {
  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 900px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: block;
    height: auto;
    padding-bottom: 0;
    overflow: visible;
  }
  .navbar-collapse.in {
    overflow-y: visible;
  }
}

.container > .navbar-wrap,
.container-fluid > .navbar-wrap,
.container > .navbar-header-left,
.container-fluid > .navbar-header-left,
.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
  margin-right: 0;
  margin-left: 0;
}

@media (min-width: 900px) {
  .container > .navbar-wrap,
  .container-fluid > .navbar-wrap,
  .container > .navbar-header-left,
  .container-fluid > .navbar-header-left,
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  -webkit-transform: translate3d(0, -60px, 0);
  transform: translate3d(0, -60px, 0);
}

@media (min-width: 900px) {
  .navbar-fixed-top {
    border-radius: 0;
  }
}

.boxed-wrap .navbar-fixed-top {
  left: auto;
  right: auto;
}

.navbar-fixed-top.fixed-transition {
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.navbar-fixed-top {
  top: 0;
}

.navbar-brand {
  float: left;
  font-size: 0;
}

@media (max-width: 900px) {
  .navbar-brand {
    display: block;
    height: 74px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    position: absolute;
    left: 50%;
  }
  .page-header-overlay .navbar-brand {
    position: relative;
    left: auto;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .navbar-brand img {
    max-height: 60px;
    position: relative;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
  }
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin: 19px 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
  height: 35px;
  line-height: 35px;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  position: relative;
  transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -webkit-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -o-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -moz-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  background-color: #000;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

.navbar-toggle.x .bar-top {
  top: 7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}

.navbar-toggle.x .bar-middle {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.navbar-toggle.x .bar-bottom {
  top: -5px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}

@media (min-width: 900px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-header .navbar-toggle {
  border-color: transparent;
}

.navbar-header .navbar-toggle:hover,
.navbar-header .navbar-toggle:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 2em;
}

@media (max-width: 899px) {
  .navbar-nav > li > a {
    line-height: 2em;
    font-size: 13px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 899px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu > li .dropdown-menu > li > a {
    padding-left: 3.5714285714285716em;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 0.7142857142857143em 1.0714285714285714em 0.7142857142857143em 1.7857142857142858em;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 2em;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover {
    background-image: none;
  }
}

@media (min-width: 900px) {
  .navbar-nav {
    float: right;
    margin: 0;
  }
  .navbar-nav > li {
    display: inline-block;
  }
  .navbar-nav.navbar-right:last-child {
    margin-right: -0.7142857142857143em;
  }
}

.header-type-classic .navbar-fixed-top,
.header-type-center .navbar-fixed-top {
  -webkit-box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46);
  box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46);
}

@media (min-width: 900px) {
  .header-type-classic .navbar-fixed-top .navbar-nav > li > .dropdown-menu,
  .header-type-center .navbar-fixed-top .navbar-nav > li > .dropdown-menu {
    top: 60px;
  }
}

.header-type-classic .navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@media (min-width: 900px) {
  .header-type-classic .navbar-nav > li > .dropdown-menu {
    top: 90px;
  }
}

.navbar-default {
  background-color: transparent;
  border-color: #333;
}

@media (min-width: 900px) {
  .header-type-center .navbar-fixed-top.navbar-default {
    height: 60px;
  }
}

.navbar-default .navbar-brand {
  color: #333;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #1a1a1a;
  background-color: transparent;
}

.navbar-default .navbar-nav > li > a {
  color: #333;
}
.navbar-default .navbar-nav > .open > a:focus {
  background: none;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background: none;
}

/* === 3.2. HEADERS === */

.navbar-default .navbar-nav > li > a i {
  margin-right: 0.35714285714285715em;
}

.header-transparent:not(.header-navbar-fixed):not(.header-type-classic-right)
  .navbar-default
  .navbar-nav
  > li
  > a {
  color: #fff;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #8ac691;
  background-color: transparent;
}

.navbar-default .navbar-nav .current-menu-item > a,
.navbar-default .navbar-nav .current-menu-item > a:focus {
  color: #8ac691;
  background-color: transparent;
}

.header-transparent:not(.header-navbar-fixed) .navbar-default .navbar-nav .active > a,
.header-transparent:not(.header-navbar-fixed) .navbar-default .navbar-nav .open > a {
  color: #8ac691;
}

.navbar-default .navbar-nav > .current-menu-ancestor > a,
.navbar-default .navbar-nav > .current-menu-parent > a,
.navbar-default .navbar-nav > .current-menu-ancestor > a:hover,
.navbar-default .navbar-nav > .current-menu-parent > a:hover {
  color: #8ac691;
  background-color: transparent;
}

.navbar-default .navbar-nav > .current-menu-ancestor > a:before,
.navbar-default .navbar-nav > .current-menu-parent > a:before,
.navbar-default .navbar-nav > .current-menu-ancestor > a:hover:before,
.navbar-default .navbar-nav > .current-menu-parent > a:hover:before {
  display: block;
}

.navbar-default .navbar-collapse {
  border-color: #333;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: transparent;
}

@media (max-width: 899px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #333;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #8ac691;
    background-color: transparent;
  }
}

.navbar-brand {
  padding: 0;
}

@media (min-width: 900px) {
  .header-type-classic .navbar-brand {
    line-height: 90px;
  }
}

.navbar-fixed-top .navbar-brand {
  line-height: 60px;
}

.navbar-brand img {
  max-height: 70px;
  max-width: 200px;
}

.navbar-brand .logo-fixed {
  display: none;
}

@media (max-width: 899px) {
  .navbar-brand .logo {
    display: block;
    max-width: 200px;
  }
  .navbar-brand .logo-fixed {
    display: none;
  }
}

.navbar-fixed-top .logo {
  display: none;
}

.navbar-fixed-top .logo-fixed {
  display: inline-block;
  max-height: 60px;
}

.header-type-classic .navbar-fixed-top .logo-fixed {
  max-height: 60px;
}

.navbar-brand .logo-mobile {
  max-height: 45px;
  display: none;
  max-width: 120px;
  vertical-align: top;
}

@media (max-width: 899px) {
  .navbar-brand .logo,
  .navbar-brand .logo-fixed {
    display: none;
  }
  .navbar-brand .logo-mobile {
    display: inline-block;
  }
}

.navbar-default {
  margin-bottom: 0;
  z-index: 999;
  border: none;
  border-radius: 0;
}

@media (min-width: 900px) {
  .navbar-default .primary-navbar-collapse {
    text-align: center;
  }
}

@media (max-width: 899px) {
  .navbar-default .primary-navbar-collapse {
    float: none;
    padding: 0;
    width: auto;
  }
}

@media (min-width: 900px) {
  .header-type-center .navbar-default .navbar-header {
    float: none;
  }
}

.navbar-default .navbar-collapse {
  border: none;
}

.primary-nav .menu-item-navbar-brand {
  display: none;
}

@media (min-width: 900px) {
  .header-type-center .navbar-wrap > .navbar-header {
    padding: 30px 0 20px;
  }
  .header-type-center .navbar-wrap > .navbar-header .navbar-brand {
    float: none;
  }
  .header-type-center .navbar-fixed-top .navbar-header {
    padding: 0;
  }
  .header-type-center .navbar-fixed-top .navbar-header .navbar-header-left {
    display: none;
  }
  .header-type-center .navbar-fixed-top .navbar-header .navbar-header-center,
  .header-type-center .navbar-fixed-top .navbar-header .navbar-header-right {
    position: absolute;
    width: auto;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 11;
  }
  .header-type-center .navbar-fixed-top .navbar-header .navbar-header-center {
    left: 0;
  }
  .header-type-center .navbar-fixed-top .navbar-header .navbar-header-right {
    right: 0;
  }
  .header-type-center .navbar-default .primary-navbar-collapse {
    float: none;
    padding: 0;
  }
  .header-type-center.header-type-center .primary-nav {
    float: none;
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .header-type-center.header-type-center .primary-nav > li {
    display: inline-block;
    float: none;
  }
}

.navbar-collapse {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-wrap {
  position: relative;
}

.navicon {
  margin-right: 0.5714285714285714rem;
  font-size: 13px;
}

@media (max-width: 899px) {
  .navicon {
    display: none;
  }
}

.megamenu-title {
  font-size: 1em;
  margin: 0;
  padding: 30px 0 5px;
  text-transform: uppercase;
}

.megamenu-title a {
  background: transparent;
}

.megamenu-title .caret {
  display: none;
}

.primary-nav,
.offcanvas-nav {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

.primary-nav .dropdown-menu,
.offcanvas-nav .dropdown-menu {
  font-weight: 300;
}

.primary-nav {
  margin: 0 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}

.primary-nav .menu-item-badge {
  margin-right: 1.2em;
  width: 15px;
  display: inline-block;
  text-align: center;
}

.primary-nav .menu-item-badge img {
  max-height: 20px;
}

@media (min-width: 900px) {
  .primary-nav > .megamenu > .dropdown-menu {
    background: #fff;
    border-top: 2px solid #8ac691;
    padding: 0;
  }
  .primary-nav > .megamenu.megamenu-fullwidth {
    position: static;
  }
  .primary-nav > .megamenu.megamenu-fullwidth > .dropdown-menu {
    width: 100%;
  }
  .primary-nav > .megamenu > .dropdown-menu .widget {
    margin-bottom: 0;
  }
  .primary-nav > .megamenu > .dropdown-menu .megamenu-sidebar {
    padding: 15px;
  }
  .primary-nav > .megamenu > .dropdown-menu .megamenu-sidebar .products {
    margin: 0;
  }
  .primary-nav > .megamenu > .dropdown-menu .megamenu-sidebar .products li {
    margin: 0;
  }
  .primary-nav > .megamenu > .dropdown-menu .megamenu-sidebar .products li img {
    width: 100%;
  }
  .primary-nav > .megamenu > .dropdown-menu .megamenu-sidebar a {
    margin: 0;
    padding: 0;
  }
  .primary-nav > .megamenu > .dropdown-menu > li {
    display: table-cell;
    vertical-align: top;
    border-bottom: none;
    border-top: none;
    padding: 8px 30px 8px 0;
    min-width: 200px;
  }
  .primary-nav > .megamenu > .dropdown-menu > li:first-child {
    padding-left: 30px;
  }
  .primary-nav > .megamenu > .dropdown-menu > li.mega-col-12 {
    width: 100%;
  }
  .primary-nav > .megamenu > .dropdown-menu > li.mega-col-3 {
    width: 25%;
  }
  .primary-nav > .megamenu > .dropdown-menu > li.mega-col-6 {
    width: 50%;
  }
  .primary-nav > .megamenu > .dropdown-menu > li.mega-col-4 {
    width: 33.3333%;
  }
  .primary-nav > .megamenu > .dropdown-menu > li > a {
    background: #fff;
  }
  .primary-nav > .megamenu > .dropdown-menu > li .dropdown-menu {
    display: block !important;
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
    float: none;
    left: 0;
    top: 0;
    opacity: 1 !important;
    padding: 0;
  }
  .primary-nav > .megamenu > .dropdown-menu > li .dropdown-menu a {
    background: #fff;
  }
  .primary-nav > .megamenu > .dropdown-menu > li .dropdown-menu a:hover {
    color: #8ac691;
  }
  .primary-nav > .megamenu.open > .dropdown-menu {
    display: table !important;
  }
}

.primary-nav .dropdown-menu {
  min-width: 200px;
}

.primary-nav .dropdown-menu li {
  border-bottom: none;
}

.primary-nav .dropdown-menu li.menu-item {
  border-top: 1px solid #e1e1e1;
}

.primary-nav .dropdown-menu li:first-child {
  border-top: none;
}

.primary-nav .dropdown-menu li.menu-item-has-children > .dropdown-menu {
  margin: 0;
  left: 100%;
  top: 0;
  padding: 8px 22px;
}

@media (max-width: 899px) {
  .primary-nav .dropdown-menu li .megamenu-title {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0.7142857142857143em 1.0714285714285714em 0.7142857142857143em 1.7857142857142858em;
    position: relative;
    color: #333;
  }
  .primary-nav .dropdown-menu li .megamenu-title .caret {
    cursor: pointer;
  }
}

.primary-nav .dropdown-menu a {
  color: #333;
  padding: 12px 0;
}

@media (min-width: 900px) {
  .primary-nav .dropdown-menu a {
    background: #fff;
  }
}

@media (min-width: 900px) {
  .primary-nav .dropdown-menu a:hover {
    color: #8ac691;
    background: transparent;
  }
}

.primary-nav .dropdown-menu .open > a {
  color: #8ac691;
}

.primary-nav li.menu-item-has-children {
  position: relative;
}

@media (max-width: 899px) {
  .primary-nav li.menu-item-has-children.open > .megamenu-title > .caret:before,
  .primary-nav li.menu-item-has-children.open > a > .caret:before {
    content: '\f068';
    position: relative;
    display: inline-block;
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
  }
}

@media (max-width: 899px) {
  .primary-nav li {
    border-bottom: 1px solid #e1e1e1;
  }
  .primary-nav li:last-child {
    border-bottom: none;
  }
}

.primary-nav > li:not(.megamenu) > .dropdown-menu {
  border-top: 2px solid #8ac691;
  padding: 8px 22px;
}

.primary-nav > li > a {
  position: relative;
  text-transform: uppercase;
}

@media (min-width: 900px) {
  .header-type-classic .primary-nav > li > a {
    padding: 0 20px;
    line-height: 90px;
  }
  .header-type-center .primary-nav > li > a {
    line-height: 70px;
    vertical-align: middle;
    text-align: center;
    display: block;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.primary-nav > li.menu-item-has-children {
  position: relative;
}

.primary-nav > li.menu-item-has-children .caret {
  border: none;
  vertical-align: baseline;
  margin-left: 0.14285714285714285em;
  font-size: 1em;
}

@media (min-width: 900px) {
  .primary-nav > li.menu-item-has-children .caret:before {
    content: '\f107';
    position: relative;
    display: inline-block;
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
  }
}

@media (max-width: 899px) {
  .primary-nav > li.menu-item-has-children .caret {
    height: 100%;
    padding: 10px;
    position: absolute;
    right: 0px;
    top: 0;
    width: 40px;
    text-align: center;
  }
  .primary-nav > li.menu-item-has-children .caret:before {
    content: '\f067';
    position: relative;
    display: inline-block;
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
  }
}

@media (min-width: 900px) {
  .primary-nav > li.menu-item-has-children li.menu-item-has-children .caret {
    position: absolute;
    right: 1rem;
  }
  .primary-nav > li.menu-item-has-children li.menu-item-has-children .caret:before {
    content: '\f105';
    position: relative;
    display: inline-block;
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
  }
}

.primary-nav > li.current-menu-parent > a,
.primary-nav > li.current-menu-parent > a:hover {
  color: #8ac691;
  background-color: transparent;
}

@media (max-width: 899px) {
  .primary-nav .dropdown-menu {
    position: relative;
    float: none;
  }
  .primary-nav > li > a {
    font-size: 13px;
  }
}

.navbar-fixed-top {
  height: 60px;
  z-index: 1001;
}

.navbar-fixed-top .navbar-default-container {
  background: #fff;
}

.navbar-fixed-top .navbar-nav.primary-nav > li > a {
  line-height: 60px;
}

.navbar-fixed-top .navbar-nav.primary-nav > li > a .underline::after {
  background: #333;
}

.navbar-search {
  position: relative;
}

.navbar-search .search-form-wrap {
  color: #444;
}

.navbar-search .search-form-wrap.show-popup {
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #e1e1e1;
  z-index: 1000;
  background: #fff;
  margin: -1px 0 0 0;
  display: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

@media (max-width: 899px) {
  .navbar-search .search-form-wrap.show-popup {
    background: rgba(0, 0, 0, 0);
    margin: 0;
    border: none;
  }
}

.navbar-search .search-form-wrap.show-popup.show {
  -webkit-animation: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s normal none 1 search_pop;
  -o-animation: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s normal none 1 search_pop;
  animation: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s normal none 1 search_pop;
}

.navbar-search .search-form-wrap.show-popup:before {
  border-color: #e1e1e1;
  border-style: solid none none solid;
  border-width: 1px 0 0 1px;
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  right: 20px;
  top: -5px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 9px;
  z-index: 1010;
  background: #fff;
}

.navbar-search .search-form-wrap.show-popup .searchform-loading {
  display: none;
}

.navbar-search .search-form-wrap.show-popup .searchform {
  position: relative;
  margin: 15px;
}

.navbar-search .search-form-wrap.show-popup .searchform:after {
  content: '\f002';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.navbar-search .search-form-wrap.show-popup .searchform.loading:after {
  content: '\f110';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.navbar-search .search-form-wrap.show-popup .searchform.loading:after {
  -webkit-animation: fa-spin 1s infinite linear;
  -o-animation: fa-spin 1s infinite linear;
  animation: fa-spin 1s infinite linear;
  -webkit-animation-timing-function: steps(8, start);
  animation-timing-function: steps(8, start);
}

.navbar-search .search-form-wrap.show-popup .searchform:after {
  color: #fff;
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 16px;
}

.navbar-search .search-form-wrap.show-popup .searchform:before {
  content: '';
  width: 34px;
  height: 34px;
  position: absolute;
  right: 0;
  text-align: center;
  background: #8ac691;
  line-height: 34px;
  color: #fff;
}

@media (max-width: 899px) {
  .navbar-search .search-form-wrap.show-popup .searchform:before {
    background: rgba(0, 0, 0, 0);
  }
}

.navbar-search .search-form-wrap.show-popup .searchinput {
  width: 250px;
  height: 34px;
  border: 1px solid #e1e1e1;
  padding: 5px;
  padding-right: 36px;
  background: #f8f8f8;
  font-size: 13px;
  outline: none;
}

.header-type-classic .navbar-default-wrap:not(.container) > .row {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 899px) {
  .navbar-default {
    height: 74px;
  }
  .navbar-default-col {
    padding: 0 10px;
  }
  .navbar-toggle {
    float: left;
    position: relative;
  }
  .header-container {
    position: relative;
    background: #fff;
  }
  .navbar-brand {
    float: none;
    text-align: center;
  }
}

.navbar-minicart {
  position: relative;
}

@media (max-width: 899px) {
  .navbar-minicart {
    display: none;
  }
}

.search-icon-mobile {
  display: none;
  position: relative;
  float: right;
  padding: 9px 10px;
  margin: 19px 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
  height: 35px;
  line-height: 35px;
  line-height: 1;
}

.search-icon-mobile:focus {
  outline: 0;
}

.search-icon-mobile .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  position: relative;
  transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -webkit-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -o-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -moz-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  background-color: #000;
}

.search-icon-mobile .icon-bar + .icon-bar {
  margin-top: 4px;
}

.search-icon-mobile.x .bar-top {
  top: 7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}

.search-icon-mobile.x .bar-middle {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.search-icon-mobile.x .bar-bottom {
  top: -5px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}

@media (min-width: 900px) {
  .search-icon-mobile {
    display: none;
  }
}

.search-icon-mobile i {
  font-size: 16px;
  height: auto;
}

@media (max-width: 899px) {
  .search-icon-mobile {
    display: block;
  }
}

.cart-icon-mobile {
  display: none;
  margin: 19px 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
  height: 35px;
  line-height: 35px;
  float: right;
  line-height: 1;
  padding: 9px 10px;
  font-size: 16px;
  color: #000;
  position: relative;
}

.cart-icon-mobile:focus {
  outline: 0;
}

.cart-icon-mobile .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  position: relative;
  transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -webkit-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -o-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  -moz-transition: transform 0.4s ease 0s, opacity 0.3s ease 0s;
  background-color: #000;
}

.cart-icon-mobile .icon-bar + .icon-bar {
  margin-top: 4px;
}

.cart-icon-mobile.x .bar-top {
  top: 7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}

.cart-icon-mobile.x .bar-middle {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.cart-icon-mobile.x .bar-bottom {
  top: -5px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}

@media (min-width: 900px) {
  .cart-icon-mobile {
    display: none;
  }
}

.cart-icon-mobile:hover {
  color: #000;
  background: rgba(255, 255, 255, 0.2);
}

.cart-icon-mobile span {
  background: none repeat scroll 0 0 #8ac691;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  color: #fff;
  font-size: 85%;
  height: 1.7em;
  line-height: 1.7em;
  position: absolute;
  right: 1px;
  text-align: center;
  top: -2px;
  width: 1.7em;
  font-size: 12px;
}

.cart-icon-mobile .minicart-icon-svg {
  font-size: 16px;
  width: 20px;
}

@media (max-width: 899px) {
  .cart-icon-mobile {
    display: block;
  }
}

.header-type-toggle-offcanvas {
  background: #fff;
}

.header-type-toggle-offcanvas .navbar-default-wrap {
  text-align: center;
}

@media (min-width: 900px) {
  .header-type-toggle-offcanvas.header-logo-position-left .navbar-default-wrap {
    text-align: left;
  }
  .header-type-toggle-offcanvas.header-logo-position-left .navbar-toggle-fixed {
    left: auto;
    right: 15px;
  }
  .header-type-toggle-offcanvas.header-logo-position-left .navbar-toggle-right {
    right: 65px;
  }
}

.header-type-toggle-offcanvas .navbar-default-wrap {
  position: relative;
}

.header-type-toggle-offcanvas .navbar-default.navbar-fixed-top .navbar-header {
  line-height: 60px;
}

.header-type-toggle-offcanvas .navbar-default.navbar-fixed-top .navbar-header img {
  max-height: 60px;
}

.header-type-toggle-offcanvas .navbar-header {
  float: none;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  z-index: 1031;
  padding: 0;
}

@media (min-width: 900px) {
  .header-type-toggle-offcanvas .navbar-header {
    line-height: 74px;
  }
}

.header-type-toggle-offcanvas .navbar-header .navbar-brand {
  padding: 0;
}

@media (max-width: 899px) {
  .header-type-toggle-offcanvas .navbar-header .navbar-brand {
    position: relative;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    left: auto;
  }
}

.header-type-toggle-offcanvas .navbar-toggle-right {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 1031;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.header-type-toggle-offcanvas .navbar-toggle-right > div {
  display: inline-block;
  margin-left: 30px;
}

@media (max-width: 899px) {
  .header-type-toggle-offcanvas .navbar-toggle-right > div {
    margin-left: 15px;
  }
}

.header-type-toggle-offcanvas .navbar-toggle-right > div .minicart {
  right: 0;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.header-type-toggle-offcanvas .navbar-toggle-right > div .minicart:before {
  display: none;
}

@media (max-width: 899px) {
  .header-type-toggle-offcanvas .navbar-toggle-right > div.navbar-user {
    display: none;
  }
}

.header-type-toggle-offcanvas .navbar-toggle-right .navbar-user {
  position: relative;
  line-height: 74px;
}

.header-type-toggle-offcanvas .navbar-toggle-right .navbar-user .dropdown-menu {
  padding: 10px;
  left: auto;
  right: 0;
  font-size: 85%;
}

.header-type-toggle-offcanvas .navbar-toggle-right .navbar-user:hover .dropdown-menu {
  display: block;
}

@media (max-width: 900px) {
  .header-type-toggle-offcanvas .navbar-toggle-fixed {
    display: block;
  }
  .header-type-toggle-offcanvas .navbar-minicart,
  .header-type-toggle-offcanvas .navbar-search > a {
    display: block;
  }
}

.header-type-toggle-offcanvas .navbar:not(.navbar-fixed-top) {
  position: relative;
}

.navbar-toggle-fixed {
  position: absolute;
  top: 50%;
  left: 20px;
  overflow: hidden;
  height: 35px;
  z-index: 1031;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.navbar-toggle-fixed .navbar-toggle {
  padding: 0;
  margin: 0;
  float: none;
  display: block;
}

.navbar-toggle-fixed .navbar-toggle .icon-bar {
  border-color: #333;
  background: #333;
}

@media (min-width: 900px) {
  .header-transparent:not(.header-navbar-fixed) .navbar-toggle-fixed .navbar-toggle .icon-bar {
    border-color: #fff;
    background: #fff;
  }
}

.offcanvas-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: fixed;
  top: 0;
  -webkit-transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  width: 100%;
  z-index: 1030;
  visibility: hidden;
}

.offcanvas {
  -webkit-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  background: none repeat scroll 0 0 #fff;
  bottom: 0;
  position: fixed;
  z-index: 1040;
  left: 0;
  -webkit-transform: translate3d(-300px, 0, 0);
  transform: translate3d(-300px, 0, 0);
  top: 0;
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 320px) {
  .offcanvas {
    width: 260px;
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
  }
}

@media (min-width: 900px) {
  .offcanvas.navbar-offcanvas {
    left: auto;
    right: 0;
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
  }
}

.offcanvas.navbar-offcanvas .navbar-toggle-fixed .navbar-toggle {
  float: right;
}

.offcanvas-wrap {
  position: relative;
  padding: 0 20px;
}

@media (max-width: 782px) {
  body.admin-bar .offcanvas-wrap {
    margin-top: 46px;
  }
}

.offcanvas-wrap .widget {
  margin-bottom: 2rem;
}

.offcanvas-user {
  margin-left: -20px;
  margin-right: -20px;
  background: #333;
  padding: 0 15px;
  margin-bottom: 20px;
}

.offcanvas-user a {
  height: 74px;
  line-height: 74px;
  color: #fff;
  width: 100%;
  float: left;
  text-align: center;
  display: block;
  text-transform: uppercase;
  font-size: 85%;
}

@media (max-width: 320px) {
  .offcanvas-user a {
    font-size: 75%;
  }
}

.offcanvas-user a.offcanvas-user-wishlist-link {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  width: 50%;
}

.offcanvas-user a.offcanvas-user-wishlist-link ~ .offcanvas-user-account-link {
  width: 50%;
}

@media (max-width: 899px) {
  .navbar-toggle-right .navbar-user,
  .offcanvas-sidebar-wrap,
  .navbar-toggle-fixed {
    display: none;
  }
  .open-offcanvas #wrapper {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
  .open-offcanvas[data-spy='scroll'] #wrapper {
    position: inherit;
    width: auto;
    height: auto;
  }
  .open-offcanvas .offcanvas {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .open-offcanvas .offcanvas-overlay {
    opacity: 0.8;
    filter: alpha(opacity=80);
    visibility: visible;
  }
}

.offcanvas-user,
.mobile-offcanvas-navbar {
  display: block;
}

.header-offcanvas-topbar {
  display: none;
}

@media (min-width: 1200px) {
  .header-offcanvas.header-offcanvas-always-show {
    -webkit-box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.17);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .header-offcanvas.header-offcanvas-always-show .navbar-toggle-fixed {
    display: none;
  }
  .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar {
    display: block;
    text-align: center;
  }
  .header-offcanvas.header-offcanvas-always-show
    .header-offcanvas-topbar
    .header-offcanvas-topbar-icons {
    background: #f3f3f3;
    margin: 0 -20px;
  }
  .header-offcanvas.header-offcanvas-always-show
    .header-offcanvas-topbar
    .header-offcanvas-topbar-icons
    > .navcart {
    float: left;
    width: 50%;
  }
  .header-offcanvas.header-offcanvas-always-show
    .header-offcanvas-topbar
    .header-offcanvas-topbar-icons
    .navcart
    ~ .navbar-user {
    float: left;
    width: 50%;
  }
  .header-offcanvas.header-offcanvas-always-show
    .header-offcanvas-topbar
    .header-offcanvas-topbar-icons
    .navcart
    ~ .navbar-user
    a
    span {
    display: none;
  }
  .header-offcanvas.header-offcanvas-always-show
    .header-offcanvas-topbar
    .header-offcanvas-topbar-icons
    > div {
    position: relative;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .header-offcanvas.header-offcanvas-always-show
    .header-offcanvas-topbar
    .header-offcanvas-topbar-icons
    > div:nth-child(2):before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: #e1e1e1;
  }
  .header-offcanvas.header-offcanvas-always-show .header-offcanvas-topbar .navbar-header {
    float: none;
    margin: 30px 0;
  }
  .header-offcanvas.header-offcanvas-always-show
    .header-offcanvas-topbar
    .navbar-header
    .navbar-brand {
    float: none;
  }
  .header-offcanvas.header-offcanvas-always-show ~ #wrapper {
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
    width: calc(100% - 300px);
    width: -webkit-calc(100% - 300px);
    width: -moz-calc(100% - 300px);
  }
  .header-offcanvas.header-offcanvas-always-show ~ #wrapper .commerce h3[class^='woo-cart'] {
    font-size: 1em;
  }
  .header-offcanvas.header-offcanvas-always-show
    ~ #wrapper
    .commerce
    .commerce-shipping-calculator
    h3
    a:after {
    margin-top: 1px;
  }
  .header-offcanvas.header-offcanvas-always-show ~ #wrapper #header {
    display: none;
  }
  .header-offcanvas.header-offcanvas-always-show ~ #wrapper .navbar-toggle-fixed {
    display: none;
  }
  .header-offcanvas.header-offcanvas-always-show ~ #wrapper > .offcanvas-overlay {
    display: none;
  }
  .header-offcanvas.header-offcanvas-always-show ~ #wrapper .container,
  .header-offcanvas.header-offcanvas-always-show ~ #wrapper .container-fluid {
    width: 100%;
  }
}

.header-offcanvas .navbar-toggle-fixed {
  position: relative;
  left: auto;
  top: auto;
  height: auto;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 20px 0;
  text-align: right;
}

.header-offcanvas .navbar-toggle-fixed .navbar-toggle {
  height: 30px;
  line-height: 30px;
}

.header-offcanvas .navbar-toggle-fixed .navbar-toggle .bar-top {
  top: 7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}

.header-offcanvas .navbar-toggle-fixed .navbar-toggle .bar-middle {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.header-offcanvas .navbar-toggle-fixed .navbar-toggle .bar-bottom {
  top: -5px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
}

.header-offcanvas .offcanvas-widget {
  margin-top: 2rem;
}

@media (max-width: 480px) {
  .header-offcanvas .widget .widget-title {
    font-size: 1em;
  }
}

.header-offcanvas .language-switcher,
.header-offcanvas .currencies-switcher {
  margin: 0 0 20px;
}

.header-offcanvas .language-switcher label,
.header-offcanvas .currencies-switcher label {
  font-size: 85%;
  margin: 0;
  width: 35%;
  text-transform: uppercase;
}

.header-offcanvas .language-switcher .form-flat-select,
.header-offcanvas .currencies-switcher .form-flat-select {
  display: inline-block;
  width: 62%;
}

.header-offcanvas .language-switcher .form-flat-select select,
.header-offcanvas .currencies-switcher .form-flat-select select {
  width: 100%;
  padding: 0.5em 2em 0.5em 1em;
}

@media (min-width: 900px) {
  .header-offcanvas .offcanvas-user,
  .header-offcanvas .mobile-offcanvas-navbar {
    display: none;
  }
  .open-offcanvas .header-offcanvas {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .open-offcanvas .offcanvas-overlay {
    opacity: 0.8;
    filter: alpha(opacity=80);
    visibility: visible;
  }
}

.offcanvas-nav {
  margin: 0;
  padding: 0;
}

.offcanvas-nav li {
  display: block;
  position: relative;
}

.offcanvas-nav li.menu-item-has-children {
  position: relative;
}

.offcanvas-nav li.menu-item-has-children .caret {
  border: none;
  vertical-align: baseline;
  margin-left: 0.14285714285714285em;
  font-size: 1em;
  height: 100%;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 0;
  width: 40px;
  text-align: center;
}

.offcanvas-nav li.menu-item-has-children .caret:before {
  content: '\f067';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.offcanvas-nav li.menu-item-has-children.open > .megamenu-title > .caret:before,
.offcanvas-nav li.menu-item-has-children.open > a > .caret:before,
.offcanvas-nav
  li.menu-item-has-children.open
  > .dropdown-menu
  > .dropdown-submenu.open
  > a
  > .caret:before {
  content: '\f068';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.offcanvas-nav li.menu-item-has-children.open .dropdown-menu {
  background-color: transparent;
  border: 0 none;
  box-shadow: none;
  float: none;
  margin-top: 0;
  position: static;
  width: auto;
  padding: 0;
}

.offcanvas-nav li.menu-item-has-children.open .dropdown-menu > li > a {
  line-height: 1.65517em;
  padding: 0.689655em 1.03448em 0.689655em 1.72414em;
}

.offcanvas-nav li.menu-item-has-children.open .dropdown-menu .dropdown-menu > li > a {
  padding-left: 3.44828em;
}

.offcanvas-nav li.active > a,
.offcanvas-nav li.open > a,
.offcanvas-nav a:hover {
  color: #8ac691;
}

.offcanvas-nav li.active > a:before,
.offcanvas-nav li.open > a:before,
.offcanvas-nav a:hover:before {
  background: #8ac691;
}

.offcanvas-nav > li > a {
  padding: 0.689655em 0;
}

.offcanvas-nav a {
  display: block;
  line-height: 1.65517em;
  padding: 0.689655em 0;
  text-transform: uppercase;
  position: relative;
  color: #333;
}

.offcanvas-nav a:before {
  content: '';
  width: 10px;
  height: 1px;
  background: #777;
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.offcanvas-nav .dropdown-menu > li > a:hover,
.offcanvas-nav .dropdown-menu > li > a:focus {
  background: transparent;
}

.offcanvas-nav .dropdown-menu > li.active > a,
.offcanvas-nav .dropdown-menu > li.active > a:hover,
.offcanvas-nav .dropdown-menu > li.active > a:focus {
  background: transparent;
}

.offcanvas-nav .dropdown-menu a {
  color: #333;
  font-size: 13px;
}

.offcanvas-nav .dropdown-menu a:hover {
  color: #8ac691;
}

.offcanvas-nav .navbar-search {
  display: none;
}

@media (min-width: 900px) {
  .page-header-overlay.open-offcanvas {
    overflow: hidden;
  }
  .page-header-overlay .offcanvas-wrap {
    padding-bottom: 20px;
  }
  .page-header-overlay .padding-wrap .navbar-offcanvas-overlay,
  .page-header-overlay .padding-wrap .offcanvas-overlay {
    left: -40px;
    right: -40px;
  }
  .page-header-overlay .padding-wrap .offcanvas-overlay {
    width: auto;
  }
  .navbar-offcanvas-overlay {
    padding-bottom: 60px;
    padding-top: 60px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    width: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    background: rgba(26, 26, 26, 0.9);
    -webkit-transform-origin: center center 0;
    -moz-transform-origin: center center 0;
    -ms-transform-origin: center center 0;
    transform-origin: center center 0;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  .navbar-offcanvas-overlay .offcanvas-widget,
  .navbar-offcanvas-overlay .language-switcher,
  .navbar-offcanvas-overlay .currencies-switcher {
    display: none;
  }
  .navbar-offcanvas-overlay .navbar-toggle .icon-bar {
    width: 50px;
  }
  .navbar-offcanvas-overlay .offcanvas-wrap {
    max-width: 90%;
    width: 1170px;
    margin: 0 auto;
  }
  .open-offcanvas .navbar-offcanvas-overlay {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  .navbar-offcanvas-overlay .navbar-toggle-fixed .navbar-toggle {
    display: inline-block;
  }
  .navbar-offcanvas-overlay .navbar-toggle-fixed .navbar-toggle .icon-bar {
    border-color: #fff;
    background: #fff;
  }
  .navbar-offcanvas-overlay .offcanvas-nav li {
    text-align: center;
  }
  .navbar-offcanvas-overlay .offcanvas-nav li .megamenu-title {
    color: #fff;
  }
  .navbar-offcanvas-overlay .offcanvas-nav li a {
    color: #fff;
    text-align: center;
    display: inline-block;
    padding-top: 0.8571428571428571rem;
    padding-bottom: 0.8571428571428571rem;
    overflow: hidden;
    font-size: 1.2em;
  }
  .navbar-offcanvas-overlay .offcanvas-nav li a:before {
    display: none;
  }
  .navbar-offcanvas-overlay .offcanvas-nav li.menu-item-has-children .dropdown-menu > li > a {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-offcanvas-overlay .offcanvas-nav li.menu-item-has-children .caret {
    display: inline-block;
    position: static;
    width: auto;
    padding: 0 0 0 15px;
  }
  .navbar-offcanvas-overlay .offcanvas-nav li.menu-item-has-children .caret:before {
    content: '\f0d7';
  }
  .navbar-offcanvas-overlay .offcanvas-nav > li > a {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 2em;
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-offcanvas-overlay .offcanvas-nav > li > a:before {
    background: #ffffff none repeat scroll 0 0;
    bottom: -20px;
    content: '';
    display: block;
    height: 2px;
    left: -10px;
    margin: 0;
    position: absolute;
    right: -10px;
    width: auto;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .navbar-offcanvas-overlay .offcanvas-nav > li > a:hover:before {
    bottom: 0;
  }
  .navbar-offcanvas-overlay .offcanvas-nav > li.active > a:before {
    bottom: 0;
  }
  .navbar-offcanvas-overlay .searchform {
    text-align: center;
    margin-top: 30px;
  }
  .navbar-offcanvas-overlay .searchform input {
    display: inline-block;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0.8571428571428571rem;
    text-align: center;
    font-size: 1.7142857142857142em;
    color: #fff;
  }
  .navbar-offcanvas-overlay .searchform::-webkit-input-placeholder {
    color: #777;
  }
  .navbar-offcanvas-overlay .searchform:-moz-placeholder {
    color: #777;
  }
  .navbar-offcanvas-overlay .searchform::-moz-placeholder {
    color: #777;
  }
  .navbar-offcanvas-overlay .searchform:-ms-input-placeholder {
    color: #777;
  }
  .navbar-offcanvas-overlay .overlay-bottom {
    padding: 30px 0 0;
    overflow: hidden;
    border-top: 1px solid #e1e1e1;
  }
  .navbar-offcanvas-overlay .overlay-bottom .copyright {
    float: left;
    width: 50%;
    color: #fff;
    line-height: 30px;
  }
  .navbar-offcanvas-overlay .overlay-bottom .social {
    float: right;
    width: 50%;
    text-align: right;
  }
  .navbar-offcanvas-overlay .overlay-bottom .social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    color: #444;
    margin-left: 7px;
  }
  .navbar-offcanvas-overlay .overlay-bottom .social a i {
    background: none;
    color: #444;
  }
  .navbar-offcanvas-overlay .overlay-bottom .social a:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  .navbar-offcanvas-overlay ~ #wrapper .offcanvas-overlay {
    background: rgba(0, 0, 0, 0.15);
  }
  .padding-wrap {
    margin: 40px;
    overflow: hidden;
    position: relative;
  }
  .padding-wrap .container {
    width: 100%;
    max-width: 1200px;
  }
  .padding-wrap .content-container {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .padding-wrap .footer-copyright {
    background: transparent;
  }
  .padding-wrap .navbar-default {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .padding-wrap .navbar-fixed-top {
    left: 40px;
    right: 40px;
  }
  .padding-wrap .heading-container {
    background: transparent;
  }
}

@media (max-width: 899px) {
  .navbar-offcanvas-overlay .searchform,
  .navbar-offcanvas-overlay .overlay-bottom {
    display: none;
  }
}

@media (min-width: 900px) {
  .navbar-header-left,
  .navbar-header-right {
    width: calc(25% - 4px);
    width: -webkit-calc(25% - 4px);
    width: -moz-calc(25% - 4px);
    display: inline-block;
    vertical-align: middle;
  }
}

@media (min-width: 900px) {
  .navbar-header-center {
    width: calc(50% - 4px);
    width: -webkit-calc(50% - 4px);
    width: -moz-calc(50% - 4px);
    display: inline-block;
    vertical-align: middle;
  }
}

@media (max-width: 899px) {
  .navbar-header-left {
    display: none;
  }
}

.navbar-header-left .social a {
  font-size: 15px;
  margin: 0 15px 0 0;
}

.header-transparent .navbar-header-left .social a {
  color: #fff;
}

.navbar-header-left .social a i {
  background: transparent;
}

.navbar-header-left .social a i:hover {
  color: #8ac691;
}

.navbar-header-center {
  text-align: center;
}

.navbar-search .searchinput-wrap {
  position: relative;
}

.navbar-search .searchinput-wrap .searchsubmit {
  border: 0;
  outline: 0;
  float: left;
  width: 10%;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background: #8ac691;
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  font-size: 1.2857142857142858em;
}

.navbar-search .searchinput-wrap .searchinput {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.5714285714285714rem 1rem;
  line-height: 1.6;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
  -webkit-transition: border-color 0.3s, box-shadow 0.3s;
  -o-transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s;
  border-width: 2px;
  border-color: #8ac691;
  border-right: 0;
  width: 55%;
  padding-right: 45px;
  float: left;
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.navbar-search .searchinput-wrap .searchinput:focus {
  border-color: #333;
  outline: none;
}

.navbar-search .searchinput-wrap .searchinput[disabled],
.navbar-search .searchinput-wrap .searchinput[readonly],
fieldset[disabled] .navbar-search .searchinput-wrap .searchinput {
  cursor: not-allowed;
  background-color: #aaa;
  opacity: 1;
  filter: alpha(opacity=100);
}

textarea.navbar-search .searchinput-wrap .searchinput {
  height: auto;
}

.navbar-search .searchinput-wrap .searchinput:focus {
  border-color: #8ac691;
}

@media (max-width: 1199px) {
  .navbar-search .searchinput-wrap .searchinput {
    width: 50%;
  }
}

.navbar-search .searchinput-wrap .search-product-category {
  float: left;
  width: 35%;
}

@media (max-width: 1199px) {
  .navbar-search .searchinput-wrap .search-product-category {
    width: 40%;
  }
}

.navbar-search .searchinput-wrap .search-product-category select {
  width: 100%;
  border-color: #8ac691;
  border-width: 2px 2px 2px 1px;
  background: #fff;
}

.navbar-search .searchinput-wrap .search-product-category i {
  z-index: 1;
}

.navbar-offcanvas svg,
.navbar-search-button svg,
.cart-icon-mobile svg,
.navbar-user svg,
.navbar-wishlist svg,
.navbar-search svg,
.navbar-minicart svg {
  height: 20px;
  vertical-align: baseline;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}

.navbar-offcanvas svg path,
.navbar-search-button svg path,
.cart-icon-mobile svg path,
.navbar-user svg path,
.navbar-wishlist svg path,
.navbar-search svg path,
.navbar-minicart svg path {
  stroke-miterlimit: 10;
  stroke-width: 2;
  fill: #000;
  stroke: #000;
}

.header-type-center-logo .navbar-search,
.header-type-center-logo .navbar-minicart,
.header-type-center-logo .navbar-offcanvas {
  display: none;
}

@media (min-width: 900px) {
  .header-type-center-logo .header-left {
    width: 40%;
    float: left;
  }
  .header-type-center-logo .header-left .navbar-collaps {
    float: right;
  }
  .header-type-center-logo .navbar-header {
    width: 20%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  .header-type-center-logo .navbar-header .navbar-brand {
    float: none;
    line-height: 90px;
  }
  .header-type-center-logo .header-right {
    width: 40%;
    float: left;
    text-align: right;
  }
  .header-type-center-logo .header-right .navbar-collapse {
    float: left;
  }
  .header-type-center-logo .header-right .navbar-search,
  .header-type-center-logo .header-right .navbar-minicart,
  .header-type-center-logo .header-right .navbar-offcanvas {
    float: left;
    display: block;
  }
  .header-type-center-logo .header-right .navbar-search-button,
  .header-type-center-logo .header-right .minicart-link,
  .header-type-center-logo .header-right .navbar-offcanvas-btn {
    line-height: 90px;
    display: block;
    padding: 0 15px;
  }
  .header-type-center-logo .primary-nav > li > a {
    line-height: 90px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 22px;
    padding-right: 22px;
  }
  .header-type-center-logo .navbar-fixed-top {
    -webkit-box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46);
    box-shadow: 0 1px 5px rgba(190, 190, 190, 0.46);
  }
  .header-type-center-logo .navbar-fixed-top .logo-fixed {
    max-height: 60px;
  }
  .header-type-center-logo .navbar-fixed-top .navbar-brand {
    line-height: 60px;
  }
  .header-type-center-logo .navbar-fixed-top .header-right .navbar-search-button,
  .header-type-center-logo .navbar-fixed-top .header-right .minicart-link,
  .header-type-center-logo .navbar-fixed-top .header-right .navbar-offcanvas-btn {
    line-height: 60px;
  }
}

.header-type-classic {
  position: relative;
}

@media (min-width: 900px) {
  .header-type-classic .navbar-header {
    position: absolute;
    left: 0;
  }
}

@media (min-width: 900px) {
  .header-type-classic-right.header-type-classic .navbar-header {
    width: auto;
    padding: 0;
    position: relative;
    left: auto;
  }
}

@media (max-width: 1200px) {
  .header-type-classic .navbar-header {
    width: auto;
  }
}

.header-type-classic-right.header-type-classic .navbar-default .primary-navbar-collapse {
  width: auto;
  float: right;
}

@media (max-width: 1200px) {
  .header-type-classic .navbar-default .primary-navbar-collapse {
    width: auto;
  }
}

.header-type-classic .navbar-default .primary-navbar-collapse .navbar-nav {
  float: none;
}

.header-type-classic .header-right {
  position: absolute;
  right: 0;
  top: 0;
}

.header-type-classic-right.header-type-classic .header-right {
  width: auto;
  position: relative;
  right: auto;
  top: auto;
  float: right;
}

@media (max-width: 1200px) {
  .header-type-classic .header-right {
    width: auto;
  }
}

@media (max-width: 899px) {
  .header-type-classic .header-right {
    display: none;
  }
}

.header-type-classic .header-right > div {
  display: inline-block;
}

@media (max-width: 1200px) {
  .header-type-classic .header-right > div {
    border: 0;
  }
}

.header-type-classic .header-right > div.navbar-offcanvas a,
.header-type-classic .header-right > div .minicart-link,
.header-type-classic .header-right > div a.wishlist,
.header-type-classic .header-right > div .navbar-search-button {
  text-align: center;
  color: #000;
  line-height: 90px;
  display: block;
  padding: 0 15px;
}

.header-type-classic .header-right > div.navbar-offcanvas a:hover,
.header-type-classic .header-right > div .minicart-link:hover,
.header-type-classic .header-right > div a.wishlist:hover,
.header-type-classic .header-right > div .navbar-search-button:hover {
  color: #8ac691;
}

.header-type-classic .header-right > div.navbar-offcanvas a {
  padding-right: 0;
}

.header-type-classic .header-right > div i {
  font-size: 1.2857142857142858em;
}

.header-type-classic .navbar-fixed-top .header-right > div.navbar-offcanvas a,
.header-type-classic .navbar-fixed-top .header-right > div .minicart-link,
.header-type-classic .navbar-fixed-top .header-right > div a.wishlist,
.header-type-classic .navbar-fixed-top .header-right > div .navbar-search-button {
  line-height: 60px;
}

.searchform-result .no-result {
  padding: 0.7142857142857143em 0.7142857142857143em;
}

.searchform-result .searchform-result-list {
  padding: 0.21428571428571427em 0.7142857142857143em 0;
}

.searchform-result .searchform-result-list .search-object {
  font-size: 110%;
  margin: 10px 0 0 0;
}

.searchform-result .searchform-result-list .search-object span {
  padding: 0 0.35714285714285715em;
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #f3f3f3;
}

.searchform-result .searchform-result-list .search-entry {
  border-bottom: 1px dotted #e1e1e1;
  padding: 0.7142857142857143em 0;
}

.searchform-result .searchform-result-list .search-entry .search-image {
  float: left;
  width: 1.7857142857142858em;
  text-align: center;
  margin: 0 0.7142857142857143em 0.35714285714285715em 0;
  font-size: 1.1428571428571428em;
}

.searchform-result .searchform-result-list .search-entry .search-image img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.searchform-result .searchform-result-list .search-entry .search-title {
  margin: 0;
  font-size: 13px;
}

.searchform-result .searchform-result-list .search-entry .search-excerpt {
  color: #777;
  font-size: 85%;
}

.searchform-result .search-view-all {
  padding: 1.4285714285714286em 0;
  text-align: center;
  background-color: #f3f3f3;
  margin: -0.07142857142857142em 0 0;
  border-top: 1px solid #e1e1e1;
}

.searchform-result .search-view-all a {
  font-size: 85%;
  text-transform: uppercase;
}

.searchform-result .search-view-all a:after {
  content: '\f178';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;

  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.searchform-result .search-view-all a:after {
  margin-left: 0.35714285714285715em;
}

.header-container.header-navbar-fixed {
  padding: 0 0 60px 0;
}

.header-search-overlay {
  left: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 99999;
}

.header-search-overlay > div {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.header-search-overlay .header-search-overlay-wrap {
  position: relative;
  height: 100%;
}

.header-search-overlay .searchform {
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 60px;
}

@media (max-width: 899px) {
  .header-search-overlay .searchform {
    padding-left: 25px;
  }
}

.header-search-overlay .searchform:before {
  content: '\f002';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.header-search-overlay .searchform:before {
  font-size: 20px;
  left: 0;
  line-height: 2.77143em;
  position: absolute;
  text-align: right;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 50px;
}

@media (max-width: 899px) {
  .header-search-overlay .searchform:before {
    width: auto;
    font-size: 16px;
  }
}

.header-search-overlay .searchform .searchinput {
  border: medium none;
  font-size: 2.5714285714285716em;
  width: 100%;
  outline: none;
  font-style: italic;
}

.navbar-fixed-top .header-search-overlay .searchform .searchinput {
  font-size: 1.7142857142857142em;
}

.header-type-toggle-offcanvas .header-search-overlay .searchform .searchinput {
  font-size: 1.7142857142857142em;
}

@media (max-width: 899px) {
  .header-search-overlay .searchform .searchinput {
    font-size: 1.2857142857142858em;
  }
}

.header-search-overlay .searchform.loading ~ .close {
  display: block;
}

.header-search-overlay .searchform.loading ~ .close > span {
  display: none;
}

.header-search-overlay .searchform.loading ~ .close:after {
  content: '\f110';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.header-search-overlay .searchform.loading ~ .close:after {
  -webkit-animation: fa-spin 1s infinite linear;
  -o-animation: fa-spin 1s infinite linear;
  animation: fa-spin 1s infinite linear;
  -webkit-animation-timing-function: steps(8, start);
  animation-timing-function: steps(8, start);
}

.header-search-overlay .close {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  height: 40px;
  position: absolute;
  right: 40px;
  width: 40px;
  opacity: 1;
  filter: alpha(opacity=100);
  font-size: 18px;
}

@media (max-width: 899px) {
  .header-search-overlay .close {
    right: 0;
    width: 20px;
  }
}

.header-search-overlay .searchform-result {
  background: #dadada;
  position: absolute;
  top: 100%;
  width: 100%;
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.51);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.51);
}

.header-search-overlay .searchform-result .no-result {
  font-size: 1.1em;
}

.header-search-overlay .searchform-result .searchform-result-list .search-entry {
  border-bottom-color: #fbfbfb;
}

.header-search-overlay .searchform-result .searchform-result-list .search-entry .search-title {
  font-size: 1.1em;
}

@media (min-width: 900px) {
  .header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
}

@media (min-width: 900px) {
  .header-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: transparent;
  }
  .header-type-classic-right.header-absolute .navbar-container {
    margin-top: 30px;
  }
  .header-type-classic-right.header-absolute .navbar-wrap {
    padding-left: 15px;
    padding-right: 15px;
    background: #fff;
  }
  .header-type-classic-right.header-absolute .topbar {
    background: transparent;
    color: #fff;
  }
  .header-type-classic-right.header-absolute .topbar ~ .navbar-container {
    margin-top: 0;
  }
  .header-type-classic-right.header-absolute .topbar a,
  .header-type-classic-right.header-absolute .topbar .top-nav > li > a,
  .header-type-classic-right.header-absolute .topbar .top-nav a {
    color: #fff;
  }
  .header-type-classic-right.header-absolute .topbar .dropdown-menu a {
    color: #777;
  }
}

@media (min-width: 900px) {
  .header-transparent .header-right > div {
    border: none;
  }
  .header-transparent .navbar-default:not(.navbar-fixed-top) {
    background: rgba(0, 0, 0, 0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header-transparent .navbar-default:not(.navbar-fixed-top).navbar-fixed-top {
    background: #fff;
    border-color: transparent;
  }
  .header-transparent .navbar-default:not(.navbar-fixed-top) .dropdown-menu a {
    background: transparent;
  }
  .header-transparent
    .navbar-default:not(.navbar-fixed-top)
    .megamenu
    > .dropdown-menu
    .dropdown-menu {
    background: transparent;
  }
}

@media (min-width: 900px) {
  .header-type-classic .header-container.page-heading-default .navbar-container {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
  }
}

.heading-container {
  background: #f3f3f3;
  overflow: hidden;
  position: relative;
}

.heading-container > div {
  height: 100%;
}

.heading-container.heading-resize {
  width: 100%;
}

.heading-container .page-breadcrumb {
  font-size: 85%;
  text-transform: uppercase;
  text-align: center;
}

.heading-container .heading-standar {
  position: relative;
}

.heading-container .heading-standar .page-breadcrumb {
  float: none;
  padding: 20px 0;
}

.heading-container .heading-parallax {
  position: relative;
}

.heading-container .heading-background {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

@media (max-width: 1199px) {
  .heading-container .heading-background {
    padding-top: 0;
  }
}

.heading-container .heading-background .heading-wrap {
  position: relative;
  text-align: center;
  padding: 30px 0;
}

@media (min-width: 900px) {
  .heading-container .heading-background .heading-wrap {
    height: 200px;
  }
  .header-transparent ~ .heading-container .heading-background .heading-wrap {
    height: 300px;
  }
}

.heading-container .heading-background .heading-wrap:after {
  content: '';
  display: inline-block;
  height: 100%;
  margin-right: -0.3em;
  vertical-align: middle;
  width: 1px;
}

.heading-container .heading-background .page-title {
  display: inline-block;
  vertical-align: middle;
}

.heading-container .heading-background .page-title h1 {
  color: #fff;
  display: block;
  margin: 0;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .heading-container .heading-background .page-title h1 {
    font-size: 1.5em;
  }
}

.heading-container .heading-background .page-title .subtitle {
  color: #fff;
  font-size: 1.2em;
  text-transform: uppercase;
  margin-top: 15px;
  display: block;
}

@media (max-width: 767px) {
  .heading-container .heading-background .page-title .subtitle {
    font-size: 1em;
    text-align: center;
  }
}

.heading-container .heading-background .page-title .page-breadcrumb {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .heading-container .heading-background .page-title .page-breadcrumb {
    display: none;
  }
}

.heading-container .heading-background .page-title .page-breadcrumb .breadcrumb > li {
  color: #fff;
}

.heading-container .heading-background .page-title .page-breadcrumb .breadcrumb > li + li::before {
  color: #fff;
}

.heading-container .heading-background .page-title .page-breadcrumb a {
  color: #fff;
}

@media (max-width: 899px) {
  .heading-container .heading-background .heading-wrap {
    display: block;
    height: auto;
    margin: 0 auto;
  }
  .heading-container .heading-background .page-title {
    display: inline-block;
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

@media (min-width: 768px) {
  .heading-container.heading-button .heading-background .heading-wrap {
    text-align: left;
  }
}

.heading-container.heading-button .heading-background .heading-wrap .heading-button-btn {
  margin-top: 30px;
  padding-right: 3rem;
  padding-left: 3rem;
}

.navbar-default .navbar-toggle {
  border: none;
}
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background: none;
}
.header-type-toggle-offcanvas .navbar-default-col,
.header-type-toggle-offcanvas .navbar-default-wrap {
  height: 70px;
}
.heading-container .heading-background {
  transform: translate3d(0px, 0px, 0px);
}
.heading-container .heading-background.bg-2 {
  padding-top: 90px;
  transform: translate3d(0px, 0px, 0px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading-container .heading-background.bg-3 {
  padding-top: 110px;
  transform: translate3d(0px, 0px, 0px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading-container .heading-background.bg-blog-minimal {
  transform: translate3d(0px, 0px, 0px);
}
.heading-container .heading-background.bg-construction {
  transform: translate3d(0px, 0px, 0px);
}

/* === 3.5. BUTTONS === */

.close {
  float: right;
  font-size: 19.5px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

/* === 3.17. BLOG POST === */

.posts.infinite-scroll .paginate,
.posts.loadmore .paginate {
  display: none;
}

.posts .entry-title {
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .posts .entry-title {
    font-size: 1.7142857142857142em;
  }
}

@media (max-width: 767px) {
  .posts .entry-title {
    font-size: 1.4285714285714286em;
  }
}

.posts .posts-wrap.posts-layout-zigzag .hentry {
  margin: 0;
}

.posts .posts-wrap.posts-layout-zigzag .hentry .hentry-wrap {
  position: relative;
}

.posts .posts-wrap.posts-layout-zigzag .hentry .entry-featured {
  width: 50%;
  float: left;
  margin: 0;
}

@media (max-width: 767px) {
  .posts .posts-wrap.posts-layout-zigzag .hentry .entry-featured {
    width: 100%;
    float: none;
  }
}

.posts .posts-wrap.posts-layout-zigzag .hentry .entry-info {
  width: 50%;
  float: left;
  padding-left: 30px;
}

@media (min-width: 992px) {
  .posts .posts-wrap.posts-layout-zigzag .hentry .entry-info {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    right: 0;
  }
}

@media (max-width: 991px) {
  .posts .posts-wrap.posts-layout-zigzag .hentry .entry-info {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .posts .posts-wrap.posts-layout-zigzag .hentry .entry-info {
    width: 100%;
    float: none;
    padding-left: 0;
    padding-bottom: 30px;
  }
}

.posts .posts-wrap.posts-layout-zigzag .hentry:nth-child(even) .entry-featured {
  float: right;
}

@media (max-width: 767px) {
  .posts .posts-wrap.posts-layout-zigzag .hentry:nth-child(even) .entry-featured {
    float: none;
  }
}

.posts .posts-wrap.posts-layout-zigzag .hentry:nth-child(even) .entry-info {
  left: 0;
  padding-left: 0;
  padding-right: 30px;
}

.posts .posts-wrap.posts-layout-zigzag .hentry .entry-meta {
  margin-bottom: 1.5em;
}

.posts .posts-wrap.posts-layout-masonry .entry-format,
.posts .posts-wrap.posts-layout-grid .entry-format {
  display: none;
}

.posts .posts-wrap.posts-layout-masonry .entry-title,
.posts .posts-wrap.posts-layout-grid .entry-title {
  font-size: 1.2em;
}

.posts .posts-wrap.posts-layout-masonry .hentry,
.posts .posts-wrap.posts-layout-grid .hentry {
  border-bottom: none;
  padding-bottom: 0;
}

.posts .posts-wrap.posts-layout-masonry.no-gap.row,
.posts .posts-wrap.posts-layout-grid.no-gap.row {
  margin-left: 0;
  margin-right: 0;
}

.posts .posts-wrap.posts-layout-masonry.no-gap.row .hentry,
.posts .posts-wrap.posts-layout-grid.no-gap.row .hentry {
  padding-left: 0;
  padding-right: 0;
}

.posts .posts-wrap.posts-layout-masonry .entry-featured,
.posts .posts-wrap.posts-layout-grid .entry-featured {
  margin: 0;
}

.posts .posts-wrap.posts-layout-masonry .entry-info,
.posts .posts-wrap.posts-layout-grid .entry-info {
  padding: 30px;
  border-style: solid;
  border-color: #e1e1e1;
  border-width: 1px 1px 1px 1px;
}

.posts .posts-wrap.posts-layout-masonry .has-post-thumbnail .entry-info,
.posts .posts-wrap.posts-layout-grid .has-post-thumbnail .entry-info {
  border-top: none;
}

.posts .posts-wrap.posts-layout-masonry .entry-content,
.posts .posts-wrap.posts-layout-grid .entry-content {
  margin-bottom: 1em;
}

.posts .posts-wrap.posts-layout-masonry .entry-meta,
.posts .posts-wrap.posts-layout-grid .entry-meta {
  float: left;
  margin: 15px 0 0;
}

@media (max-width: 320px) {
  .posts .posts-wrap.posts-layout-masonry .entry-meta,
  .posts .posts-wrap.posts-layout-grid .entry-meta {
    float: none;
  }
}

.posts .posts-wrap.posts-layout-masonry .readmore-link,
.posts .posts-wrap.posts-layout-grid .readmore-link {
  float: right;
  font-weight: 600;
  margin: 15px 0 0;
}

@media (max-width: 320px) {
  .posts .posts-wrap.posts-layout-masonry .readmore-link,
  .posts .posts-wrap.posts-layout-grid .readmore-link {
    float: none;
  }
}

.posts .posts-wrap.posts-layout-grid {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}

.posts .posts-wrap.posts-layout-grid .no-border .entry-info {
  padding-left: 0;
  padding-right: 0;
  border: none;
}

.posts .posts-wrap.posts-layout-grid .grid-full.no-border .entry-info {
  border-bottom: 1px solid #e1e1e1;
}

.posts .posts-wrap.posts-layout-grid.no-padding .hentry {
  padding: 0;
}

.posts .posts-wrap.posts-layout-grid.no-padding .hentry .entry-info {
  border: 0;
  background: #f4f4f4;
}

.posts .posts-wrap.posts-layout-grid.no-padding .hentry:hover .entry-info {
  background: #e9e9e9;
}

.posts .posts-wrap.posts-layout-grid.col-2 .hentry:nth-child(2n + 1) {
  clear: both;
}

.posts .posts-wrap.posts-layout-grid.col-3 .hentry:nth-child(3n + 1) {
  clear: both;
}

@media (max-width: 991px) {
  .posts .posts-wrap.posts-layout-grid.col-3 .hentry:nth-child(3n + 1) {
    clear: none;
  }
}

@media (max-width: 991px) {
  .posts .posts-wrap.posts-layout-grid.col-3 .hentry:nth-child(2n + 1) {
    clear: both;
  }
}

.posts .posts-wrap.posts-layout-grid.col-4 .hentry:nth-child(4n + 1) {
  clear: both;
}

@media (max-width: 991px) {
  .posts .posts-wrap.posts-layout-grid.col-4 .hentry:nth-child(4n + 1) {
    clear: none;
  }
}

@media (max-width: 991px) {
  .posts .posts-wrap.posts-layout-grid.col-4 .hentry:nth-child(2n + 1) {
    clear: both;
  }
}

.posts .posts-wrap.posts-layout-center .hentry-wrap {
  text-align: center;
}

.posts .posts-wrap.posts-layout-center .entry-format {
  display: none;
}

.hentry {
  margin-bottom: 60px;
  background: transparent;
}

.entry-header {
  margin-bottom: 15px;
  position: relative;
}

.entry-header .entry-meta {
  margin-top: 8px;
}

.entry-content {
  margin-bottom: 2em;
}

.entry-featured {
  margin-bottom: 30px;
}

.entry-featured img {
  width: 100%;
  height: auto;
}

.entry-featured.video-featured:not(.col-md-8) {
  width: 100%;
  max-width: 100%;
}

.entry-featured.video-featured a {
  position: relative;
  display: block;
}

.entry-featured.video-featured a:before {
  background: rgba(0, 0, 0, 0.14) none repeat scroll 0 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.entry-featured.video-featured a i {
  color: #ffffff;
  display: block;
  font-size: 50px;
  line-height: 1;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}

.entry-format {
  width: 65px;
  height: 65px;
  display: inline-block;
  text-align: center;
  line-height: 65px;
  font-size: 30px;
  color: #8ac691;
  float: left;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .entry-format {
    display: none;
  }
}

.entry-title {
  margin-top: 0;
  font-weight: 600;
  margin: 0;
}

.sticky .entry-title a {
  color: #8ac691;
}

.sticky .entry-title:before {
  content: '\f08d';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.sticky .entry-title:before {
  line-height: 1;
  opacity: 1;
  -webkit-transform: rotate(-36deg);
  -ms-transform: rotate(-36deg);
  -o-transform: rotate(-36deg);
  transform: rotate(-36deg);
  color: #8ac691;
  font-size: 1.2857142857142858em;
}

.entry-meta {
  margin-bottom: 1.5em;
  text-transform: uppercase;
  color: #777;
  font-size: 85%;
}

.entry-meta.icon-meta i {
  margin-right: 10px;
}

.entry-meta.icon-meta > span {
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
}

.entry-meta a {
  color: #777;
}

.entry-meta a:hover {
  color: #8ac691;
}

.readmore-link a {
  display: inline-block;
  vertical-align: top;
  font-size: 85%;
  text-transform: uppercase;
}

.readmore-link a:after {
  content: '\f0da';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.readmore-link a:after {
  margin-left: 5px;
}

.readmore-link a:hover {
  color: #8ac691;
}

.posts-layout-default .readmore-link a,
.posts-layout-zigzag .readmore-link a,
.posts-layout-center .readmore-link a {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 0.5714285714285714rem 2rem;
  font-size: 13px;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  text-decoration: none;
  background: transparent;
  border: 2px solid #333;
  text-transform: uppercase;
  color: #333;
  position: relative;
  font-weight: 600;
}

.posts-layout-default .readmore-link a i,
.posts-layout-zigzag .readmore-link a i,
.posts-layout-center .readmore-link a i,
.posts-layout-default .readmore-link a span,
.posts-layout-zigzag .readmore-link a span,
.posts-layout-center .readmore-link a span {
  z-index: 2;
  position: relative;
}

.posts-layout-default .readmore-link a.btn-icon-left i,
.posts-layout-zigzag .readmore-link a.btn-icon-left i,
.posts-layout-center .readmore-link a.btn-icon-left i {
  margin-right: 5px;
}

.posts-layout-default .readmore-link a.btn-icon-right i,
.posts-layout-zigzag .readmore-link a.btn-icon-right i,
.posts-layout-center .readmore-link a.btn-icon-right i {
  margin-left: 5px;
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in {
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in i,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in i,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in i {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in i,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in i,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in i {
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0;
  filter: alpha(opacity=0);
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in span,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in span {
  position: relative;
  left: 0;
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in:hover span,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in:hover span,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in:hover span {
  left: -15px;
}

.posts-layout-default .readmore-link a.btn-icon-right.btn-icon-slide-in:hover i,
.posts-layout-zigzag .readmore-link a.btn-icon-right.btn-icon-slide-in:hover i,
.posts-layout-center .readmore-link a.btn-icon-right.btn-icon-slide-in:hover i {
  opacity: 1;
  filter: alpha(opacity=100);
  right: 25px;
}

.posts-layout-default .readmore-link a.btn-round,
.posts-layout-zigzag .readmore-link a.btn-round,
.posts-layout-center .readmore-link a.btn-round {
  -webkit-border-radius: 40px;
  border-radius: 40px;
}

.posts-layout-default .readmore-link a:hover,
.posts-layout-zigzag .readmore-link a:hover,
.posts-layout-center .readmore-link a:hover,
.posts-layout-default .readmore-link a:focus,
.posts-layout-zigzag .readmore-link a:focus,
.posts-layout-center .readmore-link a:focus {
  color: #fff;
  border-color: #8ac691;
  background: #8ac691;
}

.posts-layout-default .readmore-link a:before,
.posts-layout-zigzag .readmore-link a:before,
.posts-layout-center .readmore-link a:before {
  display: none;
}

.post-navigation {
  padding: 30px 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.post-navigation .prev-post > span,
.post-navigation .next-post > span {
  font-size: 85%;
  font-weight: 600;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #777;
}

.post-navigation .prev-post > span:before,
.post-navigation .next-post > span:before,
.post-navigation .prev-post > span:after,
.post-navigation .next-post > span:after {
  font-size: 110%;
}

@media (max-width: 767px) {
  .post-navigation .prev-post,
  .post-navigation .next-post {
    text-align: center;
  }
  .post-navigation .prev-post:before,
  .post-navigation .next-post:before,
  .post-navigation .prev-post:after,
  .post-navigation .next-post:after {
    display: none;
  }
}

.post-navigation .prev-post > span:before {
  content: '\f104';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.post-navigation .prev-post > span:before {
  margin-right: 5px;
}

@media (max-width: 767px) {
  .post-navigation .prev-post {
    margin-bottom: 2em;
  }
}

.post-navigation .next-post {
  text-align: right;
}

.post-navigation .next-post > span:after {
  content: '\f105';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.post-navigation .next-post > span:after {
  margin-left: 5px;
}

.post-navigation a {
  display: block;
  font-weight: 600;
  color: #333;
}

.post-navigation a:hover {
  color: #8ac691;
}

.author-info {
  padding: 30px 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.author-info .author-avatar {
  float: left;
  margin-right: 30px;
}

.author-info .author-avatar img {
  max-width: 8.571428571428571em;
}

@media (max-width: 767px) {
  .author-info .author-avatar {
    float: none;
    text-align: center;
    margin-bottom: 10px;
  }
}

.author-info .author-description {
  margin-left: 8.571428571428571em;
}

@media (max-width: 767px) {
  .author-info .author-description {
    margin-left: 0;
  }
}

.author-info .author-description .author-title {
  font-size: 1.1em;
  margin-top: 0;
}

@media (max-width: 767px) {
  .author-info .author-description .author-title {
    text-align: center;
  }
}

.author-info .author-description .author-title small {
  font-size: 85%;
}

@media (max-width: 767px) {
  .author-info .author-description .author-title small {
    display: none;
  }
}

.author-info .author-description .author-link {
  display: block;
  font-weight: 600;
  margin-top: 2em;
}

.author-info .author-social {
  text-align: left;
}

.author-info .author-social a {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 0.821429em;
  text-align: center;
  background: none repeat scroll 0 0 #707070;
  font-size: 13px;
  height: 2em;
  line-height: 2em;
  width: 2em;
}

.author-info .author-social a:hover {
  background: #8ac691;
}

.entry-tags {
  margin-top: 15px;
  text-transform: uppercase;
  display: inline-block;
}

.entry-tags > span {
  color: #777;
}

.entry-tags a {
  position: relative;
  display: inline-block;
  color: #333;
}

.entry-tags a:hover {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .single.single-post.page-layout-full-width .entry-info,
  .single.single-post.page-layout-full-width .post-navigation,
  .single.single-post.page-layout-full-width .related-post,
  .single.single-post.page-layout-full-width .author-info,
  .single.single-post.page-layout-full-width .comments-area {
    width: 66.66666666666666%;
    margin-left: auto;
    margin-right: auto;
  }
  .single.single-post.page-layout-full-width .entry-header {
    text-align: center;
  }
}

.single .hentry {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .single .hentry {
    margin-left: auto;
    margin-right: auto;
  }
}

.single .entry-title {
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .single .entry-title {
    font-size: 1.2em;
  }
}

@media (max-width: 767px) {
  .single .entry-title {
    font-size: 1.5em;
  }
}

.single .entry-featured {
  margin-bottom: 2.5em;
}

.single .entry-info .entry-meta {
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.main-content .single .entry-footer {
  margin-top: 45px;
}

@media (max-width: 767px) {
  .main-content .single .entry-footer {
    text-align: center;
  }
}

.main-content .single .entry-tags {
  margin: 0;
}

.main-content .single .share-links {
  text-align: right;
  margin: 0;
}

.main-content .single .share-links > span {
  color: #777;
}

@media (max-width: 767px) {
  .main-content .single .share-links {
    text-align: center;
    margin: 10px 0 0;
  }
}

.main-content .single .share-links .share-icons a {
  margin: 0 10px;
}

.single .comments-area {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #e1e1e1;
}

.single .title-sep-text {
  font-size: 1.7142857142857142em;
  padding: 0.6em 0;
  margin: 0 0 0 0;
  position: relative;
}

@media (max-width: 480px) {
  .single .title-sep-text {
    font-size: 1.2857142857142858em;
  }
}

.single .below-content {
  margin-top: 1em;
}

.share-links {
  margin-top: 30px;
}

.share-links > span {
  display: inline-block;
  padding-right: 10px;
  text-transform: uppercase;
}

.share-links .share-icons {
  display: inline-block;
}

.share-links .share-icons a {
  font-size: 14px;
  text-align: center;
  display: inline-block;
  color: #333;
}

.share-links .share-icons a:hover,
.share-links .share-icons a:focus {
  color: #8ac691;
}

@media (max-width: 767px) {
  .share-links {
    display: block;
  }
  .share-links h4 {
    width: auto;
  }
}

.related-post {
  margin-top: 30px;
}

.related-post .related-post-title h3 {
  font-size: 1.7142857142857142em;
  padding: 0.6em 0;
  margin: 0 0 0 0;
  position: relative;
}

@media (max-width: 480px) {
  .related-post .related-post-title h3 {
    font-size: 1.2857142857142858em;
  }
}

.related-post .entry-featured {
  margin-bottom: 15px;
}

.related-post .related-post-items {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .related-post .related-post-item {
    margin-bottom: 30px;
  }
}

.related-post .post-title {
  font-size: 1.2em;
  margin-top: 0;
  text-transform: uppercase;
}

.related-post .entry-meta .meta-date {
  display: inline-block;
}

.related-post .excerpt {
  margin-top: 1em;
}

.related-post .readmore-link {
  font-weight: 600;
}

.post-slider-wrap .hentry .entry-featured {
  margin: 0;
}

.post-slider-wrap .hentry .entry-info {
  padding: 20px 25px;
  border-style: solid;
  border-color: #e1e1e1;
  border-width: 0 1px 1px 1px;
}

.post-slider-wrap .hentry .entry-info .entry-title {
  font-size: 1.4285714285714286em;
}

.post-slider-wrap .hentry .entry-info .entry-meta {
  float: left;
}

@media (max-width: 320px) {
  .post-slider-wrap .hentry .entry-info .entry-meta {
    float: none;
    margin-bottom: 15px;
  }
}

.post-slider-wrap .hentry .entry-info .readmore-link {
  float: right;
}

@media (max-width: 320px) {
  .post-slider-wrap .hentry .entry-info .readmore-link {
    float: none;
  }
}

ul.isotope-grid-post {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* === 3.18. SIDEBAR === */

.widget {
  margin-bottom: 3rem;
}

.widget p:last-child,
.widget ul:last-child,
.widget ol:last-child {
  margin-bottom: 0;
}

.widget ul,
.widget ol {
  margin-left: 0;
  padding: 0;
  list-style: none;
}

.widget select {
  width: 100%;
}

.main-sidebar > .widget {
  margin-bottom: 40px;
}

.main-sidebar .widget_rss li,
.main-sidebar .widget_pages li,
.main-sidebar .widget_archive li,
.main-sidebar .widget_meta li,
.main-sidebar .widget_nav_menu li,
.main-sidebar .widget_categories li,
.main-sidebar .widget_recent_entries li,
.main-sidebar .widget_recent_comments li {
  padding-bottom: 10px;
  position: relative;
  padding-left: 1em;
}

.main-sidebar .widget_rss li:before,
.main-sidebar .widget_pages li:before,
.main-sidebar .widget_archive li:before,
.main-sidebar .widget_meta li:before,
.main-sidebar .widget_nav_menu li:before,
.main-sidebar .widget_categories li:before,
.main-sidebar .widget_recent_entries li:before,
.main-sidebar .widget_recent_comments li:before {
  content: '\f105';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.main-sidebar .widget_rss li:before,
.main-sidebar .widget_pages li:before,
.main-sidebar .widget_archive li:before,
.main-sidebar .widget_meta li:before,
.main-sidebar .widget_nav_menu li:before,
.main-sidebar .widget_categories li:before,
.main-sidebar .widget_recent_entries li:before,
.main-sidebar .widget_recent_comments li:before {
  position: absolute;
  line-height: inherit;
  left: 0;
}

.main-sidebar .widget_rss li:last-child,
.main-sidebar .widget_pages li:last-child,
.main-sidebar .widget_archive li:last-child,
.main-sidebar .widget_meta li:last-child,
.main-sidebar .widget_nav_menu li:last-child,
.main-sidebar .widget_categories li:last-child,
.main-sidebar .widget_recent_entries li:last-child,
.main-sidebar .widget_recent_comments li:last-child {
  border: none;
}

.main-sidebar .widget_rss li .post-date,
.main-sidebar .widget_pages li .post-date,
.main-sidebar .widget_archive li .post-date,
.main-sidebar .widget_meta li .post-date,
.main-sidebar .widget_nav_menu li .post-date,
.main-sidebar .widget_categories li .post-date,
.main-sidebar .widget_recent_entries li .post-date,
.main-sidebar .widget_recent_comments li .post-date {
  display: block;
  font-size: 85%;
  color: #777;
}

.main-sidebar .widget_rss li ul,
.main-sidebar .widget_pages li ul,
.main-sidebar .widget_archive li ul,
.main-sidebar .widget_meta li ul,
.main-sidebar .widget_nav_menu li ul,
.main-sidebar .widget_categories li ul,
.main-sidebar .widget_recent_entries li ul,
.main-sidebar .widget_recent_comments li ul {
  padding-top: 15px;
}

.main-sidebar .widget_rss li ul li:last-child,
.main-sidebar .widget_pages li ul li:last-child,
.main-sidebar .widget_archive li ul li:last-child,
.main-sidebar .widget_meta li ul li:last-child,
.main-sidebar .widget_nav_menu li ul li:last-child,
.main-sidebar .widget_categories li ul li:last-child,
.main-sidebar .widget_recent_entries li ul li:last-child,
.main-sidebar .widget_recent_comments li ul li:last-child {
  padding-bottom: 0;
}

.main-sidebar .widget_archive li {
  padding-left: 1.5em;
}

.main-sidebar .widget_archive li:before {
  content: '\f133';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.main-sidebar .widget_categories li {
  padding-left: 1.5em;
}

.main-sidebar .widget_categories li:before {
  content: '\f114';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.main-sidebar .widget_meta li {
  padding-left: 1.5em;
}

.main-sidebar .widget_meta li:before {
  content: '\f013';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.main-sidebar .widget_recent_comments li {
  padding-left: 1.5em;
}

.main-sidebar .widget_recent_comments li:before {
  content: '\f0e5';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.widgettitle,
.widget-title {
  font-size: 1.2em;
  margin-bottom: 1.5em;
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .main-sidebar {
    padding: 20px;
    border: 1px solid #e1e1e1;
  }
  .main-sidebar.no-border {
    border: 0;
    padding: 0;
  }
}

.widget_search form {
  position: relative;
}

.widget_search form:before {
  content: '\f002';
  position: relative;
  display: inline-block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.widget_search form:before {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  line-height: 40px;
  z-index: 1;
  -webkit-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
}

.widget_search form #searchsubmit {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: block !important;
  visibility: visible;
  border: 0;
  background: transparent;
  outline: 0;
  text-indent: -9999em;
}

.widget_tag_cloud .tagcloud a,
.widget_product_tag_cloud .tagcloud a {
  float: left;
  margin: 0 0.7142857142857143em 0.7142857142857143em 0;
  padding: 1em 1.5em;
  font-size: 13px;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
  text-align: center;
  vertical-align: middle;
  font-size: 85%;
  border: 1px solid #e1e1e1;
  color: #444;
}

.widget_tag_cloud .tagcloud a:hover,
.widget_product_tag_cloud .tagcloud a:hover {
  border-color: #333;
  color: #333;
}

.posts-thumbnail-list > li {
  padding: 0 0 20px 0;
}

.posts-thumbnail-list > li:last-child {
  padding-bottom: 0;
}

.widget-post-thumbnail li .posts-thumbnail-image {
  float: left;
  width: 70px;
  margin-right: 1em;
}

.widget-post-thumbnail li .posts-thumbnail-content h4 {
  font-size: 1.1em;
  margin-top: -3px;
  margin-bottom: 0.5em;
}

.widget-post-thumbnail li .posts-thumbnail-content .posts-thumbnail-meta {
  color: #777;
  font-size: 85%;
}

.widget-post-thumbnail li .posts-thumbnail-content .posts-thumbnail-meta a {
  color: #777;
}

.widget-post-thumbnail li .posts-thumbnail-content .posts-thumbnail-meta a:hover {
  color: #8ac691;
}

/* === 3.19. CONTACT FORM === */

.form-control:not(.submit) {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.5714285714285714rem 1rem;
  line-height: 1.6;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
  -webkit-transition: border-color 0.3s, box-shadow 0.3s;
  -o-transition: border-color 0.3s, box-shadow 0.3s;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-control:not(.submit):focus {
  border-color: #333;
  outline: none;
}

.form-control:not(.submit)[disabled],
.form-control:not(.submit)[readonly],
fieldset[disabled] .form-control:not(.submit) {
  cursor: not-allowed;
  background-color: #aaa;
  opacity: 1;
  filter: alpha(opacity=100);
}

textarea.form-control:not(.submit) {
  height: auto;
}

textarea.form-control:not(.submit) {
  height: 130px;
}

.form-control-wrap {
  display: block;
  width: 100%;
  margin-top: 0.35714285714285715em;
  margin-bottom: 1em;
}

.submit {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.5714285714285714rem 2rem;
  font-size: 13px;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  text-decoration: none;
  color: #fff;
  background-color: #333;
  border-color: #333;
  padding: 0.8571428571428571rem 1.5em;
}

.submit i,
.submit span {
  z-index: 2;
  position: relative;
}

.submit.btn-icon-left i {
  margin-right: 5px;
}

.submit.btn-icon-right i {
  margin-left: 5px;
}

.submit.btn-icon-right.btn-icon-slide-in {
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
}

.submit.btn-icon-right.btn-icon-slide-in span,
.submit.btn-icon-right.btn-icon-slide-in i {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.submit.btn-icon-right.btn-icon-slide-in i {
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0;
  filter: alpha(opacity=0);
}

.submit.btn-icon-right.btn-icon-slide-in span {
  position: relative;
  left: 0;
}

.submit.btn-icon-right.btn-icon-slide-in:hover span {
  left: -15px;
}

.submit.btn-icon-right.btn-icon-slide-in:hover i {
  opacity: 1;
  filter: alpha(opacity=100);
  right: 25px;
}

.submit.btn-round {
  -webkit-border-radius: 40px;
  border-radius: 40px;
}

.submit:hover,
.submit:focus,
.submit:active,
.submit.active {
  color: #fff;
}

.submit:active,
.submit.active {
  background-image: none;
}

.submit:hover,
.submit:focus,
.submit:active,
.submit.active {
  background-color: #666;
  border-color: #666;
}

/* --------------------------------------------------------------------- */
/* 4. COMMERCE
/* --------------------------------------------------------------------- */

/* === 4.1. GENERAL === */
@font-face {
  font-family: 'star';
  src: url('../../fonts/star.eot');
  src: url('../../fonts/star.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/star.woff') format('woff'), url('../../fonts/star.ttf') format('truetype'),
    url('../../fonts/star.svg#star') format('svg');
  font-weight: normal;
  font-style: normal;
}
.commerce .info-excerpt {
  display: none;
}
.commerce span.onsale {
  color: #fff;
  display: inline-block;
  position: absolute;
  left: 10px;
  text-align: center;
  text-transform: uppercase;
  top: 10px;
  z-index: 100;
  padding: 0 10px;
  line-height: 25px;
  font-size: 0.8571428571428571em;
  background: #8ac691;
}
.commerce ul.products:not(.masonry-products) {
  margin-left: -15px;
  margin-right: -15px;
}

.commerce ul.products:not(.masonry-products) li.product {
  float: left;
  margin: 0 0 30px;
  padding: 0 15px;
  position: relative;
  width: 25%;
  margin-left: 0;
  height: auto;
  display: inline-block;
  clear: none;
}

@media (min-width: 992px) {
  .commerce ul.products:not(.masonry-products) li.product.first {
    clear: both;
  }
  .commerce ul.products:not(.masonry-products) li.product.last {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .columns-6 > ul.products:not(.masonry-products) li.product,
  ul.products:not(.masonry-products).columns-6 li.product {
    width: 16.666666666666664%;
  }
  .columns-6 > ul.products:not(.masonry-products) li.product.first,
  ul.products:not(.masonry-products).columns-6 li.product.first {
    clear: none;
  }
  .columns-6 > ul.products:not(.masonry-products) li.product:nth-of-type(6n + 1),
  ul.products:not(.masonry-products).columns-6 li.product:nth-of-type(6n + 1) {
    clear: both;
  }
  .columns-5 > ul.products:not(.masonry-products) li.product,
  ul.products:not(.masonry-products).columns-5 li.product {
    width: 20%;
  }
  .columns-5 > ul.products:not(.masonry-products) li.product.first,
  ul.products:not(.masonry-products).columns-5 li.product.first {
    clear: none;
  }
  .columns-5 > ul.products:not(.masonry-products) li.product:nth-of-type(5n + 1),
  ul.products:not(.masonry-products).columns-5 li.product:nth-of-type(5n + 1) {
    clear: both;
  }
  .columns-4 > ul.products:not(.masonry-products) li.product.first,
  ul.products:not(.masonry-products).columns-4 li.product.first {
    clear: none;
  }
  .columns-4 > ul.products:not(.masonry-products) li.product:nth-of-type(4n + 1),
  ul.products:not(.masonry-products).columns-4 li.product:nth-of-type(4n + 1) {
    clear: both;
  }
  .columns-3 > ul.products:not(.masonry-products) li.product,
  ul.products:not(.masonry-products).columns-3 li.product {
    width: 33.33333333333333%;
  }
  .columns-3 > ul.products:not(.masonry-products) li.product.first,
  ul.products:not(.masonry-products).columns-3 li.product.first {
    clear: none;
  }
  .columns-3 > ul.products:not(.masonry-products) li.product:nth-of-type(3n + 1),
  ul.products:not(.masonry-products).columns-3 li.product:nth-of-type(3n + 1) {
    clear: both;
  }
  .columns-2 > ul.products:not(.masonry-products) li.product,
  ul.products:not(.masonry-products).columns-2 li.product {
    width: 50%;
  }
  .columns-2 > ul.products:not(.masonry-products) li.product.first,
  ul.products:not(.masonry-products).columns-2 li.product.first {
    clear: none;
  }
  .columns-2 > ul.products:not(.masonry-products) li.product:nth-of-type(2n + 1),
  ul.products:not(.masonry-products).columns-2 li.product:nth-of-type(2n + 1) {
    clear: both;
  }
}

.columns-1 > ul.products:not(.masonry-products) li.product,
ul.products:not(.masonry-products).columns-1 li.product {
  width: 100%;
  clear: none;
}

@media (max-width: 991px) and (min-width: 480px) {
  .commerce ul.products:not(.masonry-products) > li.product:not(.product-category) {
    width: 33.33333333333333%;
    clear: none;
    margin-left: 0;
    height: auto;
    display: inline-block;
    position: relative;
  }
  .commerce
    ul.products:not(.masonry-products)
    > li.product:not(.product-category):nth-of-type(3n + 1) {
    clear: both;
  }
  .commerce ul.products:not(.masonry-products) > .product-category.last {
    margin-right: 0;
  }
  .commerce-lookbok.commerce ul.products:not(.masonry-products) > li.product,
  .columns-4 > .commerce ul.products:not(.masonry-products) > li.product,
  .commerce ul.products:not(.masonry-products).columns-4 > li.product,
  .columns-2 > .commerce ul.products:not(.masonry-products) > li.product,
  .commerce ul.products:not(.masonry-products).columns-2 > li.product {
    width: 50%;
  }
  .commerce-lookbok.commerce ul.products:not(.masonry-products) > li.product.first,
  .columns-4 > .commerce ul.products:not(.masonry-products) > li.product.first,
  .commerce ul.products:not(.masonry-products).columns-4 > li.product.first,
  .columns-2 > .commerce ul.products:not(.masonry-products) > li.product.first,
  .commerce ul.products:not(.masonry-products).columns-2 > li.product.first {
    clear: none;
  }
  .commerce-lookbok.commerce ul.products:not(.masonry-products) > li.product:nth-of-type(3n + 1),
  .columns-4 > .commerce ul.products:not(.masonry-products) > li.product:nth-of-type(3n + 1),
  .commerce ul.products:not(.masonry-products).columns-4 > li.product:nth-of-type(3n + 1),
  .columns-2 > .commerce ul.products:not(.masonry-products) > li.product:nth-of-type(3n + 1),
  .commerce ul.products:not(.masonry-products).columns-2 > li.product:nth-of-type(3n + 1) {
    clear: none;
  }
  .commerce-lookbok.commerce ul.products:not(.masonry-products) > li.product:nth-of-type(2n + 1),
  .columns-4 > .commerce ul.products:not(.masonry-products) > li.product:nth-of-type(2n + 1),
  .commerce ul.products:not(.masonry-products).columns-4 > li.product:nth-of-type(2n + 1),
  .columns-2 > .commerce ul.products:not(.masonry-products) > li.product:nth-of-type(2n + 1),
  .commerce ul.products:not(.masonry-products).columns-2 > li.product:nth-of-type(2n + 1) {
    clear: both;
  }
  .col-md-9.main-wrap ul.products:not(.masonry-products) li.product:not(.product-category) {
    width: 33.33333333333333%;
    clear: none;
  }
  .col-md-9.main-wrap ul.products:not(.masonry-products) li.product:not(.product-category).first {
    clear: none;
  }
  .col-md-9.main-wrap
    ul.products:not(.masonry-products)
    li.product:not(.product-category):nth-of-type(3n + 1) {
    clear: both;
  }
}

@media (max-width: 480px) {
  .commerce ul.products:not(.masonry-products) > li.product,
  .commerce ul.products:not(.masonry-products) > li.product:not(.product-category) {
    width: 100%;
    float: none;
    clear: none;
  }
}
.commerce ul.products li.product {
  overflow: hidden;
  background-color: transparent;
  text-align: center;
}
.commerce .products ul,
.commerce ul.products {
  margin: 0;
  padding: 0;
  list-style: none outside;
  clear: both;
}
.commerce ul.products li.product figure {
  position: relative;
}

.commerce ul.products li.product figure .product-wrap {
  position: relative;
  display: block;
}

.commerce ul.products li.product figure .product-wrap .product-images {
  position: relative;
  overflow: hidden;
  border: 1px solid #e1e1e1;
}

.commerce ul.products li.product figure .product-wrap .product-images > a {
  display: block;
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-action {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-action > div {
  display: inline-block;
  vertical-align: top;
}

.commerce ul.products li.product figure .product-wrap .product-images .shop-loop-thumbnail {
  left: 0;
  top: 0;
  width: 100%;
  z-index: 8;
}

.commerce ul.products li.product figure .product-wrap .product-images .shop-loop-thumbnail img {
  display: block;
  height: auto;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  width: 100%;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  opacity: 1;
  filter: alpha(opacity=100);
}

.commerce
  ul.products
  li.product
  figure
  .product-wrap
  .product-images
  .shop-loop-thumbnail.shop-loop-front-thumbnail {
  z-index: 10;
  position: relative;
}

.commerce
  ul.products
  li.product
  figure
  .product-wrap
  .product-images
  .shop-loop-thumbnail.shop-loop-back-thumbnail {
  position: absolute;
}

.commerce ul.products li.product figure:hover .product-wrap .product-images {
  border-color: #8ac691;
}

.commerce
  ul.products
  li.product
  figure:hover
  .product-wrap
  .product-images
  .shop-loop-front-thumbnail
  img {
  opacity: 0;
  filter: alpha(opacity=0);
}

.commerce ul.products li.product figure:hover .product-wrap .product-images .loop-action {
  opacity: 1;
  filter: alpha(opacity=100);
}

.commerce ul.products li.product figcaption {
  padding: 1em 0 0;
  position: relative;
  z-index: 50;
}

.commerce ul.products li.product figcaption .shop-loop-product-info {
  display: block;
}

.commerce ul.products li.product figcaption .shop-loop-product-info:before,
.commerce ul.products li.product figcaption .shop-loop-product-info:after {
  content: ' ';
  display: table;
}

.commerce ul.products li.product figcaption .shop-loop-product-info:after {
  clear: both;
}

.commerce ul.products li.product figcaption .shop-loop-product-info .list-info-meta {
  display: none;
}

.commerce ul.products li.product strong {
  display: block;
}

.commerce ul.products li.product .star-rating {
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 85%;
}

.commerce ul.products li.product .info-price {
  margin-top: 15px;
}

.commerce ul.products li.product .info-price del {
  font-size: 85%;
}

.commerce ul.products li.product .price {
  opacity: 0.7;
  filter: alpha(opacity=70);
  font-weight: 500;
}

.commerce ul.products li.product .price del {
  color: #999;
  float: none;
  margin: 0 5px;
}

.commerce ul.products li.product .price ins {
  background: none;
  text-decoration: none;
  float: none;
}
.commerce ul.products li.product .product_title {
  font-size: 1.2em;
  margin-top: 0.5em;
  margin-bottom: 10px;
}
.commerce .star-rating {
  float: right;
  /* overflow: hidden; */
  position: relative;
  height: 1em;
  line-height: 1em;
  font-size: 13px;
  width: 5.5em;
  font-family: 'star';
}

.commerce .star-rating:before {
  content: '\73\73\73\73\73';
  color: #8ac691;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  letter-spacing: 1px;
}

.commerce .star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
  color: #8ac691;
}

.commerce .star-rating span:before {
  content: '\E033\E033\E033\E033\E033';
  top: 0;
  position: absolute;
  left: 0;
  letter-spacing: 1.5px;
}

.commerce .products .star-rating {
  display: block;
  float: none;
}

.commerce ul.products li.product .star-rating {
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 85%;
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-action {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.commerce ul.products li.product figure .product-wrap .product-images .loop-action > div {
  display: inline-block;
  vertical-align: top;
}

.commerce ul.products li.product .shop-loop-quickview a {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  height: 45px;
  width: 45px;
  line-height: 53px;
  text-align: center;
}

.commerce ul.products li.product .shop-loop-quickview a i {
  font-size: 18px;
}

.commerce ul.products li.product .shop-loop-quickview a.loading i:before {
  content: '\e02d';
  -webkit-animation: fa-spin 0.5s infinite linear;
  -o-animation: fa-spin 0.5s infinite linear;
  animation: fa-spin 0.5s infinite linear;
  -webkit-animation-timing-function: steps(8, start);
  animation-timing-function: steps(8, start);
}

.commerce ul.products li.product .loop-add-to-cart a {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  height: 45px;
  width: 45px;
  line-height: 45px;
  position: relative;
  text-indent: -999em;
}

.commerce ul.products li.product .loop-add-to-cart a:before {
  content: '\e013';
  position: relative;
  display: inline-block;
  font-family: 'ElegantIcons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}
.commerce ul.products li.product .loop-add-to-cart a:before {
  display: inline-block;
  font-size: 18px;
  left: 0;
  position: absolute;
  text-indent: 0;
  top: 0;
  height: 45px;
  width: 45px;
  line-height: 45px;
}
.product-categories-grid-wrap {
  position: relative;
}

.product-categories-grid-wrap .wall-col {
  margin: 0;
  padding: 0;
  height: 30rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 991px) {
  .product-categories-grid-wrap .wall-col {
    height: auto;
    padding-bottom: 0;
  }
}

.product-categories-grid-wrap .wall-col .product-category-grid-featured {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}

.product-categories-grid-wrap
  .wall-col
  .product-category-grid-item-wrap
  .product-category-grid-featured-wrap:before {
  z-index: 1;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 1s;
  -webkit-transition: background-color 1s;
}

.product-categories-grid-wrap .wall-col:hover .product-category-grid-featured {
  -webkit-transform: matrix(1.2, 0, 0, 1.2, 0, 0);
  transform: matrix(1.2, 0, 0, 1.2, 0, 0);
}

.product-categories-grid-wrap
  .wall-col:hover
  .product-category-grid-item-wrap
  .product-category-grid-featured-wrap:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.product-categories-grid-wrap .wall-col:hover .product-category-grid-featured-summary h3:before {
  bottom: -10px;
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}

.product-categories-grid-wrap .wall-row {
  width: 100%;
  display: table;
}

.product-categories-grid-wrap .wall-row .wall-col {
  display: table-cell;
  float: none;
  position: relative;
  height: 100%;
}

@media (max-width: 767px) {
  .product-categories-grid-wrap .wall-row {
    display: block;
  }
  .product-categories-grid-wrap .wall-row .wall-col {
    display: block;
  }
}

.product-categories-grid-wrap .product-category-grid-item {
  display: block;
}

.product-categories-grid-wrap .product-category-grid-item-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.grid-gutter .product-categories-grid-wrap .product-category-grid-item-wrap:before {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border: 15px solid #fff;
  z-index: 3;
}

.product-categories-grid-wrap .product-category-grid-featured {
  height: 0;
  padding-bottom: 100%;
  background-color: #f3f3f3;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 101% auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.product-categories-grid-wrap .product-category-grid-featured-wrap {
  overflow: hidden;
  position: relative;
}

.product-categories-grid-wrap .title-in {
  height: 0;
  padding-bottom: 50%;
}

@media (max-width: 991px) {
  .product-categories-grid-wrap .title-in {
    height: auto;
    padding-bottom: 0;
  }
}

.product-categories-grid-wrap .title-in .product-category-grid-item-wrap {
  position: static;
}

.product-categories-grid-wrap .title-in .product-category-grid-item-wrap .portfolio-featured {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

@media (max-width: 767px) {
  .product-categories-grid-wrap .pull-right {
    float: none !important;
  }
}

.product-categories-grid-wrap .title-out {
  height: 0;
  padding-bottom: 25%;
}

.product-categories-grid-wrap .title-out .product-category-grid-featured {
  padding-bottom: 50%;
}

@media (max-width: 767px) {
  .product-categories-grid-wrap .title-out .product-category-grid-featured {
    padding-bottom: 100%;
  }
}

.product-categories-grid-wrap .title-out.padding-100 .product-category-grid-featured {
  padding-bottom: 100%;
}

.product-categories-grid-wrap .height-auto {
  height: auto;
  padding: 0;
}

.product-categories-grid-wrap .height-auto .product-category-grid-featured {
  padding-bottom: 100%;
}

@media (max-width: 767px) {
  .product-categories-grid-wrap .height-auto .product-category-grid-featured {
    padding-bottom: 100%;
  }
}

.product-categories-grid-wrap .product-category-grid-featured-summary {
  text-transform: uppercase;
  text-align: center;
  z-index: 12;
}

.product-categories-grid-wrap .product-category-grid-featured-summary h3 {
  margin: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 2em;
  position: relative;
  line-height: 1.1;
  color: #fff;
}

.product-categories-grid-wrap .product-category-grid-featured-summary h3 small {
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 0.5em;
  display: block;
  font-weight: normal;
  color: #333;
  color: #fff;
}

.product-categories-grid-wrap .product-category-grid-featured-summary .product-category-grid-btn {
  margin-top: 15px;
}

.grid-style-1 .product-categories-grid-wrap .product-category-grid-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  background: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.grid-style-1 .product-categories-grid-wrap .product-category-grid-featured-summary {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 30px;
}

.grid-style-1 .product-categories-grid-wrap .product-category-grid-featured-summary h3:before {
  width: 100px;
  height: 1px;
  content: '';
  position: absolute;
  bottom: -30px;
  -webkit-transition: bottom 0.3s, opacity 0.3s;
  -o-transition: bottom 0.3s, opacity 0.3s;
  transition: bottom 0.3s, opacity 0.3s;
  left: 50%;
  margin-left: -50px;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  background: #fff;
}

@media (max-width: 767px) {
  .grid-style-2 .product-categories-grid-wrap .product-category-grid-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .grid-style-2 .product-categories-grid-wrap .product-category-grid-featured-summary {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .grid-style-2 .product-categories-grid-wrap .product-category-grid-featured-summary h3:before {
    width: 100px;
    height: 1px;
    content: '';
    position: absolute;
    bottom: -30px;
    -webkit-transition: bottom 0.3s, opacity 0.3s;
    -o-transition: bottom 0.3s, opacity 0.3s;
    transition: bottom 0.3s, opacity 0.3s;
    left: 50%;
    margin-left: -50px;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    background: #fff;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .product-category-grid-featured-summary
    .product-category-grid-btn {
    display: none;
  }
}

.grid-style-2 .product-categories-grid-wrap .title-out {
  background: #f3f3f3;
}

@media (min-width: 768px) {
  .grid-style-2 .product-categories-grid-wrap .title-in .product-category-grid-featured-summary {
    text-align: left;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-in
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap {
    padding: 30px;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-in
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap {
    top: 0;
    left: auto;
    right: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .grid-gutter.grid-style-2
    .product-categories-grid-wrap
    .title-in
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap {
    top: 15px;
    right: 15px;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-in
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap
    h3
    small {
    font-size: 0.6em;
    text-transform: none;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-in
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap
    .product-category-grid-btn {
    border-color: #fff;
    color: #fff;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-in
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap
    .product-category-grid-btn:hover {
    border-color: #8ac691;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-in.out-right
    .product-category-grid-featured-summary {
    left: 0;
    right: auto;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-in.out-right
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap {
    top: 0;
    left: 0;
    right: auto;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .grid-gutter.grid-style-2
    .product-categories-grid-wrap
    .title-in.out-right
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap {
    top: 15px;
    left: 15px;
  }
  .grid-style-2 .product-categories-grid-wrap .title-out .product-category-grid-featured {
    padding-bottom: 100%;
  }
  .grid-style-2 .product-categories-grid-wrap .title-out .product-category-grid-featured-wrap {
    width: 50%;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-out
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap
    h3 {
    color: #333;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-out
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap
    h3
    small {
    color: #333;
  }
  .grid-style-2 .product-categories-grid-wrap .product-category-grid-featured-summary {
    width: 50%;
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .product-category-grid-featured-summary
    .product-category-grid-featured-summary-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 15px;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-out.last-right
    .product-category-grid-featured-wrap {
    float: right;
  }
  .grid-style-2
    .product-categories-grid-wrap
    .title-out.last-right
    .product-category-grid-featured-summary {
    right: auto;
    left: 0;
  }
}

@media (max-width: 991px) {
  .product-categories-grid-wrap .title-out,
  .grid-style-3 .product-categories-grid-wrap .title-out,
  .grid-style-4 .product-categories-grid-wrap .title-out {
    height: auto;
    padding-bottom: 0;
  }
  .product-categories-grid-wrap .title-in,
  .grid-style-3 .product-categories-grid-wrap .title-in,
  .grid-style-4 .product-categories-grid-wrap .title-in {
    height: auto;
    padding-bottom: 0;
  }
}

ul.masonry-products li.product {
  padding-bottom: 2em;
  background: none;
  z-index: 51;
}

.commerce ul.products li.product.style-2 {
  text-align: left;
}

.commerce ul.products li.product.style-2 figure .star-rating {
  margin-left: 0;
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images {
  border: 0;
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action {
  right: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  background: none;
  width: 50px;
  z-index: 99;
}

.commerce ul.products li.product.style-2 figure .product-wrap .product-images .loop-action > div {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  -o-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .shop-loop-quickview {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .shop-loop-quickview
  a {
  color: #000;
  width: 50px;
  height: 40px;
  line-height: 40px;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-cart {
  -webkit-transition: all 0.3s ease 0.1s;
  -o-transition: all 0.3s ease 0.1s;
  transition: all 0.3s ease 0.1s;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-cart
  a {
  color: #000;
  width: 50px;
  height: 40px;
  line-height: 40px;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-cart
  a:before {
  width: 50px;
  height: 40px;
  line-height: 40px;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-cart
  a.loading:before,
.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-cart
  a.added:before {
  color: #000;
  line-height: 40px;
  width: 50px;
  height: 40px;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-wishlist {
  -webkit-transition: all 0.3s ease 0.1s;
  -o-transition: all 0.3s ease 0.1s;
  transition: all 0.3s ease 0.1s;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-wishlist
  .add_to_wishlist {
  color: #000;
  width: 50px;
  height: 40px;
  line-height: 40px;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-wishlist
  .add_to_wishlist:before {
  width: 50px;
  height: 40px;
  line-height: 40px;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-wishlist
  .yith-wcwl-wishlistaddedbrowse
  a,
.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-wishlist
  .yith-wcwl-wishlistexistsbrowse
  a {
  color: #000;
  width: 50px;
  height: 40px;
  line-height: 40px;
}

.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-wishlist
  .yith-wcwl-wishlistaddedbrowse
  a:before,
.commerce
  ul.products
  li.product.style-2
  figure
  .product-wrap
  .product-images
  .loop-action
  .loop-add-to-wishlist
  .yith-wcwl-wishlistexistsbrowse
  a:before {
  width: 50px;
  height: 40px;
  line-height: 40px;
}

.commerce
  ul.products
  li.product.style-2
  figure:hover
  .product-wrap
  .product-images
  .loop-action
  > div {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.commerce .widget_price_filter .price_slider {
  margin-bottom: 1.3em;
}

.commerce .widget_price_filter .price_slider_amount {
  text-align: right;
}

.commerce .widget_price_filter .price_slider_amount:before,
.commerce .widget_price_filter .price_slider_amount:after {
  content: ' ';
  display: table;
}

.commerce .widget_price_filter .price_slider_amount:after {
  clear: both;
}

.commerce .widget_price_filter .price_slider_amount #min_price,
.commerce .widget_price_filter .price_slider_amount #max_price {
  display: none;
}

.commerce .widget_price_filter .price_slider_amount .button {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  color: #333;
  padding: 0.5714285714285714rem 2rem;
  font-size: 13px;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  color: #fff;
  background-color: #333;
  border-color: #333;
  padding: 0.42857142857142855rem 2rem;
}

.commerce .widget_price_filter .price_slider_amount .button:hover,
.commerce .widget_price_filter .price_slider_amount .button:focus {
  text-decoration: none;
}

.commerce .widget_price_filter .price_slider_amount .button i,
.commerce .widget_price_filter .price_slider_amount .button span {
  z-index: 2;
  position: relative;
}

.commerce .widget_price_filter .price_slider_amount .button:hover,
.commerce .widget_price_filter .price_slider_amount .button:focus,
.commerce .widget_price_filter .price_slider_amount .button:active,
.commerce .widget_price_filter .price_slider_amount .button.active {
  color: #fff;
}

.commerce .widget_price_filter .price_slider_amount .button:active,
.commerce .widget_price_filter .price_slider_amount .button.active {
  background-image: none;
}

.commerce .widget_price_filter .price_slider_amount .button:hover,
.commerce .widget_price_filter .price_slider_amount .button:focus {
  background-color: #666;
  border-color: #666;
}

.commerce .widget_price_filter .price_label {
  text-transform: uppercase;
  float: left;
}

.commerce .widget_price_filter .price_label span {
  color: #333;
}

.commerce .widget_price_filter .ui-slider {
  position: relative;
  text-align: left;
}

.commerce .widget_price_filter .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 12px;
  height: 12px;
  cursor: pointer;
  outline: none;
  top: -4px;
  background: #333;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 1px dotted #333;
}

.commerce .widget_price_filter .ui-slider .ui-slider-handle:last-child {
  margin-left: -9px;
}

.commerce .widget_price_filter .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  background: #999;
}

.commerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background: #e6e6e6;
}

.commerce .widget_price_filter .ui-slider-horizontal {
  height: 4px;
}

.commerce .widget_price_filter .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.commerce .widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
  left: -1px;
}

.commerce .widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
  right: -1px;
}

.commerce.widget_product_categories ul.product-categories > li:first-child {
  border: none;
}

.commerce.widget_product_categories ul.product-categories li {
  padding: 0;
  border-bottom: none;
}

.commerce.widget_product_categories ul.product-categories li ul.children li {
  padding-left: 1.4285714285714286em;
}

.commerce.widget_product_categories ul.product-categories a {
  padding: 0 0 15px 0;
  display: inline-block;
}

.commerce.widget_product_categories ul.product-categories a:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 5px;
  border: 1px dotted #999;
  margin-right: 0.7142857142857143em;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.commerce.widget_product_categories ul.product-categories a:hover {
  color: #8ac691;
}

.commerce.widget_product_categories ul.product-categories a:hover:before {
  background: #333;
  border-color: #333;
}
.commerce .shop-toolbar {
  padding-bottom: 2em;
}

.commerce .shop-toolbar:before,
.commerce .shop-toolbar:after {
  content: ' ';
  display: table;
}

.commerce .shop-toolbar:after {
  clear: both;
}

.commerce .shop-toolbar .view-mode {
  float: right;
  border: 1px solid #e1e1e1;
  padding: 0 10px;
}

@media (max-width: 1199px) {
  .commerce .shop-toolbar .view-mode {
    display: none;
  }
}

.commerce .shop-toolbar .view-mode a {
  font-size: 1.2857142857142858em;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  float: left;
  padding: 0 10px;
  color: #aaa;
  cursor: pointer;
}

.commerce .shop-toolbar .view-mode a:hover,
.commerce .shop-toolbar .view-mode a.active {
  color: #333;
}

.commerce .shop-toolbar .view-mode a.list-mode {
  margin-right: 0;
}

.commerce .shop-toolbar .filter-toggle-button {
  float: left;
  padding: 0 10px;
}

.commerce .shop-toolbar .filter-toggle-button a {
  font-size: 1em;
  height: 40px;
  display: inline-block;
  line-height: 38px;
  text-align: center;
  float: left;
  color: #777;
  cursor: pointer;
}

.commerce .shop-toolbar .filter-toggle-button a:hover,
.commerce .shop-toolbar .filter-toggle-button a.active {
  color: #333;
}

.commerce .commerce-ordering {
  float: left;
}

.commerce .commerce-ordering .commerce-ordering-select {
  float: left;
  line-height: 1;
  margin-right: 1em;
}

.commerce .commerce-ordering .commerce-ordering-select label {
  margin: 0;
}

.commerce .commerce-ordering .form-flat-select {
  display: inline-block;
}

@media (max-width: 767px) {
  .commerce .commerce-ordering {
    display: none;
  }
}

@media (min-width: 992px) {
  .commerce .shop-loop.list ul.products {
    margin: 0;
  }
  .commerce .shop-loop.list ul.products li.product:not(.product-category) {
    display: block;
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    clear: none;
  }
  .commerce .shop-loop.list ul.products li.product:not(.product-category) .product-container {
    border: 0;
    padding: 0;
  }
  .commerce .shop-loop.list ul.products li.product:not(.product-category) .product-wrap {
    width: 30%;
  }
  .commerce .shop-loop.list ul.products li.product:not(.product-category) figure:before,
  .commerce .shop-loop.list ul.products li.product:not(.product-category) figure:after {
    content: ' ';
    display: table;
  }
  .commerce .shop-loop.list ul.products li.product:not(.product-category) figure:after {
    clear: both;
  }
  .commerce .shop-loop.list ul.products li.product:not(.product-category) .product-wrap {
    float: left;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    .product-wrap
    .product-images:after {
    display: none;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    .product-wrap
    .product-images
    .loop-action {
    display: none;
  }
  .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption {
    width: 70%;
    display: table;
    padding: 0 0 10px 30px;
    margin: 0;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .product_title {
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .shop-loop-product-info {
    text-align: left;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .info-meta
    .star-rating {
    display: block;
    margin: 0 0 0.5em;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .info-price
    .price {
    font-size: 1.2857142857142858em;
    color: #333;
  }
  .commerce .shop-loop.list ul.products li.product:not(.product-category) figcaption .info-excerpt {
    display: block;
    margin-top: 1.5em;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .loop-add-to-wishlist {
    float: left;
    margin-left: 10px;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .shop-loop-quickview {
    float: left;
    margin-left: 10px;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .shop-loop-quickview
    a {
    border: 1px solid #e1e1e1;
    color: #aaa;
    line-height: 46px;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .shop-loop-quickview
    a:hover {
    color: #fff;
    background: #333;
    border-color: #333;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta {
    display: block;
    margin-top: 30px;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart {
    float: left;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    font-size: 13px;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    color: #fff;
    background-color: #333;
    border-color: #333;
    border: 0;
    padding: 0 0.8571428571428571rem;
    line-height: 45px;
    padding-left: 50px;
    padding-right: 1.5em;
    width: auto;
    text-indent: 0;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a:hover,
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a:focus {
    text-decoration: none;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a
    i,
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a
    span {
    z-index: 2;
    position: relative;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a:hover,
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a:focus,
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a:active,
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a.active {
    color: #fff;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a:active,
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a.active {
    background-image: none;
  }
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a:hover,
  .commerce
    .shop-loop.list
    ul.products
    li.product:not(.product-category)
    figcaption
    .list-info-meta
    .loop-add-to-cart
    a:focus {
    background-color: #666;
    border-color: #666;
  }
}

.commerce .commerce-pagination .commerce-result-count {
  float: left;
}

.commerce .commerce-pagination .paginate {
  float: right;
  margin: 0;
  padding: 0;
}
.commerce .col2-set {
  width: 100%;
}

.commerce .col2-set:before,
.commerce .col2-set:after {
  content: ' ';
  display: table;
}

.commerce .col2-set:after {
  clear: both;
}

.commerce .col2-set .col-1 {
  float: left;
  width: 48%;
}

.commerce .col2-set .col-2 {
  float: right;
  width: 48%;
}
.commerce form.login .input-text,
.commerce form.register .input-text {
  border-color: #e1e1e1;
}

.commerce form.login,
.commerce form.checkout_coupon,
.commerce form.register {
  padding: 0 0 80px;
  margin: 0;
  text-align: left;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.commerce form.login:before,
.commerce form.checkout_coupon:before,
.commerce form.register:before,
.commerce form.login:after,
.commerce form.checkout_coupon:after,
.commerce form.register:after {
  content: ' ';
  display: table;
}

.commerce form.login:after,
.commerce form.checkout_coupon:after,
.commerce form.register:after {
  clear: both;
}
.commerce form .form-row .input-text {
  display: block;
  height: 40px;
  padding: 0.5714285714285714rem 1rem;
  line-height: 1.6;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
  -webkit-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  -o-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}
.commerce form .form-row .required {
  color: #fe252c;
  border: 0;
  text-decoration: none;
}
.commerce form .form-row-wide {
  clear: both;
}
.commerce form .form-row {
  margin-bottom: 15px;
}
.commerce form .form-row button.button,
.commerce form .form-row input.button {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.5714285714285714rem 2rem;
  font-size: 13px;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  background: transparent;
  border: 2px solid #333;
  text-transform: uppercase;
  color: #333;
  position: relative;
  font-weight: 600;
}
.commerce form .form-row button.button:hover,
.commerce form .form-row input.button:hover,
.commerce form .form-row button.button:focus,
.commerce form .form-row input.button:focus {
  color: #fff;
  border-color: #8ac691;
  background: #8ac691;
}
/* === 4.2. SINGLE PRODUCT === */

.commerce div.product-quickview-content,
.commerce div.product.style-1 {
  overflow: hidden;
}

.commerce div.product-quickview-content div.single-product-images,
.commerce div.product.style-1 div.single-product-images {
  position: relative;
  margin-bottom: 2em;
}

.commerce div.product-quickview-content div.single-product-images .product-images-slider,
.commerce div.product.style-1 div.single-product-images .product-images-slider {
  position: relative;
  overflow: hidden;
  border: 1px solid #e1e1e1;
}

.commerce div.product-quickview-content div.summary .product_meta,
.commerce div.product div.summary .product_meta {
  clear: both;
  padding: 0;
  margin: 0;
}

.commerce div.product-quickview-content div.summary .product_meta:before,
.commerce div.product div.summary .product_meta:before,
.commerce div.product-quickview-content div.summary .product_meta:after,
.commerce div.product div.summary .product_meta:after {
  content: ' ';
  display: table;
}

.commerce div.product-quickview-content div.summary .product_meta:after,
.commerce div.product div.summary .product_meta:after {
  clear: both;
}

.commerce div.product-quickview-content div.summary .product_meta a,
.commerce div.product div.summary .product_meta a {
  font-weight: 600;
}

.commerce div.product-quickview-content div.summary .product_meta a:hover,
.commerce div.product div.summary .product_meta a:hover {
  text-decoration: underline;
}

.commerce div.product-quickview-content div.summary .product_meta > span,
.commerce div.product div.summary .product_meta > span {
  display: block;
}

.commerce div.product-quickview-content .content-share,
.commerce div.product .content-share {
  position: relative;
  margin-top: 1em;
}

@media (min-width: 992px) {
  .commerce div.product.style-1 div.single-product-images-slider.product-has-thumbnails-gallery {
    padding-left: 95px;
  }
}

.commerce div.product.style-1 div.single-product-images-slider img {
  width: 100%;
}

.commerce div.product.style-1 div.single-product-thumbnails {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

@media (max-width: 991px) {
  .commerce div.product.style-1 div.single-product-thumbnails {
    display: none;
  }
}

.commerce div.product.style-2 .single-product-images-slider {
  border: 1px solid #e1e1e1;
}

.commerce div.product.style-2 .single-product-images-slider img {
  width: 100%;
}

.commerce div.product.style-2 .single-product-thumbnails {
  margin-top: 20px;
}

.commerce div.product.style-2 .single-product-thumbnails img {
  width: 100%;
}

@media (max-width: 1199px) {
  .commerce div.product.style-2 .single-product-thumbnails {
    margin-bottom: 60px;
  }
}

.commerce div.product.style-2 div.summary .share-links .share-icons {
  text-align: left;
}

.commerce div.product.style-2 .commerce-tabs .nav-tabs {
  border-bottom: 1px solid #e1e1e1;
}

.commerce div.product.style-2 .commerce-tabs .nav-tabs li {
  margin-bottom: -1px;
}
.commerce div.product {
  margin-bottom: 0;
  position: relative;
}

.commerce div.product .summary .product_title {
  margin: 0.3em 0 0.7em;
  padding: 0;
  text-transform: uppercase;
  font-size: 1.7142857142857142em;
}

.commerce div.product .summary .commerce-product-rating {
  display: block;
  margin-bottom: 15px;
}

.commerce div.product .summary .commerce-product-rating .star-rating {
  margin: 3px 10px 0 0;
  float: left;
}

.commerce div.product .summary .commerce-product-rating .commerce-review-link {
  font-size: 85%;
}

.commerce div.product .summary .price {
  font-size: 2em;
  color: #333;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-weight: 500;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.commerce div.product .summary .price del {
  color: #999;
  float: none;
  margin: 0 5px;
  font-size: 0.7em;
}

.commerce div.product .summary .price ins {
  background: none;
  text-decoration: none;
  float: none;
}

.commerce div.product .summary .product-excerpt {
  position: relative;
  margin-top: 2em;
}

.commerce div.product .summary .product-excerpt p {
  margin: 0;
}

.commerce div.product form.cart div.quantity {
  display: inline-block;
  vertical-align: top;
}

.commerce div.product form.cart div.quantity input.qty {
  height: 50px;
  width: 80px;
}

.commerce .quantity {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  zoom: 1;
}

.commerce .quantity input.qty {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.5714285714285714rem 1rem;
  line-height: 1.6;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e1e1e1;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
  -webkit-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  -o-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 70px;
  font-size: 1em;
}

.commerce .quantity input.qty:focus {
  border-color: #333;
  outline: none;
}

.commerce .quantity input.qty[disabled],
.commerce .quantity input.qty[readonly],
fieldset[disabled] .commerce .quantity input.qty {
  cursor: not-allowed;
  background-color: #aaa;
  opacity: 1;
  filter: alpha(opacity=100);
}

textarea.commerce .quantity input.qty {
  height: auto;
}

.commerce .quantity input[type='number'] {
  padding-right: 1.2em;
}

.commerce .quantity input {
  display: inline-block;
  margin: 0;
  vertical-align: top;
}

.commerce div.product form.cart .button,
.commerce div.product form.cart input.button {
  text-transform: uppercase;
  height: 50px;
  vertical-align: top;
  /* margin-left: 30px; */
  padding-left: 1.5em;
  padding-right: 1.5em;
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.commerce div.product form.cart .button:hover,
.commerce div.product form.cart input.button:hover,
.commerce div.product form.cart .button:focus,
.commerce div.product form.cart input.button:focus,
.commerce div.product form.cart .button:active,
.commerce div.product form.cart input.button:active,
.commerce div.product form.cart .button.active,
.commerce div.product form.cart input.button.active {
  color: #fff;
}

.commerce div.product form.cart .button:active,
.commerce div.product form.cart input.button:active,
.commerce div.product form.cart .button.active,
.commerce div.product form.cart input.button.active {
  background-image: none;
}

.commerce div.product form.cart .button:hover,
.commerce div.product form.cart input.button:hover,
.commerce div.product form.cart .button:focus,
.commerce div.product form.cart input.button:focus {
  background-color: #666;
  border-color: #666;
}

.commerce div.product form.cart .single_add_to_cart_button:before {
  content: '\e013';
  position: relative;
  display: inline-block;
  font-family: 'ElegantIcons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.commerce div.product form.cart .single_add_to_cart_button:before {
  font-size: 1.2857142857142858em;
  margin-right: 0.5em;
}

.commerce div.product form.cart {
  padding: 30px 0;
}

.commerce div.product div.summary .share-links {
  background: none;
  padding: 0;
  margin: 30px 0 0 0;
  text-align: left;
}

.commerce div.product div.summary .share-links > span {
  padding-right: 20px;
}

.commerce div.product div.summary .share-links .share-icons {
  text-align: left;
}

.commerce div.product div.summary .share-links .share-icons a {
  background: none;
  width: 40px;
  height: 40px;
  line-height: 38px;
  color: #444;
  font-size: 1.2857142857142858em;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 1px solid #e1e1e1;
  margin: 0 20px 0 0;
}

.commerce div.product div.summary .share-links .share-icons a:hover {
  color: #8ac691;
  border-color: #8ac691;
}

.commerce div.product div.social {
  text-align: right;
  margin: 0 0 1em;
}

.commerce div.product div.social span {
  margin: 0 0 0 0.14285714285714285em;
}

.commerce div.product div.social span span {
  margin: 0;
}

.commerce .commerce-tabs {
  margin: 30px auto;
}

.commerce .commerce-tabs .nav-tabs {
  border: none;
  text-align: center;
  border-bottom: 1px solid #e1e1e1;
}

.commerce .commerce-tabs .nav-tabs > li {
  float: none;
  margin: 0 0 -1px -4px;
  display: inline-block;
  width: 33.33%;
  /*border-bottom: 1px solid #e1e1e1*/
}

.commerce .commerce-tabs .nav-tabs h3 {
  font-size: calc(11px + 0.5vw);
}

.commerce .commerce-tabs .nav-tabs > li > a {
  font-size: 1.1428571428571428em;
  border-bottom: none;
  text-transform: uppercase;
  font-weight: 600;
  background: rgba(0, 0, 0, 0);
  /*border-width: 0 0 1px 0;*/
  border-style: solid;
  margin: 0;
  border-color: rgba(0, 0, 0, 0);
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.commerce .commerce-tabs .nav-tabs > li > a:hover {
  color: #333;
}

/*.commerce .commerce-tabs .nav-tabs>li.active {*/
/*    border-bottom-color: #333*/
/*}*/

.commerce .commerce-tabs .nav-tabs > li > a.active {
  color: #333;
  border-bottom-color: #333;
  border-bottom: 4px solid;
}

.commerce .commerce-tabs .tab-content {
  background: transparent;
  padding: 20px 0 40px;
  /*border-bottom: 1px solid #e1e1e1*/
}

.commerce .commerce-tabs .tab-content .tab-pane h2 {
  display: none;
}

@media (min-width: 768px) {
  .page-layout-full-width.commerce .commerce-tabs .tab-content #tab-additional_information,
  .page-layout-full-width.commerce .commerce-tabs .tab-content #tab-reviews {
    max-width: 66%;
    margin: 0 auto;
  }
}

.commerce .style-2 .commerce-tabs .nav-tabs {
  text-align: inherit;
}

/* Fix for Add to Cart FilteredProducts */
.shop-loop-quickview .cart .quantity {
  display: inline-block;
}

.shop-loop-quickview .cart .quantity input.qty {
  height: 45px;
}

.shop-loop-quickview .cart button.single_add_to_cart_button {
  vertical-align: top;
  padding: 0.75rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.shop-loop-quickview .cart button.single_add_to_cart_button:before {
  content: '\E013';
  position: relative;
  display: inline-block;
  font-family: ElegantIcons;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  font-size: 1.25em;
  margin-right: 0.5em;
}

/* Fix for Cart Message */
.woocommerce-message {
  display: none;
  border: 1px solid #a0ce4e;
  background: rgba(204, 228, 158, 0.3);
}

.woocommerce-message,
.woocommerce-error,
.woocommerce-info {
  padding: 1em 1em 1em 3.5em !important;
  margin: 0 0 3em !important;
  position: relative;
  border: 2px solid #e8e8e8;
  list-style: none outside !important;
  width: auto;
}

.woocommerce-message:before {
  color: #a0ce4e;
  content: '\f058';
  font-family: 'fontawesome';
}

.woocommerce-message:before,
.woocommerce-error:before,
.woocommerce-info:before {
  content: '';
  height: 1.5em;
  width: 1.5em;
  display: block;
  position: absolute;
  top: 0;
  left: 1em;
  font-family: sans-serif;
  font-size: 1em;
  line-height: 1.5;
  text-align: center;
  padding-top: 1em;
}

.woocommerce-message:before,
.woocommerce-error:before,
.woocommerce-info:before,
.woocommerce-message:after,
.woocommerce-error:after,
.woocommerce-info:after {
  content: ' ';
  display: table;
}

.woocommerce-message > a.button,
.woocommerce-error > a.button,
.woocommerce-info > a.button {
  border: medium none !important;
  float: right;
  padding: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.wc-forward:after,
.wc-forward a:after {
  font-family: 'WooCommerce';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-left: 0.5em;
  content: '\e029';
  font-size: 0.7em;
}

.woocommerce-message:before {
  color: #a0ce4e;
  content: '\f058';
  font-family: 'fontawesome';
}

.woocommerce-message .button:not(.checkout-button) {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.5714285714285714rem 2rem;
  font-size: 13px;
  line-height: 1.6;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  background: transparent;
  border: 2px solid #333;
  text-transform: uppercase;
  color: #333;
  position: relative;
  font-weight: 600;
}

.woocommerce-message a.button:hover {
  color: #8ac691 !important;
  background: transparent !important;
  border: none !important;
}

.cartmessage {
  display: block;
}

/* Mini Cart */
.header-container .minicart:before {
  content: '';
  display: block;
  height: 9px;
  position: absolute;
  right: 20px;
  top: -5px;
  left: auto;
  transform: translate(-50%, 0) rotate(45deg);
  -moz-transform: translate(-50%, 0) rotate(45deg);
  -webkit-transform: translate(-50%, 0) rotate(45deg);
  -ms-transform: translate(-50%, 0) rotate(45deg);
  -o-transform: translate(-50%, 0) rotate(45deg);
  width: 9px;
  z-index: 1010;
  background: #fff;
}

.minicart-display {
  display: block !important;
}

.o-hidden {
  display: none;
}
.o-clear {
  clear: both;
}

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}
