@import '../../assets/scss/settings/variables';
@import '../../assets/scss/settings/utility';

.main-hd {
  position: relative;
  box-shadow: 0px 15px 15px -5px rgba(0, 0, 0, 0.075);
  z-index: 1;
  & .row_custom_small + .row_custom_small {
    margin-top: 0;
  }
  & .nav-hd {
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: 1050px) {
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 1.25rem 0;
    }
    & .slicknav_menu {
      display: none;
      transition: all 0.3s ease;
      transition-property: transform, opacity;
      & .slicknav_btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;
        cursor: pointer;

        & .slicknav_icon {
          float: right;
          margin: 0;
          & .slicknav_icon-bar {
            background-color: $black;
            @include transition(all 0.3s ease);
            display: block;
            width: 1.5em;
            height: 3px;
            &:not(:last-of-type) {
              margin-bottom: 5px;
            }
          }
        }
      }
      & .slicknav_btn.slicknav_open {
        & .slicknav_icon-bar {
          background-color: $black;
          transform-origin: 30% 50%;
          width: 30px;
          margin-top: 5px;
          &:first-child {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            transform: rotate(-45deg);
          }
          &:last-child {
            opacity: 0;
          }
        }
      }
      & .slicknav_nav,
      & .slicknav_nav ul {
        list-style: none;
        overflow: hidden;
      }
      & .slicknav_nav {
        font-size: 0.875em;
        background-color: $main-color-light;
        padding: 0;
        margin-top: 108px !important;
        margin-bottom: 0 !important;
        clear: both;
        z-index: 999;
        &:first-child {
          padding-top: 24px;
          border-top: none;
        }
        & .slicknav_arrow {
          font-size: 0.8em;
          margin: 0 0 0 0.4em;
          display: none;
        }
        & ul {
          padding: 0;
          margin: 0;
        }
        & > li {
          text-align: left;
          border-top: 1px solid #c5c5ca;
          display: block;
          height: $max;
          & > p {
            padding: 14px;
            & > a {
              position: relative;
              text-decoration: none;
              text-transform: uppercase;
              padding: 0;
              margin: 0;
              display: inline;
            }
            & .slicknav_arrow {
              font-size: 0.8em;
              margin: 0 0 0 0.4em;
              display: none;
            }
          }
        }
        & > li.current a,
        & > li:hover a {
          background: $main-color-light;
        }
        & ul li,
        & > li > a,
        & > li > p {
          padding: 14px;
          display: block;
        }
        & a {
          text-decoration: none;
          text-transform: uppercase;
          color: $white;
          position: relative;
          &:last-child:before {
            display: none;
          }
        }
        & .child-nav {
          position: relative;
          padding: 12px 30px;
          &:first-child {
            padding-top: 24px;
            border-top: none;
          }
        }
      }
      & .cat_nav {
        &:hover {
          cursor: pointer;
        }
      }
    }
    & .nav-cn {
      display: inline-block;
      position: relative;
      font-size: 14px;
      margin-right: 0.75rem;
      &::before {
        content: '';
        height: 1px;
        position: absolute;
        left: 0;
        right: 5.7010625rem;
        background: #c8c4c4;
        top: -1px;
      }
      & nav {
        display: block;
        & .menu-hd {
          line-height: 14px;
          margin-bottom: 0;
          letter-spacing: 0.02em;
          & > li {
            position: relative;
            display: inline-block;
            & + li {
              margin-left: 1.5rem;
            }
            & > .sub-menu {
              display: none;
              flex-direction: column;
              position: absolute;
              top: 3.0625rem;
              background-color: white;
              z-index: 2;
              padding: 1.5rem 1.5rem;
              left: 0;
              right: 0;
              min-width: 155px;
              &::before,
              &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                background-color: #9dcd98;
                height: 5px;
              }
              &::before {
                top: 0;
              }
              &::after {
                bottom: 0;
              }
              & > li {
                list-style-type: none;
                &:not(:last-of-type) {
                  margin-bottom: 1rem;
                }
                & > a {
                  display: block;
                }
              }
            }
            &:first-child {
              margin-left: 0;
            }
            &:hover {
              .sub-menu {
                display: block;
              }
              &:last-child {
                background-color: unset;
              }
            }
            & > a {
              display: block;
              position: relative;
              @include transition(color 0.3s ease);
              color: #1a1919;
              font-weight: 600;
              text-transform: uppercase;
              padding: 1.25rem 0;
              &.active::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 5px;
                background: #9dcd98;
              }
            }
            & .sub-menu {
              display: none;
            }
          }

          & li.menu-item-has-children {
            &:hover {
              > a {
                opacity: 0.8;
              }
            }
          }
          & > li.current-menu-ancestor > a,
          & > li.current-menu-item > a {
            color: #9dcd98 !important;
          }
          & a {
            text-decoration: none;
            font-weight: bold;
            @include transition(all 0.3s ease);
            &:focus,
            &:hover {
              color: #8ac691;
            }
          }
          & li {
            & .no_border__custom {
              padding-right: 0px;
              & .sub-menu {
                width: auto !important;
              }
            }
          }
        }
      }
    }
    & .logoWrapper {
      @media (max-width: 1050px) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
      }
    }
    & .logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1.5rem;
      width: 180px;
      @media (max-width: 1050px) {
        position: relative;
        justify-self: center;
        top: initial;
        transform: none;
        left: initial;
        display: block;
        width: clamp(9rem, calc(4.0248rem + 15.5172vw), 11.25rem);
      }
      & h1 {
        margin: 0;
      }
    }
  }

  // Other
  & .collapse.show {
    display: block;
  }
  & .custom__height {
    height: 70px;
    text-align: center;
  }
  & ul,
  & li {
    margin: 0;
    padding: 0;
  }
  & .slicknav_nav {
    & > div > li {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.5rem 0.75rem;
      border-top: 1px solid #c8c4c4;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.875rem;
      outline: none;
      &:not(.empty)::before {
        content: '';
        position: absolute;
        top: 0.5rem;
        cursor: pointer;
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 0.75rem;
        display: inline-block;
        font-weight: bold;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z' clip-rule='evenodd' /%3E%3C/svg%3E");
      }
      &:focus-within > ul {
        text-transform: none;
        display: block;
        margin: 0.375rem 0 0 1.5rem;
        list-style: none;
        font-size: 0.875rem;
        font-weight: bold;
      }
      & > a {
        width: max-content;
        margin-left: 1.5rem;
      }
    }
    & > div:last-of-type > li {
      border-bottom: 1px solid #c8c4c4;
    }
  }
}

@media (max-width: 1050px) {
  & .nav-hd {
    & .slicknav_menu {
      & .changeHamburger .slicknav_icon-bar {
        transform-origin: 30% 50%;
        width: 30px;
        margin-top: 5px;
        display: block;
        margin-bottom: -1px !important;
        &:first-child {
          @include transform(rotate(45deg));
        }
        &:nth-child(2) {
          @include transform(rotate(-45deg));
        }
        &:last-child {
          opacity: 0;
        }
      }
      & #navigation {
        & .producto-nav {
          & a:before {
            font-family: 'FontAwesome';
            content: '\f067';
            padding-right: 8px;
            font-size: 10px;
            vertical-align: middle;
          }
          & .slicknav_nav .sub-menu {
            background-color: #f5f5f5 !important;
          }
          & .child-nav {
            position: relative;
            padding: 12px 30px;
            &:first-child {
              padding-top: 24px;
              border-top: none;
            }
          }
          & .sub-menu {
            & .tiret__custom {
              padding: 12px 0;
              &:first-child {
                padding-top: 24px;
              }
              & > a {
                font-size: 0.75rem;
                &:after {
                  content: '\f068';
                  margin-left: 8px;
                  vertical-align: middle;
                  font-family: 'FontAwesome';
                  font-size: 10px;
                }
              }
            }
          }
          &:nth-child(2) {
            & li:after {
              display: none;
            }
          }
        }
        & li {
          max-width: $max;
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  & .nav-hd {
    & .slicknav_menu {
      top: 18px !important;
      display: block !important;
    }
    & .nav-cn {
      display: none !important;
    }
    & li:first-child .sub-menu {
      width: 100% !important;
    }
  }
}

@media (min-width: 1005px) {
  & .slicknav_menu {
    display: none;
  }
  & .nav-cn {
    display: block;
  }
}
