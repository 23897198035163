@import '../../assets/scss/settings/variables';

.overHead {
  // text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.875rem;
  @media (max-width: 513px) {
    justify-content: space-between;
  }
  & > a {
    padding: 1rem 1rem 1rem 0;
    @media (max-width: 513px) {
      padding: 0.75rem 1rem;
      &:first-child {
        padding-right: 0;
      }
    }
    @media (max-width: 330px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: 514px) {
      &:nth-child(3),
      &:nth-child(4) {
        position: relative;
        visibility: hidden;
        transition: background-size 0.25s ease-in-out;
      }
      &:nth-child(3)::after,
      &:nth-child(4)::after {
        visibility: visible;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1.25rem;
        height: 1rem;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:nth-child(3)::after {
        background-image: url(../../assets/images/united-kingdom.png);
      }
      &:nth-child(4)::after {
        background-image: url(../../assets/images/france.png);
      }
    }
    &:last-of-type {
      background-color: #8ac691;
      color: white;
      padding-left: 1rem;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #6aaf72;
      }
    }
    & i {
      @media (min-width: 331px) {
        margin-right: 0.5rem;
      }
    }
  }
}

.tool-hd {
  text-align: right;
  padding: 20px;
  div {
    &:first-child {
      padding-bottom: 0;
      a i {
        margin-right: 9px;
      }
    }
  }
  a {
    padding: 0 7px;
    &:first-child {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: $xsmall_min) and (max-width: $xsmall_max) {
  .tool-hd {
    a {
      padding: unset;
    }
  }
}

@media screen and (max-width: $small_max) {
  .tool-hd {
    text-align: center;
    .col-md-12 {
      text-align: center;
    }
    a {
      margin-left: 20px !important;
      &:first-child {
        margin-left: 0 !important;
      }
    }
  }
}
