.postsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35ch, 1fr));
  padding: 0 4.375rem;
  gap: 3.375rem 2rem;
  @media (max-width: 513px) {
    grid-template-columns: 1fr;
    padding: 0 1.5rem;
    row-gap: 2rem;
  }
}
