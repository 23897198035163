// Fonts
$fontlight: 300;
$fontregular: 400;
$fontmedium: 500;
$fontlightbold: 600;
$fontbold: 700;
$fontblack: 900;

$main-font: "Lato", Helvetica, Arial, sans-serif;

// Colors
$white: #fff;
$black: #000;
$main-color: #9fce4e;
$main-color-light: #9fce4ec7;
$white-smoke: #F5F5F5;
$somber-gray: #ccc;
$dark-gray: #9b9b9b;
$gray: #333;
$light-gray: #444;
$dark-violet: #290027;

// Media Query
$xxsmall: 320px;
$xsmall_min: 321px;
$xsmall_max: 375px;
$small_min: 376px;
$small_max: 425px;
$medium_min: 426px;
$medium_max: 769px;

$filter_query: 770px;
$filter_query: 770px;

// Width
$max: 100%;
$max-width-row: 1100px;
$container-medium: 1390px;
$xxlarge: 1440px;

// Gutter
$gutter-medium: 30px;

//position
$position-bg-commerce: calc(-#{$gutter-medium} + #{$container-medium});

// with
$width-bg-commerce: calc(100vw - #{$container-medium});
