@import '../../assets/scss/settings/variables';
@import '../../assets/scss/settings/utility';

.SideBySideCarousel {
  overflow: hidden;
  margin-bottom: 4.5rem;
  // min-height: 424px;
  @media (max-width: 1080px) {
    margin-bottom: clamp(2rem, 7.5vw, 4.5rem);
    padding-bottom: 0.75rem;
  }
  & .slideItem {
    display: flex !important;
    min-height: 424px;
    & > div {
      width: 50%;
    }
    & .leftPart {
      @media (max-width: 1080px) {
        display: none;
      }
    }
    & .rightPart {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #a5b9c4;
      color: $white;
      padding: 5rem 7rem;
      @media (max-width: 1080px) {
        width: 100%;
        padding: 3rem 3.5rem;
      }
      @media (max-width: 513px) {
        padding: 2.5rem;
      }
      & h1 {
        color: white;
        text-align: center;
        font-size: clamp(1.75rem, 7.5vw, 2.5rem);
        margin: -2rem 0 1.5rem;
        z-index: 1;
      }
      & .topDecoration,
      & .bottomDecoration {
        display: block;
        width: 100%;
        height: 2px;
        background-color: white;
      }
      & .topDecoration {
        margin-bottom: 1em;
      }
      & .bottomDecoration {
        margin-top: 1em;
      }
      & .rightPartContent {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        line-height: 1.5em;
        // height: 10.5em;
        min-height: 10.5rem;
        @media (max-width: 513px) {
          font-size: 0.875rem;
        }
      }
    }
  }
  & .imgContent {
    @include height-width($max);
    background-size: cover;
    background-position: center;
  }
  & .slick-dots {
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);
    @media (max-width: 1080px) {
      position: relative;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin-top: 0.75rem;
      top: unset;
      left: unset;
      transform: unset;
    }
    & li {
      display: block;
      background: #a5b9c4;
      margin: 0 auto;
      width: clamp(30px, 4vw, 40px);
      height: clamp(30px, 4vw, 40px);
      position: relative;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 4px 8px 8px 0px rgba(0, 0, 0, 0.2);
      font-size: 1.125rem;
      transition: all 0.3s ease-in-out;
      &:not(:last-of-type) {
        margin-bottom: 1rem;
        @media (max-width: 1080px) {
          margin-right: 1rem !important;
        }
      }
      @media (max-width: 1080px) {
        margin: 0 !important;
      }
      & button {
        font-size: 1em;
        line-height: 1em;
        font-weight: bold;
        color: white;
        width: 100%;
        height: 100%;
        &::before {
          content: none;
        }
      }
      &.slick-active {
        background: #a5b9c4;
        width: clamp(45px, 7vw, 60px);
        height: clamp(45px, 7vw, 60px);
        font-size: clamp(1.5rem, 4vw, 2.25rem);
        @media (max-width: 700px) {
          width: 50px;
          height: 50px;
          font-size: 1.75rem;
        }
      }
    }
  }
}
