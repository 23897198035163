@import '../settings/variables';
@import '../settings/utility';

@media screen and (min-width: $xsmall_min) and (max-width: $xsmall_max) {
  .carousel_custom_product_7 {
    .summary {
      .meta-cn {
        p {
          strong {
            width: 170px !important;
          }
          span {
            width: 120px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $medium_min) and (max-width: $medium_max) {
  // .content-container.no-padding {
  //   padding-top: 126px;
  // }

  .all_category_product {
    .list_cat_product__custom {
      .item_product_category {
        height: 408px !important;
        img {
          height: 248px !important;
          width: 248px !important;
        }
      }
    }
  }
}

@media screen and (max-width: $small_max) {
  .wrapper {
    margin: unset;
  }

  .carousel_custom_product_7 {
    .summary {
      h1 {
        font-size: 1.5rem;
      }
      .meta-cn {
        p {
          strong {
            width: 170px !important;
          }
          span {
            width: 300px !important;
          }
        }
      }
      .meta-vol,
      .product-excerpt p {
        font-size: 1rem;
      }
    }
  }

  .sidebar-wrap {
    display: none;
    position: absolute;
    top: 22%;
    bottom: 0;
    padding-right: 0;
    background-color: $white;
    box-shadow: 0 2px 10px rgba(198, 198, 198, 0.7);
    overflow: hidden;
    height: auto;
    @include transition(all 0.3s ease);
    z-index: 99999;
  }

  .modal.fade.show {
    background: rgba(255, 255, 255, 0.9);
  }
  .modal .modal-content {
    height: 100% !important;
  }

  .all-articles__custom {
    .item__article__only {
      height: 517px;
      h2 {
        font-size: 1.25rem !important;
        margin-top: -20px !important;
      }
      time {
        color: #bebebe;
        display: block;
        margin: 12px 0 -4px;
      }
      span {
        font-size: 16.5px !important;
      }
      button {
        height: 33px;
        font-family: 'Helvetica Lt', Arial, Verdana;
        font-size: 1.125rem;
      }
    }
  }

  a:hover,
  a:focus {
    color: $white;
  }
}

@media screen and (min-width: 319px) and (max-width: $medium_max) {
  .commerce {
    .commerce-tabs {
      .nav-tabs {
        > li {
          > a {
            border-width: unset;
          }
        }
      }
    }
    div.product.style-2 {
      .commerce-tabs {
        .nav-tabs {
          li {
            width: 100%;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: $medium_max) {
//   .content-container.no-padding {
//     padding-top: 112px;
//   }
// }
