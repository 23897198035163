.postContent {
  max-width: 60.75rem;
  margin: 0 auto;
  padding: 0 4.375rem;
  margin-top: 2rem;
  @media (max-width: 513px) {
    padding: 0 1.5rem;
  }
  & > h1 {
    font-size: clamp(1.75rem, 4vw, 2.25rem);
    color: #1d212c;
  }
  & .date {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    & i {
      margin-right: 0.75rem;
    }
    & time {
      font-size: 1.125rem;
      color: #444444;
    }
  }
  & .richText {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }
  .postNavigation {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #c8c4c4;
    border-bottom: 1px solid #c8c4c4;
    padding: 1rem 0;
    margin-bottom: 2.625rem;
    & .previousPost,
    & .nextPost {
      display: flex;
      flex-direction: column;
      & > span {
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
        color: #979797;
        margin-bottom: 0.25rem;
      }
      & > a {
        font-size: 0.75rem;
        color: #1a1919;
        font-weight: bold;
        &:hover {
          color: #8ac691;
        }
      }
    }

    & .previousPost span::before {
      content: '<';
      margin-right: 0.25rem;
    }
    & .nextPost {
      & span::after {
        content: '>';
        margin-left: 0.25rem;
      }
      & a {
        text-align: right;
      }
    }
  }
}
