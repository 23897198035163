
/* === 3.16. FOOTER === */

.footer-widget {
    font-size: 0.9em;
    background: #212121;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.footer-widget .footer-widget-wrap {
    padding: 60px 0
}

@media (max-width:991px) {
    .footer-widget .footer-widget-col {
        margin-bottom: 30px
    }
}

.footer-widget .widget {
    margin-bottom: 0;
}

.footer-widget .widget:first-child {
    padding-top: 0
}

.footer-widget .widget-title {
    margin-top: 0;
    color: #fff;
    margin-bottom: 2em
}

.footer-widget li {
    border-bottom: #727272;
    padding-left: 0;
    padding-bottom: 10px;
    position: relative
}

.footer-widget li .post-date {
    display: block
}

.footer-widget li:last-child {
    border: none
}


.footer-widget .posts-thumbnail-image img {
    width: 60px
}

.footer-widget .posts-thumbnail-content {
    border-bottom: none;
    margin-left: 73px;
    min-height: 45px
}

.footer-widget .posts-thumbnail-content h4 a {
    color: #ccc;
    font-family: "Poppins", Helvetica, Arial, sans-serif
}

.footer-widget .posts-thumbnail-content h4 a:hover {
    color: #fff
}

.footer-widget .posts-thumbnail-content .posts-thumbnail-meta {
    color: #7c7c7c
}

.footer-widget .posts-thumbnail-image {
    float: left;
    height: auto;
    width: 60px
}

.footer-widget .recent-tweets ul,
.footer-widget .posts-thumbnail-list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none
}

.footer-widget .posts-thumbnail-list li {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: none
}

.footer-widget a {
    color: #ccc;
    font-weight: 300
}

.footer-widget a:hover {
    color: #fff
}

.dh-enable-animation .footer-space {
    display: none
}

@media (min-width:992px) {
    .dh-enable-animation .footer-space {
        display: block
    }
}

.footer {
    color: #959595
}

@media (min-width:992px) {
    .dh-enable-animation .footer.footer-fixed {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        margin-left: 0;
        margin-right: 0;
    }
}

.footer a {
    color: #ccc
}

.footer a:hover {
    color: #fff
}

.footer-info {
    background: #161616;
    font-size: 85%
}

.footer-menu,
.footer-copyright {
    padding: 30px 0
}

.footer-menu {
    text-align: right
}

@media (max-width:767px) {
    .footer-menu {
        text-align: center
    }
}

.footer-menu .footer-nav {
    margin: 0;
    padding: 0;
    list-style: none
}

.footer-menu .footer-nav li {
    padding: 0 .5714285714285714em;
    line-height: .7142857142857143em;
    display: inline-block;
    position: relative
}

.footer-menu .footer-nav li:last-child:before {
    display: none
}

.footer-menu .footer-nav li a {
    color: #959595;
    border-bottom: 1px solid transparent
}

.footer-menu .footer-nav li a:hover,
.footer-menu .footer-nav li a:focus {
    color: #fff;
    border-bottom: 1px solid
}

.footer-social {
    background: #161616;
    text-align: center;
    padding-top: 30px
}

.footer-social a {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #959595;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    line-height: 38px;
    font-size: 14px;
    margin: 0 10px;
    color: #959595
}

.footer-social a:hover {
    border-color: #9fce4e
}

.footer-social a:hover i {
    background: none;
    color: #9fce4e
}

.mailchimp-form-content {
    position: relative
}

.mailchimp-form-content input.form-control {
    width: 100%;
    display: inline-block;
    height: 50px
}

.mailchimp-form-content button {
    position: absolute;
    width: 50px;
    height: 50px;
    line-height: 50px;
    padding: 0;
    background: #333;
    color: #fff;
    top: 0;
    right: 0
}

.mailchimp-form-content button:hover {
	color: #fff;
}