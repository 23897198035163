
.homeCarousel{
    .slick-slider{
        width: 100% !important;
    }
    
    .slick-prev{
        left: 25px;
        z-index: 30;
    }
    
    .slick-next{
        right: 25px;
    }

    .wrapperCenter{
        text-align: center;
        z-index: 21;
    }
    
    .imgContainer{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 505px;
        opacity: 1;
        z-index: 20;
    }

    .subtitle{
        display: inline-block;
        position: relative;
        margin-top: 150px;
        font-size: 1.4vw;
        color: #fff;
    }

    .title{
        display: inline-block;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 3vw;
        color:white;
    }

    .btnWrapper{
        display: inline-block;
        margin: 0 10px;
        a{
            transition: none;
            line-height: 20px;
            margin: 0;
            padding: 8px 28px;
            letter-spacing: 0;
            font-weight: 600;
            font-size: 13px;
            color:white;
            border: 1px solid;
        }
    }

    @media (max-width: 500px) {
        .slick-arrow{
            display: none !important;
        }
    }
}
