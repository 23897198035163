.recentPostsBg {
  background-color: #e4e5ee;
  padding: 1.75rem 4.375rem;
  @media (max-width: 513px) {
    padding: 1.75rem 1.5rem;
  }
  h3 {
    display: block;
    padding: 0;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: normal;
    margin: 1rem 0 2rem;
  }
  & ol {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13.8125rem, 1fr));
    list-style: none;
    margin-bottom: 0;
    gap: 1.25rem 7.8125rem;
    padding: 0;
    @media (max-width: 513px) {
      grid-template-columns: 1fr;
    }
    & li {
      & > a {
        display: block;
        & img {
          width: 100%;
        }
      }
      & h4 {
        font-size: 1.125rem;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1;
        margin: 0.5rem 0 0;
        transition: color 0.3s ease-in-out;
        &:hover {
          color: #8ac691;
        }
      }
      & time {
        font-size: 0.75rem;
      }
    }
  }
}
