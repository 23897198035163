footer {
  background: #1a1919;
  color: white;
  padding: 1.5rem 4.375rem;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
  @media (max-width: 513px) {
    padding: 1.5rem;
  }
  & ul {
    list-style: none;
    padding: 0;
  }
  & > ul {
    flex: 0.8;
    display: flex;
    justify-content: space-between;
    gap: 0.75rem 1.5rem;
    flex-wrap: wrap;
    @media (max-width: 1080px) {
      margin-bottom: 1.5rem;
    }
    & li {
      & a {
        color: #999;
        font-size: 0.6875rem;
        &.first_link {
          color: white;
          text-transform: uppercase;
          font-size: 0.75rem;
        }
      }
    }
  }
  & .flags {
    @media (max-width: 513px) {
      margin-bottom: 1.5rem;
    }
    & a {
      position: relative;
      visibility: hidden;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      &:first-child::after,
      &:last-child::after {
        visibility: visible;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.5rem;
        height: 1rem;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:first-child::after {
        background-image: url(../../assets/images/united-kingdom.png);
      }
      &:last-child::after {
        background-image: url(../../assets/images/france.png);
        left: 1rem;
      }
    }
  }
  & .contact {
    height: max-content;
    min-width: max-content;
    flex: 0.2;
    width: max-content;
    border-left: 1px solid #979797;
    padding-left: 1.5rem;
    margin-left: 0;
    @media (min-width: 1080px) {
      margin-left: 3rem;
    }
    & div:first-child {
      margin-bottom: 1rem;
      & a + a {
        margin-left: 1rem;
      }
      & img {
        width: 1.25rem;
      }
    }
    & div:last-child {
      font-size: 0.6875rem;
      & > span {
        display: block;
        margin-bottom: 0.375rem;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.75rem;
      }
      & i {
        margin-right: 0.5rem;
      }
      & a {
        color: white;
      }
      & div:first-of-type {
        margin-bottom: 0.25rem;
      }
    }
  }
  & .copyright {
    margin: 1.5rem 0 0;
    flex-basis: 100%;
    & > * {
      color: #999;
      font-size: 0.75rem;
      @media (max-width: 719px) {
        display: block;
      }
    }
    & *:nth-child(-n + 2)::after {
      @media (min-width: 719px) {
        content: '-';
        margin: 0 0.5rem;
      }
    }
    & .ot-sdk-show-settings {
      color: #8ac691 !important;
      border-color: #8ac691 !important;
      background-color: transparent;
      margin-left: 1rem;
      transition-property: background-color !important;
      &:hover {
        color: white !important;
        background-color: #8ac691 !important;
      }
      @media (max-width: 719px) {
        display: block;
        margin: 1rem 0 0;
      }
    }
  }
}
