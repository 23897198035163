.List3ColumnsTitleDynamic {
  padding: 0 4.375rem;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35ch, 1fr));
  gap: 1.75rem;
  margin: 0;
  margin-bottom: clamp(3.5625rem, 16vw, 7.5rem);
  @media (max-width: 513px) {
    grid-template-columns: 1fr;
    padding: 0 1.5rem;
  }
  & a {
    display: block;
    &:hover {
      color: unset;
      & h2 {
        color: #8ac691;
      }
    }
    & img {
      width: 100%;
      margin-bottom: 1.875rem;
    }
    & h2 {
      font-size: 1.25rem;
      text-transform: uppercase;
      margin: 0 0 0.5rem;
      font-weight: bold;
      transition: color 0.3s ease-in-out;
    }
    & p {
      font-size: 0.875rem;
      margin: 0;
    }
  }
}
