.headingSmallBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12.5rem;
  & > h1 {
    font-size: clamp(1.75rem, 6vw, 2.5rem);
    color: #444;
    text-transform: uppercase;
  }
}
